import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects';
import Cookies from 'js-cookie';
import axios from 'axios';
import qs from 'qs';

import {backendUrl, backendUrlV2Full} from '@config';

import {
  authLoginRequest,
  authLoginSuccess,
  authLoginFailure,
  authLogoutRequest,
  authLogoutSuccess,
  authUnauthorized,
  authGotoNext,
  authGotoOn,
  authLoginNewPasswordSuccess,
  authLoginNewPasswordFailure,
  authVerifyUser,
  authVerifyUserSuccess,
  authVerifyUserFailure,
  authCheckIfLoggedIn,
  authGetAuthStatusAndStartLoading,
  authUserIsConfirmedLoggedIn,
  authUserIsConfirmedNotLoggedIn,
  authStartLoginCheckOnInterval,
  authLoginNewPasswordRequest, authBartUser, authTransferUser, authTransferUserSuccess,
} from '@actions/auth.actions';
import {getConfig, loadOrGetConfig} from '@sagas/config.sagas';
import {getAuthStatus} from '../selectors/auth.selectors';
import {getConfigObject} from '../selectors/config.selectors';
import {notificationsAdd} from '@actions/notifications.actions';
import {i18n} from '@src/i18n';
import {messagesCheckMessages} from '@actions/messages.actions';


function* gotoNext(action) {
  yield put(authGotoOn());
  window.location.reload();
}

function* login(action) {
  const {username, password} = action.payload;

  try {
    const {login, valid} = yield call(
      ({username: user_name, password}) =>
        axios
          .request({
            method: 'POST',
            url: `${backendUrl}/api/login`,
            data: qs.stringify({
              password,
              user_name,
              login: 1,
              full: 1,
            }),
            withCredentials: true,
          })
          .then(({data}) => data),
      {
        username,
        password,
      },
    );

    if (!valid) {
      Cookies.remove('tg-visit');
      throw new Error('Invalid user');
    } else {
      const {session_id} = login;

      Cookies.set('tg-visit', session_id);

      yield put(authUserIsConfirmedLoggedIn());
    }

    Cookies.set('identity_login_attempted', 0);

    const {login: {start_url}} = {login};

    localStorage.removeItem('orgId');
    yield put({type: 'RESET_STORE'});

    // when config is loaded, the rest of the loading will continie
    yield call(getConfig);
    const configObject = yield select(getConfigObject);
    const redirect = new URLSearchParams(window.location.search).get('redirect') || start_url;

    console.log('aaa', configObject.getProperty('params.mail'))
    if (['poll', 'push'].includes(configObject.getProperty('params.mail'))) {
      console.log('go here...')
      // ALWAYS CHECK FOR NEW MESSAGES WHEN LOGGED INN.
      yield put(messagesCheckMessages());
    }
    console.log('bbb')

    yield put(authLoginSuccess({
      authProfile: login,
      start_url:
          redirect
          || (configObject.getProperty('params.start-route')
            ? configObject.getProperty('params.start-route')
            : '/my-education'),
    }));
  } catch (error) {
    console.error(error);
    yield put(authLoginFailure({error}));
  }
}

function* checkLogin(action) {
  try {
    const {username} = action.payload;
    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          user_name: username,
        },
        url: `${backendUrl}/api/verify_user`,
        withCredentials: true,
      }));

    // since this endpoint returns a cookie, we remove it since the cookie is invalid before logon
    Cookies.remove('tg-visit');
    if (data.valid) {
      if (data.auth_url) {
        window.location = data.auth_url;
      }
      yield put(authVerifyUserSuccess({message: 'valid'}));
    } else {
      yield put(authVerifyUserSuccess({message: 'no-user'}));
    }
  } catch (error) {
    console.error(error);
    yield put(authVerifyUserFailure({}));
  }
}

function* newPassword(action) {
  try {
    const {username} = action.payload;
    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          email_or_user_name: username,
        },
        url: `${backendUrl}/sendreminder`,
        withCredentials: true,
      }));

    if(data.statuscode === 0) {
      yield put(authLoginNewPasswordSuccess({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-success'),
          color: 'green',
        },
      }));
    } else{
      yield put(authLoginNewPasswordFailure({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-failure'),
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(authLoginNewPasswordFailure({}));
  }
}

function* logout() {
  try {
    yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/api/logout`,
        withCredentials: true,
      }));
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    localStorage.removeItem('track');
    localStorage.removeItem('config');
    localStorage.removeItem('bart');
    localStorage.removeItem('learningportalConfig');
    yield put(authLogoutSuccess());
  } catch (error) {
    console.error(error);
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    yield put(authLogoutSuccess());
  }
}


function* transferUser() {
  const {data} = yield call(() =>
    axios.request({
      method: 'POST',
      url: `${backendUrlV2Full}/user/transfer`,
      data: {token: Cookies.get('tg-visit')},
      withCredentials: true,
      credentials: 'same-origin',
    }));

  if (data.valid) {
    yield put(authTransferUserSuccess());
  }

  return true;
}

function* bartUser(action) {
  const {id} = action.payload.person.data;

  localStorage.setItem('bart', id);
  localStorage.removeItem('orgId');

  yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/persons/impersonate/${id}?json=1`,
      withCredentials: true,
    }));


  window.location = '/';

  /* DEBUG!
  const { data } = yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    })
  ); */

  // return data.valid;
  return true;
}

function* checkIfLoggedIn() {
  const {data} = yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    }));

  return data.valid;
}

function* getAuthStatusAndStartLoading() {
  const isValid = yield call(checkIfLoggedIn);

  if (isValid) {
    yield put(authUserIsConfirmedLoggedIn());
  } else {
    yield put(authUserIsConfirmedNotLoggedIn());

    return;
  }

  // when config is loaded, the rest of the loading will continie
  yield call(loadOrGetConfig);
}

function* startLoginCheckOnInterval(action) {
  const {intervalMs} = action.payload;

  const authStatus = yield select(getAuthStatus);

  if (authStatus.isLoggedIn) {
    const statusNow = yield call(checkIfLoggedIn);

    if (statusNow === false) {
      yield put(authUserIsConfirmedNotLoggedIn());
    }
  }

  // try to emulate set interval
  yield delay(intervalMs);
  yield call(startLoginCheckOnInterval, {payload: {intervalMs}});
}

const exportObj = [
  takeLatest(authLoginRequest().type, login),
  takeLatest(authLogoutRequest().type, logout),
  takeLatest(authVerifyUser().type, checkLogin),
  takeLatest(authLoginNewPasswordRequest().type, newPassword),
  takeLatest(authGotoNext().type, gotoNext),
  takeEvery(authUnauthorized().type, logout),
  takeEvery(authBartUser().type, bartUser),
  takeLatest(authTransferUser().type, transferUser),
  takeLatest(authCheckIfLoggedIn().type, checkIfLoggedIn),
  takeLatest(
    authGetAuthStatusAndStartLoading().type,
    getAuthStatusAndStartLoading,
  ),
  takeLatest(authStartLoginCheckOnInterval().type, startLoginCheckOnInterval),
];

export default exportObj;
