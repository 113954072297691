import {FooterLogo, FooterMainText, FooterSecondaryText, FooterTextWrapper} from '../containers/styles/styles';
import {ReactComponent as SnapperLogoDimmed} from '@src/assets/svg/logo/snapper_dimmed.svg';
import {i18n} from '@src/i18n';
import {version, buildHash} from '@src/config';
import {screenreaderOnlyMinxin} from '@styles/mixins';

export const SnapperFooter = (
  <>
    <FooterLogo aria-label="Snapper logo">
      <SnapperLogoDimmed
        width="65"
        height="65"
        aria-hidden="true"
      />
    </FooterLogo>
    <FooterTextWrapper>
      <FooterMainText>
        <a
          target="_blank"
          href="https://snapper.no"
          rel="noreferrer"
        >
          Snapper Net Solutions
        </a>
      </FooterMainText>
      <FooterSecondaryText>
        <span css={screenreaderOnlyMinxin}>
          {i18n('login.version') + ':'}
        </span>
        {' '}
        {`${buildHash} v. ${version}`}
      </FooterSecondaryText>
    </FooterTextWrapper>
  </>
);
