const prefix = 'components';

export const COMPONENTS_SET_COURSE_CATALOG_STATE = `${prefix} set course-catalog components state`;

export const setCourseCatalogComponentsState = payload => ({
  type: COMPONENTS_SET_COURSE_CATALOG_STATE,
  payload,
});

export const COMPONENTS_SET_COURSE_CATALOG_ACTIVE_VIEW = `${prefix} set course-catalog components active view`;

export const setCourseCatalogComponentsActiveView = payload => ({
  type: COMPONENTS_SET_COURSE_CATALOG_ACTIVE_VIEW,
  payload,
});

export const COMPONENTS_SET_COURSE_CATALOG_LAYOUT = `${prefix} set course-catalog components layout`;
export const COMPONENTS_SET_COURSE_CATALOG_LAYOUT_REQUEST = `${prefix} set course-catalog components layout request`;
export const COMPONENTS_SET_COURSE_CATALOG_LAYOUT_SUCCESS = `${prefix} set course-catalog components layout success`;
export const COMPONENTS_SET_COURSE_CATALOG_LAYOUT_FAILURE = `${prefix} set course-catalog components layout failure`;

export const setCourseCatalogLayout = () => ({type: COMPONENTS_SET_COURSE_CATALOG_LAYOUT});

export const setCourseCatalogLayoutRequest = () => ({type: COMPONENTS_SET_COURSE_CATALOG_LAYOUT_REQUEST});

export const setCourseCatalogLayoutSuccess = payload => ({
  type: COMPONENTS_SET_COURSE_CATALOG_LAYOUT_SUCCESS,
  payload,
});

export const setCourseCatalogLayoutFailure = payload => ({
  type: COMPONENTS_SET_COURSE_CATALOG_LAYOUT_FAILURE,
  payload,
});
