import dayjs from 'dayjs';
import {daysDifference} from '@utils/misc.utils';

export const isDatesEqual = (a, b) => a.getYear() === b.getYear() && a.getMonth() === b.getMonth() &&  a.getDate() === b.getDate();

export const isToday = date => isDatesEqual(new Date(), date);

export const sortByDateDesc = (startDateA, startDateB) => startDateA - startDateB;

export const verifyDeadlineDates = (signOnDeadlineDate, signOffDeadlineDate)  => {
  const now = new Date();

  const enableSignOn = signOnDeadlineDate > now;
  const enableSignOff = signOffDeadlineDate > now;

  const daysToSignOn = enableSignOn ? daysDifference(signOnDeadlineDate, now) : 0;
  const daysToSignOff =  enableSignOff ? daysDifference(signOffDeadlineDate, now) : 0;

  return {
    signOnDeadline: signOnDeadlineDate,
    signOffDeadline: signOffDeadlineDate,
    enableSignOn,
    enableSignOff,
    daysToSignOn,
    daysToSignOff,
  };
};

export const getPersonDetailsFromEventObject = event => {
  if (!event?.person?.id) return {};

  const {
    person: {
      id,
      fullname,
      user_name,
    },
  } = event;

  return {
    id,
    fullname,
    user_name,
  };
};

export const partitionWaitlistConfirmed = ({
  eventId,
  profileId,
  employeesEvents,
  selfSignedOn,
}) => {
  const waitlist = [];
  const confirmed = [];

  const eventObjects = selfSignedOn
    ? employeesEvents?.filter?.(event => event?.id === eventId && !!event?.person?.id && event.person.id !== profileId)
    : employeesEvents?.filter?.(event => event?.id === eventId);

  eventObjects?.forEach?.(event => {
    if (!event?.hasOwnProperty?.('waitlist')) return;

    const personDetails = getPersonDetailsFromEventObject(event);

    (event.waitlist ? waitlist : confirmed).push(personDetails);
  });

  return {
    waitlist,
    confirmed,
  };
};

export const getNormalizedParticipantsData = ({
  eventId,
  myEvents,
  employeesEvents,
  profileId,
}) => {
  let selfSignedOn, selfWaitlist, selfConfirmed, selfParticipantObj;

  const selfSignedOnEvent = myEvents?.filter(event => event.id === eventId)?.[0];

  if (selfSignedOnEvent) {
    selfSignedOn = true;
    selfWaitlist = selfSignedOnEvent.waitlist;
    selfConfirmed = !selfWaitlist;

    const {fullname, user_name} = selfSignedOnEvent.person;

    selfParticipantObj = {
      id: profileId,
      fullname,
      user_name,
    };
  } else {
    [selfSignedOn, selfWaitlist, selfConfirmed, selfParticipantObj] = [false, false, false, null];
  }

  const {waitlist, confirmed} = partitionWaitlistConfirmed({
    eventId,
    profileId,
    employeesEvents,
    selfSignedOn,
  });

  // const confirmedCount = (selfConfirmed ? 1 : 0) + confirmed?.length ?? 0;
  const waitlistCount = (selfWaitlist ? 1 : 0) + waitlist?.length ?? 0;

  const normalized = {
    selfSignedOn,
    selfWaitlist,
    selfConfirmed,
    selfParticipantObj,
    employeesConfirmed: confirmed,
    employeesWaitlist: waitlist,
    // confirmedCount,
    waitlistCount,
  };

  return normalized;
};

export const parseEvent = event => {
  const {
    id,
    title,
    startdate,
    enddate,
    competence_id,
    duration,
    durations,
    location: {title: locationStr},
    max_participants,
    participants_count,
    sign_on_deadline,
    sign_off_deadline,
    short_description,
    certificates_url,
    competence_groups,
    joinable,
  } = event;
  const startDate = dayjs(startdate);
  const endDate = dayjs(enddate);

  if (!startDate) return null;

  // filter out passed events
  if (startDate.isBefore(dayjs())) return null;

  const courseTypeTitle = competence_groups?.[0]?.title;

  const startsToday = startDate.isSame(dayjs());

  const durationUnits = {};

  durations?.forEach?.(({duration, type}) => {
    durationUnits[type] = duration;
  });

  const {
    signOnDeadline,
    signOffDeadline,
    daysToSignOn,
    daysToSignOff,
    enableSignOn,
    enableSignOff,
  } = verifyDeadlineDates(dayjs(sign_on_deadline), dayjs(sign_off_deadline));

  return {
    id,
    title,
    startDate,
    endDate,
    isToday: startsToday,
    location: locationStr,
    locationTitle: locationStr,
    locationObj: {...event.location},
    participants_count,
    confirmedCount: participants_count,
    maxParticipants: max_participants,
    shortDescription: short_description,
    duration: {
      formatted: duration,
      units: durationUnits,
    },
    deadlines: {
      signOnDeadline,
      signOffDeadline,
      daysToSignOn,
      daysToSignOff,
      signOnPassed: !enableSignOn,
      signOffPassed: !enableSignOff,
    },
    certificateUrl: certificates_url,
    courseId: competence_id,
    competence_id,
    courseTypeTitle,
    joinable,
  };
};

export const normalizeCourseEventsData = ({
  courseEvents,
  myEvents,
  employeesEvents,
  profileId,
  isManager,
  allCourseEvents,
}) => {
  const normalizedEvents = {};
  const eventsByDate = {};
  const eventsByCourseId = {};
  const eventsParticipantData = {};

  const appendEvent = event => {
    if (!event?.id || !event?.startdate) return;

    const eventId = event.id;

    const parsedEvent = parseEvent(event);

    if (!parsedEvent) return;

    const participantsData = getNormalizedParticipantsData({
      eventId,
      profileId,
      myEvents,
      employeesEvents,
    });

    participantsData.confirmedCount = event.participants_count;

    const {
      courseId,
      startDate,
      maxParticipants,
      deadlines: {signOnPassed},
    } = parsedEvent;

    const isFull = participantsData.confirmedCount === maxParticipants;
    const isJoinable = !isFull && !signOnPassed;

    if (!eventsByDate.hasOwnProperty(startDate)) {
      eventsByDate[startDate] = [];
    }

    eventsByDate[startDate].push(eventId);

    if (!eventsByCourseId.hasOwnProperty(courseId)) {
      eventsByCourseId[courseId] = [];
    }

    eventsByCourseId[courseId].push(eventId);

    eventsParticipantData[eventId] = {
      ...participantsData,
      isJoinable,
      isFull,
    };

    normalizedEvents[eventId] = {
      isJoinable,
      isFull,
      ...participantsData,
      ...parsedEvent,
    };
  };

  courseEvents?.forEach?.(appendEvent);
  employeesEvents?.filter?.(event => !normalizedEvents?.hasOwnProperty?.(event.id))?.forEach?.(appendEvent);
  allCourseEvents?.filter?.(event => !normalizedEvents?.hasOwnProperty?.(event.id))?.forEach?.(appendEvent);

  return {
    eventsByDate,
    eventsByCourseId,
    normalizedEvents,
    eventsParticipantData,
  };
};
