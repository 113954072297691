import {
  MESSAGES_GET_REQUEST,
  MESSAGES_GET_SUCCESS,
  MESSAGES_GET_FAILURE,
  MESSAGES_CHECK_MESSAGES,
  MESSAGES_CHECK_MESSAGES_REQUEST,
  MESSAGES_CHECK_MESSAGES_SUCCESS,
  MESSAGE_SET_READ_SUCCESS,
} from '@actions/messages.actions';
import dayjs from 'dayjs';

import * as T from '@types/load.types';
import notifications from '@reducers/notifications.reducer';

const initialState = {
  all: {
    status: T.LoadStatuses.NOT_LOADED,
    data: [],
    error: null,
  },
  unread: {
    data: [],
    status: T.LoadStatuses.NOT_LOADED,
    notification: 0,
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case MESSAGES_GET_REQUEST:
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  case MESSAGES_GET_SUCCESS:
    return {
      ...state,
      all: {
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        data: action.payload.messages,
        error: null,
      },
    };
  case MESSAGE_SET_READ_SUCCESS:
    const newData = [...state.all.data];

    console.log(newData);
    const find = newData.find(nd => nd.message_id === action.payload.message_id);

    find.isread = dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS');
    console.log('in', find);
    console.log('new', newData);

    return {
      ...state,
      all: {
        ...state.all,
        data: newData,
      },
      unread: {
        ...state.unread,
        notification: state.unread.notification - 1,
      },
    };

  case MESSAGES_GET_FAILURE:
    return {
      ...state,
      all: {
        ...state.all,
        status: T.LoadStatuses.FAILED,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case MESSAGES_CHECK_MESSAGES_REQUEST:
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case MESSAGES_CHECK_MESSAGES_SUCCESS:
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.LOADED,
        data: action.payload.messages,
        notification: action.payload.notification,
      },
    };
  default:
    return state;
  }
};

export default messages;
