import dayjs from 'dayjs';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';
import {createSelector} from 'reselect';
import {EVENT_TYPES} from '@components/calendar-with-tooltip/util.jsx';
import {LoadStatuses} from '@types/load.types';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';
import {getNormalizedEmployees, getNormalizedEmployeesEvents} from './employees.selectors';
import {getNormalizedProfileEvents, getProfileId} from './profile.selectors';

export const getCompetencesSearchResults = ({courses: {normalizedData: {competencesSearchResults}}}) => competencesSearchResults;

export const getNormalizedCompetenceDetails = ({courses: {normalizedData: {competenceDetails}}}) => competenceDetails;

export const getNormalizedCourseEvents = ({courses: {normalizedData: {courseEvents}}}) => courseEvents;

export const getNormalizedCompetencegroups = ({courses: {normalizedData: {competencegroups}}}) => competencegroups && competencegroups || [];

export const getGroupedCompetenceIds = ({courses: {normalizedData: {competenceIdsByGroupId}}}) => competenceIdsByGroupId;

export const getNormalizedCompetences = ({courses: {normalizedData: {competences}}}) => competences;

export const getNormalizedSelectedCompetenceGroup = ({courses: {normalizedData: {selectedCompetencegroup}}}) => selectedCompetencegroup;

export const getNormalizedSelectedCompetenceGroups = ({courses: {normalizedData: {selectedCompetencegroups}}}) => selectedCompetencegroups;

export const getCompetences = createSelector(
  ({courses: {competences}}) => competences,
  competences => ({
    ...competences,
    data:
    Array.isArray(competences.data)
    && competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.content && competence.content.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const cover = competence.files.find(file => file.title === 'cover');
      const durations
        = competence.durations
          && competence.durations.length
          && competence.durations[0].duration
        || null; // ToDo: Get dynamic value from API
      const durationType
        = competence.durations
          && competence.durations.length
          && competence.durations[0].type
        || null;

      return {
        ...competence,
        cover,
        image:
          cover && cover.url
          || competence.content && competence.content.image,
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        allDurations: competence.durations,
        durationType,
      };
    }),
  }),
);

export const getFeeaturedCompetences = createSelector(
  ({courses: {featuredCompetences}}) => featuredCompetences,
  featuredCompetences => {
    const competences = getCompetences({courses: {competences: featuredCompetences}});

    return competences;
  },
);

export const getCourseEvents = ({courses: {courseEvents}}) => courseEvents;

export const getCompetencegroups = ({courses: {competencegroups}}) =>
  competencegroups;

export const getCourseCatalogNews = ({courses: {courseCatalogNews}}) =>
  courseCatalogNews;

export const getCompetencetypes = ({courses: {competencetypes}}) =>
  competencetypes;

export const getSelectedCatalogView = ({courses: {filters: {catalogView}}}) => catalogView;

export const getSelectedCourseKindTab = ({courses: {filters: {courseKind:{tab}}}}) => tab;

export const getSelectedCompetencegroupId = ({courses: {selectedCompetencegroupId}}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({courses: {selectedSubcompetencegroupId}}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({courses: {selectedSubSubcompetencegroupId}}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({courses: {courseSignOn}}) =>
  courseSignOn;

export const getSigningOffCourse = ({courses: {courseSignOff}}) =>
  courseSignOff;

export const getCompetencesSearchTerm = ({courses: {competences: {searchTerm}}}) => searchTerm;

export const getCoursesSorting = ({courses: {sorting}}) => sorting;

export const getCourseFilters = ({courses}) => courses.filters;

export const getSelectedCompetencetypes = createSelector(
  ({courses: {filters: {selectedCompetencetypes}}}) => selectedCompetencetypes,
  selectedCompetencetypes =>
    Object.keys(selectedCompetencetypes).reduce(
      (ids, id) => selectedCompetencetypes[id] && [...ids, id] || ids,
      [],
    ),
);

export const getSelectedCompetencetypesNames = createSelector(
  ({courses: {competencetypes, selectedCompetencetypes}}) => ({
    competencetypes,
    selectedCompetencetypes,
  }),
  ({competencetypes, selectedCompetencetypes}) => competencetypes.data
    ? new Set(competencetypes.data
      .filter(({id}) => selectedCompetencetypes.has(id))
      .map(({competence_type}) => competence_type))
    : new Set(),
);

export const getInitializeMyCoursesView = state => state.courses.initializeMyCoursesView;

export const getCompetenceDetails = createSelector(
  ({courses: {competenceDetails}}) => competenceDetails,
  competenceDetails => ({
    ...competenceDetails,
    data: competenceDetails.data && (competence => {
      let cover;
      let duration;

      if (
        Array.isArray(competence.files)
        && competence.files.filter(file => file.title === 'cover').length
      ) {
        cover = competence.files.find(file => file.title === 'cover');
      }
      if (Array.isArray(competence.durations) && competence.durations.length) {
        [duration] = competence.durations;
      }

      return {
        ...competence,
        cover,
        duration: duration && {
          value: duration.duration,
          metric: duration.type,
        },
      };
    })(competenceDetails.data),
  }),
);

export const getActiveCourse = ({courses: {activeCourse}}) => activeCourse;

export const getSignOnCourseResults = ({courses: {courseSignOn: {results, isFetching}}}) => ({
  results,
  isFetching,
});

export const getCurrentSignature = ({courses: {currentSignature}}) => currentSignature;

export const getSignCourse = ({courses: {courseSignCourse}}) => courseSignCourse;

export const getFeaturedContentIds = createSelector(
  getPropertiesForCurrLangAndTrack,
  getConfigObject,
  (configForCurrLangAndMap, configObject) => {
    const featuredTiles = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles');
    const featuredTilesForCurrMap = _get(
      configForCurrLangAndMap,
      'courseCatalog.featuredTiles.contentIds',
    );

    const ids = [];

    if (featuredTilesForCurrMap) {
      if (Array.isArray(featuredTilesForCurrMap)) {
        ids.push(featuredTilesForCurrMap);
      } else {
        ids.push([featuredTilesForCurrMap]);
      }
    }
    if (featuredTiles && featuredTiles.contentIds) {
      if (Array.isArray(featuredTiles.contentIds)) {
        ids.push(featuredTiles.contentIds);
      } else {
        ids.push([featuredTiles.contentIds]);
      }
    }

    return _uniqWith(ids, _isEqual);
  },
);

export const getNormalizedCourseEventsExtraData = createSelector(
  getProfileId,
  getNormalizedCourseEvents,
  getNormalizedProfileEvents,
  getNormalizedEmployees,
  getNormalizedEmployeesEvents,
  (profileId, courseEvents, profileEvents, employees, employeesEvents) => {
    const {status: eventsStatus, eventById, allEventIds} = courseEvents || {};

    if (![LoadStatuses.LOADED, LoadStatuses.LOADED_PARTIAL].includes(eventsStatus)
      || !allEventIds?.length
      || profileEvents.status !== LoadStatuses.LOADED) {
      return courseEvents;
    }
    const extraDataLoading = profileEvents.status === LoadStatuses.IS_LOADING
      || employeesEvents.status === LoadStatuses.IS_LOADING
      || employees.status === LoadStatuses.IS_LOADING;

    if (extraDataLoading) {
      return courseEvents;
    }

    const getIsSelfInWaitlist = eventId => !!profileEvents?.waitlistIds?.includes?.(eventId);
    const getIsSelfConfirmed = eventId => !!profileEvents?.confirmedIds?.includes?.(eventId);

    const {
      data: employeeById,
      ids: employeeIds,
      status: employeesStatus,
    } = employees || {};

    const {
      employeeIdsConfirmedByEventId,
      employeeIdsWaitlistByEventId,
      employeeIdsByEventId,
      status: employeesEventsStatus,
    } = employeesEvents || {};

    const isEmployeesLoaded = employeesStatus === LoadStatuses.LOADED
      && employeesEventsStatus === LoadStatuses.LOADED;

    const getEmployee = employeeId => employeeById?.[employeeId];

    const getEmployeeIdsNotSignedUp = (eventId, selfSignedUp) => {
      const employeeIdsSignedUp = employeeIdsByEventId[eventId] || [];

      return employeeIds.filter(employeeId => {
        if (selfSignedUp && employeeId === profileId) return false;

        return !employeeIdsSignedUp.includes(employeeId);
      });
    };

    const eventByIdExtraData = allEventIds.reduce((acc, eventId) => {
      const event = eventById[eventId];

      if (!event) {
        return acc;
      }

      const isSelfInWaitlist = getIsSelfInWaitlist(eventId);
      const isSelfConfirmed = getIsSelfConfirmed(eventId);

      const isSelfSignedUp = isSelfInWaitlist || isSelfConfirmed;

      const participantsData = {
        isSelfInWaitlist,
        isSelfConfirmed,
        isSelfSignedUp,
        employeeIdsSignedUp: [],
        employeeIdsNotSignedUp: [],
        employeeIdsWaitlist: [],
        employeeIdsConfirmed: [],
        employeesSignedUp: [],
        employeesNotSignedUp: [],
        employeesWaitlist: [],
        employeesConfirmed: [],
      };

      const me = isSelfSignedUp ? [profileId] : [];

      if (isEmployeesLoaded) {
        participantsData.employeeIdsNotSignedUp = getEmployeeIdsNotSignedUp(eventId, isSelfSignedUp);
        participantsData.employeeIdsSignedUp = [...me, ...employeeIdsByEventId[eventId] || []];
        participantsData.employeeIdsConfirmed = [...me, ...employeeIdsConfirmedByEventId[eventId] || []];
        participantsData.employeeIdsWaitlist = [...me, ...employeeIdsWaitlistByEventId[eventId] || []];
        // todo: remove this and use ids instead
        participantsData.employeesNotSignedUp = participantsData.employeeIdsNotSignedUp.map(getEmployee)?.filter(Boolean);
        participantsData.employeesSignedUp = participantsData.employeeIdsSignedUp.map(getEmployee)?.filter(Boolean);
        participantsData.employeesWaitlist = participantsData.employeeIdsWaitlist.map(getEmployee)?.filter(Boolean);
        participantsData.employeesConfirmed = participantsData.employeeIdsConfirmed.map(getEmployee)?.filter(Boolean);
      }

      return {
        ...acc,
        [eventId]: {
          ...event,
          participantsData,
        },
      };
    }, {});

    return {
      ...courseEvents,
      loadedExtraData: true,
      eventById: eventByIdExtraData,
    };
  },
);

export const getCompetencesData = createSelector(
  getCompetences,
  competences => ({
    ...competences,
    data:
    Array.isArray(competences.data)
    && competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.content && competence.content.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const cover = competence.files.find(file => file.title === 'cover');
      const durations
        = competence.durations
          && competence.durations.length
          && competence.durations[0].duration
        || null; // ToDo: Get dynamic value from API
      const durationType
        = competence.durations
          && competence.durations.length
          && competence.durations[0].type
        || null;

      return {
        ...competence,
        cover,
        image:
          cover && cover.url
          || competence.content && competence.content.image,
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        allDurations: competence.durations,
        durationType,
      };
    }),
  }),
);

export const getSelectedCompetenceTypes = createSelector(
  getNormalizedSelectedCompetenceGroups,
  competencegroups => {
    const {competences} = competencegroups || {};

    if (!competences?.length) return [];

    const types = [];
    const unique = new Set();

    competences.forEach(({
      competence_type,
      competence_type_id,
      competence_type_key,
    }) => {
      if (unique.has(competence_type_id)) return;

      unique.add(competence_type_id);

      types.push({
        competence_type,
        competence_type_id,
        competence_type_key,
      });
    });

    return types;
  },
);

export const getIsFetchingCompetenceGroups = createSelector(
  getNormalizedCompetencegroups,
  competencegroups => competencegroups.status === LoadStatuses.IS_LOADING,
);

export const getCompetencesList = createSelector(
  getCompetencesSearchTerm,
  getCompetencesSearchResults,
  getNormalizedSelectedCompetenceGroups,
  getIsFetchingCompetenceGroups,
  (searchTerm, searchResults, selectedCompetencegroups, isFetching) => {
    if (searchTerm) {
      const {data, status} = searchResults;

      return {
        data: !!data && Object.values(data),
        isFetching: status === LoadStatuses.IS_LOADING,
      };
    }

    return {
      data: selectedCompetencegroups?.competences,
      isFetching,
    };
  },
);

export const getIsFetchingCourseEvents = createSelector(
  getNormalizedCourseEventsExtraData,
  courseEvents => courseEvents.status === LoadStatuses.IS_LOADING,
);

export const getAllEventsSortedByDate = createSelector(
  getNormalizedCourseEventsExtraData,
  events => {
    const {eventById, allEventIds: sortedEventIds} = events || {};

    if (!sortedEventIds?.length) return [];

    return sortedEventIds.map(id => eventById[id]);
  },
);

export const getCompetenceDetailsById = createSelector(
  getNormalizedCompetenceDetails,
  getNormalizedCourseEventsExtraData,
  (_, cid) => cid,
  (competenceDetails, events, cid) => {
    const {data} = competenceDetails;

    if (!data?.[cid]) return null;

    const {eventById, eventIdsByCourseId} = events;

    const obj = {
      ...data[cid],
      events: [],
      calendarItems: {},
    };

    if (eventIdsByCourseId[cid]) {
      eventIdsByCourseId[cid].forEach(id => {
        const event = eventById[id];

        if (!event) return;

        obj.events.push(event);

        const year = dayjs(event.startDate).year();
        const month = dayjs(event.startDate).month();
        const yearMonthString = `${year}-${month}`;
        const dayNumber = dayjs(event.startDate).format('D');

        if (!obj.calendarItems[yearMonthString]) {
          obj.calendarItems[yearMonthString] = {};
        }

        if (!obj.calendarItems[yearMonthString][dayNumber]) {
          obj.calendarItems[yearMonthString][dayNumber] = [];
        }

        const tooltip = {
          title: event.title,
          dateStart: event.startDate,
          time: event.startTime,
          duration: event.duration,
          id: event.id,
          competence_id: event.competence_id,
          persons: event?.participantsData?.employeesConfirmed?.map?.(employee => ({
            fullname: employee.fullname,
            firstname: employee.firstname,
            lastname: employee.lastname,
            person_id: employee.person_id,
            user_name: employee.user_name,
          })),
          eventType: EVENT_TYPES.courseEvent,
          location: event.location,
        };

        obj.calendarItems[yearMonthString][dayNumber].push(tooltip);
      });
    }

    return obj;
  },
);

