import {call, put, takeLatest} from 'redux-saga/effects';
import * as manageActions from '@actions/manage.actions';
import retry from '@utils/sagas.utils';
import {backendUrl} from '@config';
import axios from 'axios';

const ManageAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});


function* fetchRoles(action) {
  yield put(manageActions.fetchManageRolesRequest());

  try {
    const roles = yield call(fetchMangeRolesApi, {});

    yield put(manageActions.fetchManageRolesSuccess({roles}));
  } catch (error) {
    console.error(error);
    yield put(manageActions.fetchManageRolesFailure({error}));
  }
}

function* fetchMangeRolesApi(action) {
  return yield retry(() =>
    ManageAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/roles/list_roles`,
        withCredentials: true,
      })
      .then(response => response.data));
}

function* fetchCompetences(action) {
  yield put(manageActions.fetchManageCompetencesRequest());

  try {
    const competences = yield call(fetchMangeComptencesApi, {});

    yield put(manageActions.fetchManageCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    yield put(manageActions.fetchManageCompetencesFailure({error}));
  }
}


function* fetchMangeComptencesApi(action) {
  return yield retry(() =>
    ManageAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/courses/search_competences?search_for=&ajax=1&json=1&batch=0&limit=50&sort_by=date`,
        withCredentials: true,
      })
      .then(response => response.data));
}


const manageSagas = [
  takeLatest(manageActions.fetchManageRoles().type, fetchRoles),
  takeLatest(manageActions.fetchManageCompetences().type, fetchCompetences),
];

export default manageSagas;
