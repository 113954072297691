const prefix = 'employees';

export const EMPLOYEES_GET = `${prefix} get`;
export const EMPLOYEES_GET_REQUEST = `${prefix} get request`;
export const EMPLOYEES_GET_SUCCESS = `${prefix} get success`;
export const EMPLOYEES_GET_FAILURE = `${prefix} get failure`;

export const employeesGetRequest = () => ({type: EMPLOYEES_GET_REQUEST});

export const employeesGet = payload => ({
  type: EMPLOYEES_GET,
  payload,
});

export const employeesGetSuccess = payload => ({
  type: EMPLOYEES_GET_SUCCESS,
  payload,
});

export const employeesGetFailure = payload => ({
  type: EMPLOYEES_GET_FAILURE,
  payload,
});

export const EMPLOYEES_APPROVE_REJECT_SELF_SIGN = 'EMPLOYEES_APPROVE_REJECT_SELF_SIGN';
export const EMPLOYEES_APPROVE_REJECT_SELF_SIGN_REQUEST = 'EMPLOYEES_APPROVE_REJECT_SELF_SIGN_REQUEST';
export const EMPLOYEES_APPROVE_REJECT_SELF_SIGN_SUCCESS = 'EMPLOYEES_APPROVE_REJECT_SELF_SIGN_SUCCESS';
export const EMPLOYEES_APPROVE_REJECT_SELF_SIGN_FAILURE = 'EMPLOYEES_APPROVE_REJECT_SELF_SIGN_FAILURE';

export const employeesApproveRejectSelfSign = payload => ({
  type: EMPLOYEES_APPROVE_REJECT_SELF_SIGN,
  payload,
});
export const employeesApproveRejectSelfSignRequest = () => ({type: EMPLOYEES_APPROVE_REJECT_SELF_SIGN_REQUEST});
export const employeesApproveRejectSelfSignSuccess = payload => ({
  type: EMPLOYEES_APPROVE_REJECT_SELF_SIGN_SUCCESS,
  payload,
});
export const employeesApproveRejectSelfSignFailure = () => ({type: EMPLOYEES_APPROVE_REJECT_SELF_SIGN_FAILURE});


export const EMPLOYEES_FETCH_FUNCTIONS = `${prefix} fetch functions`;
export const EMPLOYEES_FETCH_FUNCTIONS_REQUEST = `${prefix} fetch functions request`;
export const EMPLOYEES_FETCH_FUNCTIONS_SUCCESS = `${prefix} fetch functions success`;
export const EMPLOYEES_FETCH_FUNCTIONS_FAILURE = `${prefix} fetch functions failure`;

export const employeesFetchFunctions = () => ({type: EMPLOYEES_FETCH_FUNCTIONS});

export const employeesFetchFunctionsRequest = () => ({type: EMPLOYEES_FETCH_FUNCTIONS_REQUEST});

export const employeesFetchFunctionsSuccess = payload => ({
  type: EMPLOYEES_FETCH_FUNCTIONS_SUCCESS,
  payload,
});

export const employeesFetchFunctionsFailure = payload => ({
  type: EMPLOYEES_FETCH_FUNCTIONS_FAILURE,
  payload,
});

export const SWITCH_EMPLOYEE_TAB = `${prefix}/employee/switch/tab`;
export const switchEmployeeTab = payload => ({
  type: SWITCH_EMPLOYEE_TAB,
  payload,
});

export const EMPLOYEES_FETCH_EXTRADATA = `${prefix} fetch extradata`;
export const EMPLOYEES_FETCH_EXTRADATA_REQUEST = `${prefix} fetch extradata request`;
export const EMPLOYEES_FETCH_EXTRADATA_SUCCESS = `${prefix} fetch extradata success`;
export const EMPLOYEES_FETCH_EXTRADATA_FAILURE = `${prefix} fetch extradata failure`;

export const employeesFetchExtraData = () => ({type: EMPLOYEES_FETCH_EXTRADATA});

export const employeesFetchExtraDataRequest = () => ({type: EMPLOYEES_FETCH_EXTRADATA_REQUEST});

export const employeesFetchExtraDataSuccess = payload => ({
  type: EMPLOYEES_FETCH_EXTRADATA_SUCCESS,
  payload,
});

export const employeesFetchExtraDataFailure = payload => ({
  type: EMPLOYEES_FETCH_EXTRADATA_FAILURE,
  payload,
});

export const EMPLOYEES_SEARCH_PHRAZE_CHANGE = `${prefix} search phraze change`;

export const employeesSearchPhrazeChange = payload => ({
  type: EMPLOYEES_SEARCH_PHRAZE_CHANGE,
  payload,
});

export const EMPLOYEES_SEARCH_REQUEST = `${prefix} search request`;
export const EMPLOYEES_SEARCH_SUCCESS = `${prefix} search success`;
export const EMPLOYEES_SEARCH_FAILURE = `${prefix} search failure`;

export const employeesSearchRequest = () => ({type: EMPLOYEES_SEARCH_REQUEST});

export const employeesSearchSuccess = payload => ({
  type: EMPLOYEES_SEARCH_SUCCESS,
  payload,
});

export const employeesSearchFailure = payload => ({
  type: EMPLOYEES_SEARCH_FAILURE,
  payload,
});

export const EMPLOYEES_PERSON_SELECT = `${prefix} person select`;

export const employeesSelectPerson = payload => ({
  type: EMPLOYEES_PERSON_SELECT,
  payload,
});

export const EMPLOYEES_EDIT_VIEW_INIT = `${prefix} edit view init`;

export const employeesEditViewInit = payload => ({
  type: EMPLOYEES_EDIT_VIEW_INIT,
  payload,
});

export const EMPLOYEES_REPORT_VIEW_INIT = `${prefix}/report/view/init`;
export const EMPLOYEES_REPORT_VIEW_REQUEST = `${prefix}/report/fetch/request`;
export const EMPLOYEES_REPORT_VIEW_SUCCESS = `${prefix}/report/fetch/success`;
export const EMPLOYEES_REPORT_VIEW_FAILURE = `${prefix}/report/fetch/failure`;

export const employeesReportViewInit = payload => ({
  type: EMPLOYEES_REPORT_VIEW_INIT,
  payload,
});

export const employeesReportRequest = payload => ({
  type: EMPLOYEES_REPORT_VIEW_REQUEST,
  payload,
});

export const employeesReportSuccess = payload => ({
  type: EMPLOYEES_REPORT_VIEW_SUCCESS,
  payload,
});

export const employeesReportFailure = payload => ({
  type: EMPLOYEES_REPORT_VIEW_FAILURE,
  payload,
});

export const CAMP_EXTRA_VIEW_INIT = `${prefix}/report/view/init`;
export const CAMP_EXTRA_VIEW_REQUEST = `${prefix}/report/fetch/request`;
export const CAMP_EXTRA_VIEW_SUCCESS = `${prefix}/report/fetch/success`;
export const CAMP_EXTRA_VIEW_FAILURE = `${prefix}/report/fetch/failure`;

export const campExtraViewInit = payload => ({
  type: CAMP_EXTRA_VIEW_INIT,
  payload,
});

export const campExtraRequest = () => ({type: CAMP_EXTRA_VIEW_REQUEST});

export const campExtraSuccess = payload => ({
  type: CAMP_EXTRA_VIEW_SUCCESS,
  payload,
});

export const campExtraFailure = payload => ({
  type: CAMP_EXTRA_VIEW_FAILURE,
  payload,
});

export const EMPLOYEES_COURSEMATRIX_VIEW_REQUEST = `${prefix}/coursematrix/fetch/request`;
export const EMPLOYEES_COURSEMATRIX_VIEW_SUCCESS = `${prefix}/coursematrix/fetch/success`;
export const EMPLOYEES_COURSEMATRIX_VIEW_FAILURE = `${prefix}/coursematrix/fetch/failure`;

export const employeesCoursematrixRequest = () => ({type: EMPLOYEES_COURSEMATRIX_VIEW_REQUEST});

export const employeesCoursematrixSuccess = payload => ({
  type: EMPLOYEES_COURSEMATRIX_VIEW_SUCCESS,
  payload,
});

export const employeesCoursematrixFailure = payload => ({
  type: EMPLOYEES_COURSEMATRIX_VIEW_FAILURE,
  payload,
});

export const EMPLOYEES_ADD_VIEW_INIT = `${prefix} add view init`;

export const employeesAddViewInit = payload => ({
  type: EMPLOYEES_ADD_VIEW_INIT,
  payload,
});

export const EMPLOYEES_FETCH_SELECTED_PERSON = `${prefix} fetch person`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST = `${prefix} fetch person request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS = `${prefix} fetch person success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE = `${prefix} fetch person failure`;

export const employeesFetchSelectedPerson = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON,
  payload,
});

export const employeesFetchSelectedPersonRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_SELECTED_PERSON_REPORT = `${prefix} fetch person/report`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST = `${prefix} fetch person/report/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS = `${prefix} fetch person/report/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE = `${prefix} fetch person/report/failure`;

export const employeesFetchSelectedPersonReport = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_REPORT,
  payload,
});

export const employeesFetchSelectedPersonReportRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonReportSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonReportFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS = `${prefix} fetch person/checklists`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST = `${prefix} fetch person/checklists/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS = `${prefix} fetch person/checklists/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_FAILURE = `${prefix} fetch person/checklists/failure`;

export const employeesFetchSelectedPersonChecklists = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS,
  payload,
});

export const employeesFetchSelectedPersonChecklistsRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonChecklistsSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonChecklistsFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_FAILURE,
  payload,
});



export const EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES = `${prefix}/fetch/person/activites`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST = `${prefix}/fetch/person/activites/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS = `${prefix}/fetch/person/activites/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_FAILURE = `${prefix}/fetch/person/activites/failure`;

export const employeesFetchSelectedPersonActivities = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES,
  payload,
});

export const employeesFetchSelectedPersonActivitiesRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonActivitiesSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonActivitiesFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_FAILURE,
  payload,
});


export const EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING = `${prefix}/fetch/person/expiring2`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_REQUEST = `${prefix}/fetch/person/expiring/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_SUCCESS = `${prefix}/fetch/person/expiring/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_FAILURE = `${prefix}/fetch/person/expiring/failure`;

export const employeesFetchSelectedPersonExpiring = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING,
  payload,
});

export const employeesFetchSelectedPersonExpiringRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonExpiringSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonExpiringFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_FAILURE,
  payload,
});


export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES = `${prefix}/fetch/person/competences`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST = `${prefix}/fetch/person/competences/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS = `${prefix}/fetch/person/competences/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_FAILURE = `${prefix}/fetch/person/competences/failure`;

export const employeesFetchSelectedPersonCompetences = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES,
  payload,
});

export const employeesFetchSelectedPersonCompetencesRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonCompetencesSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonCompetencesFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN = `${prefix}/fetch/person/competences/children`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_REQUEST = `${prefix}/fetch/person/competences/children/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_SUCCESS = `${prefix}/fetch/person/competences/children/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_FAILURE = `${prefix}/fetch/person/competences/children/failure`;

export const employeesFetchSelectedPersonCompetencesChildren = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN,
  payload,
});

export const employeesFetchSelectedPersonCompetencesChildrenRequest = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_REQUEST,
  payload,
});

export const employeesFetchSelectedPersonCompetencesChildrenSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonCompetencesChildrenFailure = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_CHILDREN_FAILURE,
  payload,
});


export const EMPLOYEES_EDIT_SELECTED_PERSON = `${prefix} edit selected person`;
export const EMPLOYEES_EDIT_SELECTED_PERSON_PUT_REQUEST = `${prefix} edit selected person put request`;
export const EMPLOYEES_EDIT_SELECTED_PERSON_PUT_SUCCESS = `${prefix} edit selected person put success`;
export const EMPLOYEES_EDIT_SELECTED_PERSON_PUT_FAILURE = `${prefix} edit selected person put failure`;

export const employeesEditSelectedPerson = payload => ({
  type: EMPLOYEES_EDIT_SELECTED_PERSON,
  payload,
});

export const employeesEditSelectedPersonPutRequest = () => ({type: EMPLOYEES_EDIT_SELECTED_PERSON_PUT_REQUEST});

export const employeesEditSelectedPersonPutSuccess = payload => ({
  type: EMPLOYEES_EDIT_SELECTED_PERSON_PUT_SUCCESS,
  payload,
});

export const employeesEditSelectedPersonPutFailure = payload => ({
  type: EMPLOYEES_EDIT_SELECTED_PERSON_PUT_FAILURE,
  payload,
});

export const EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT = `${prefix}/update/selected/person/employment`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_REQUEST = `${prefix}/update/selected/person/employment/request`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_SUCCESS = `${prefix}/update/selected/person/employment/success`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_FAILURE = `${prefix}/update/selected/person/employment/failure`;

export const employeesUpdateSelectedPersonEmployment = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT,
  payload,
});

export const employeesUpdateSelectedPersonEmploymentRequest = () => ({type: EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_REQUEST});

export const employeesUpdateSelectedPersonEmploymentSuccess = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_SUCCESS,
  payload,
});

export const employeesUpdateSelectedPersonEmploymentFailure = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT_FAILURE,
  payload,
});

export const EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES = `${prefix} update selected person roles`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_REQUEST = `${prefix} update selected person roles post request`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS = `${prefix} update selected person roles post success`;
export const EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_FAILURE = `${prefix} update selected person roles post failure`;

export const employeesUpdateSelectedPersonRoles = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES,
  payload,
});

export const employeesUpdateSelectedPersonRolesPostRequest = () => ({type: EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_REQUEST});

export const employeesUpdateSelectedPersonRolesPostSuccess = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS,
  payload,
});

export const employeesUpdateSelectedPersonRolesPostFailure = payload => ({
  type: EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_FAILURE,
  payload,
});

export const EMPLOYEES_ADD_PERSON = `${prefix} add person`;
export const EMPLOYEES_ADD_PERSON_POST_REQUEST = `${prefix} add person post request`;
export const EMPLOYEES_ADD_PERSON_POST_SUCCESS = `${prefix} add person post success`;
export const EMPLOYEES_ADD_PERSON_POST_FAILURE = `${prefix} add person post failure`;

export const employeesAddPerson = payload => ({
  type: EMPLOYEES_ADD_PERSON,
  payload,
});

export const employeesAddPersonPostRequest = () => ({type: EMPLOYEES_ADD_PERSON_POST_REQUEST});

export const employeesAddPersonPostSuccess = payload => ({
  type: EMPLOYEES_ADD_PERSON_POST_SUCCESS,
  payload,
});

export const employeesAddPersonPostFailure = payload => ({
  type: EMPLOYEES_ADD_PERSON_POST_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_STATISTICS = `${prefix} fetch statistics`;
export const EMPLOYEES_FETCH_STATISTICS_GET_REQUEST = `${prefix} fetch statistics get request`;
export const EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS = `${prefix} fetch statistics get success`;
export const EMPLOYEES_FETCH_STATISTICS_GET_FAILURE = `${prefix} fetch statistics get failure`;

export const employeesFetchStatistics = payload => ({
  type: EMPLOYEES_FETCH_STATISTICS,
  payload,
});

export const employeesFetchStatisticsGetRequest = () => ({type: EMPLOYEES_FETCH_STATISTICS_GET_REQUEST});

export const employeesFetchStatisticsGetSuccess = payload => ({
  type: EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS,
  payload,
});

export const employeesFetchStatisticsGetFailure = payload => ({
  type: EMPLOYEES_FETCH_STATISTICS_GET_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_ORGANISATION = 'EMPLOYEES_FETCH_ORGANISATION';
export const EMPLOYEES_FETCH_ORGANISATION_REQUEST = 'EMPLOYEES_FETCH_ORGANISATION_REQUEST';
export const EMPLOYEES_FETCH_ORGANISATION_SUCCESS = 'EMPLOYEES_FETCH_ORGANISATION_SUCCESS';
export const EMPLOYEES_FETCH_ORGANISATION_FAILURE = 'EMPLOYEES_FETCH_ORGANISATION_FAILURE';

export const employeesFetchOrganisationRequest = () => ({type: EMPLOYEES_FETCH_ORGANISATION_REQUEST});

export const employeesFetchOrganisationSuccess = payload => ({
  type: EMPLOYEES_FETCH_ORGANISATION_SUCCESS,
  payload,
});

export const employeesFetchOrganisationFailure = payload => ({
  type: EMPLOYEES_FETCH_ORGANISATION_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_TREE_PROGRESS = 'EMPLOYEES_FETCH_TREE_PROGRESS';
export const EMPLOYEES_FETCH_TREE_PROGRESS_REQUEST = 'EMPLOYEES_FETCH_TREE_PROGRESS_REQUEST';
export const EMPLOYEES_FETCH_TREE_PROGRESS_SUCCESS = 'EMPLOYEES_FETCH_TREE_PROGRESS_SUCCESS';
export const EMPLOYEES_FETCH_TREE_PROGRESS_FAILURE = 'EMPLOYEES_FETCH_TREE_PROGRESS_FAILURE';

export const employeesFetchTreeProgress = payload => ({
  type: EMPLOYEES_FETCH_TREE_PROGRESS,
  payload,
});

export const employeesFetchTreeProgressRequest = payload => ({
  type: EMPLOYEES_FETCH_TREE_PROGRESS_REQUEST,
  payload,
});

export const employeesFetchTreeProgressSuccess = payload => ({
  type: EMPLOYEES_FETCH_TREE_PROGRESS_SUCCESS,
  payload,
});

export const employeesFetchTreeProgressFailure = payload => ({
  type: EMPLOYEES_FETCH_TREE_PROGRESS_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_TREE = 'EMPLOYEES_FETCH_TREE';
export const EMPLOYEES_FETCH_TREE_REQUEST = 'EMPLOYEES_FETCH_TREE_REQUEST';
export const EMPLOYEES_FETCH_TREE_SUCCESS = 'EMPLOYEES_FETCH_TREE_SUCCESS';
export const EMPLOYEES_FETCH_TREE_FAILURE = 'EMPLOYEES_FETCH_TREE_FAILURE';

export const employeesFetchTree = payload => ({
  type: EMPLOYEES_FETCH_TREE,
  payload,
});

export const employeesFetchTreeRequest = payload => ({
  type: EMPLOYEES_FETCH_TREE_REQUEST,
  payload,
});

export const employeesFetchTreeSuccess = payload => ({
  type: EMPLOYEES_FETCH_TREE_SUCCESS,
  payload,
});

export const employeesFetchTreeFailure = payload => ({
  type: EMPLOYEES_FETCH_TREE_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_CHECKLISTS = `${prefix}/fetch/checklists`;
export const EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST = `${prefix}/fetch/checklists/get/request`;
export const EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS = `${prefix}/fetch/checklists/get/success`;
export const EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE = `${prefix}/fetch/checklists/get/failure`;

export const employeesFetchChecklists = payload => ({
  type: EMPLOYEES_FETCH_CHECKLISTS,
  payload,
});

export const employeesFetchChecklistsGetRequest = () => ({type: EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST});

export const employeesFetchChecklistsGetSuccess = payload => ({
  type: EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS,
  payload,
});

export const employeesFetchChecklistsGetFailure = payload => ({
  type: EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE,
  payload,
});

export const EMPLOYEE_MOVE = `${prefix}/employee/move`;
export const EMPLOYEE_MOVE_REQUEST = `${prefix}/employee/move/request`;
export const EMPLOYEE_MOVE_SUCCESS = `${prefix}/employee/move/success`;
export const EMPLOYEE_MOVE_FAILURE = `${prefix}/employee/move/failure`;

export const employeeMove = payload => ({
  type: EMPLOYEE_MOVE,
  payload,
});

export const employeeMoveRequest = () => ({type: EMPLOYEE_MOVE_REQUEST});

export const employeeMoveSuccess = payload => ({
  type: EMPLOYEE_MOVE_SUCCESS,
  payload,
});

export const employeeMoveFailure = payload => ({
  type: EMPLOYEE_MOVE_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_ACTIVITIES = `${prefix} fetch activities`;
export const EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST = `${prefix} fetch activities get request`;
export const EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS = `${prefix} fetch activities get success`;
export const EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE = `${prefix} fetch activities get failure`;

export const employeesFetchActivities = payload => ({
  type: EMPLOYEES_FETCH_ACTIVITIES,
  payload,
});

export const employeesFetchActivitiesGetRequest = () => ({type: EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST});

export const employeesFetchActivitiesGetSuccess = payload => ({
  type: EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS,
  payload,
});

export const employeesFetchActivitiesGetFailure = payload => ({
  type: EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_EXPIRING_COMPETENCES = `${prefix}/fetch/expiring/competences`;
export const EMPLOYEES_FETCH_EXPIRING_COMPETENCES_REQUEST = `${prefix}/fetch/expiring/competences/request`;
export const EMPLOYEES_FETCH_EXPIRING_COMPETENCES_SUCCESS = `${prefix}/fetch/expiring/competences/success`;
export const EMPLOYEES_FETCH_EXPIRING_COMPETENCES_FAILURE = `${prefix}/fetch/expiring/competences/failure`;

export const employeesFetchExpiringCompetences = payload => ({
  type: EMPLOYEES_FETCH_EXPIRING_COMPETENCES,
  payload,
});

export const employeesFetchExpiringCompetencesRequest = () => ({type: EMPLOYEES_FETCH_EXPIRING_COMPETENCES_REQUEST});

export const employeesFetchExpiringCompetencesSuccess = payload => ({
  type: EMPLOYEES_FETCH_EXPIRING_COMPETENCES_SUCCESS,
  payload,
});

export const employeesFetchExpiringCompetencesFailure = payload => ({
  type: EMPLOYEES_FETCH_EXPIRING_COMPETENCES_FAILURE,
  payload,
});

export const EMPLOYEES_RESET_PASSWORD = `${prefix} reset password`;
export const EMPLOYEES_RESET_PASSWORD_REQUEST = `${prefix} reset password request`;
export const EMPLOYEES_RESET_PASSWORD_SUCCESS = `${prefix} reset password success`;
export const EMPLOYEES_RESET_PASSWORD_FAILURE = `${prefix} reset password failure`;

export const employeesResetPassword = payload => ({
  type: EMPLOYEES_RESET_PASSWORD,
  payload,
});
export const employeesResetPasswordRequest = () => ({type: EMPLOYEES_RESET_PASSWORD_REQUEST});
export const employeesResetPasswordSuccess = payload => ({
  type: EMPLOYEES_RESET_PASSWORD_SUCCESS,
  payload,
});
export const employeesResetPasswordFailure = payload => ({
  type: EMPLOYEES_RESET_PASSWORD_FAILURE,
  payload,
});

export const EMPLOYEES_EDIT_PASSWORD = `${prefix} edit password`;
export const EMPLOYEES_EDIT_PASSWORD_REQUEST = `${prefix} edit password request`;
export const EMPLOYEES_EDIT_PASSWORD_SUCCESS = `${prefix} edit password success`;
export const EMPLOYEES_EDIT_PASSWORD_FAILURE = `${prefix} edit password failure`;

export const employeesEditPassword = payload => ({
  type: EMPLOYEES_EDIT_PASSWORD,
  payload,
});
export const employeesEditPasswordRequest = () => ({type: EMPLOYEES_EDIT_PASSWORD_REQUEST});
export const employeesEditPasswordSuccess = payload => ({
  type: EMPLOYEES_EDIT_PASSWORD_SUCCESS,
  payload,
});
export const employeesEditPasswordFailure = payload => ({
  type: EMPLOYEES_EDIT_PASSWORD_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS = `${prefix}/fetch/selected-person/events`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_REQUEST = `${prefix}/fetch/selected-person/events/request`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_SUCCESS = `${prefix}/fetch/selected-person/events/success`;
export const EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_FAILURE = `${prefix}/fetch/selected-person/events/failure`;

export const employeesFetchSelectedPersonEvents = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS,
  payload,
});

export const employeesFetchSelectedPersonEventsRequest = () => ({type: EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_REQUEST});

export const employeesFetchSelectedPersonEventsSuccess = payload => ({
  type: EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_SUCCESS,
  payload,
});

export const employeesFetchSelectedPersonEventsFailure = payload => ({type: EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_FAILURE});


export const EMPLOYEES_FETCH_EVENTS = `${prefix}/fetch/events`;
export const EMPLOYEES_FETCH_EVENTS_REQUEST = `${prefix} fetch events request`;
export const EMPLOYEES_FETCH_EVENTS_SUCCESS = `${prefix} fetch events success`;
export const EMPLOYEES_FETCH_EVENTS_FAILURE = `${prefix} fetch events failure`;

export const employeesFetchEvents = payload => ({
  type: EMPLOYEES_FETCH_EVENTS,
  payload,
});
export const employeesFetchEventsRequest = () => ({type: EMPLOYEES_FETCH_EVENTS_REQUEST});
export const employeesFetchEventsSuccess = payload => ({
  type: EMPLOYEES_FETCH_EVENTS_SUCCESS,
  payload,
});
export const employeesFetchEventsFailure = payload => ({
  type: EMPLOYEES_FETCH_EVENTS_FAILURE,
  payload,
});

export const EMPLOYEES_FETCH_EVENTS_WAITLIST = `${prefix}/fetch/eventsWaitlist`;
export const EMPLOYEES_FETCH_EVENTS_WAITLIST_REQUEST = `${prefix} fetch eventsWaitlist request`;
export const EMPLOYEES_FETCH_EVENTS_WAITLIST_SUCCESS = `${prefix} fetch eventsWaitlist success`;
export const EMPLOYEES_FETCH_EVENTS_WAITLIST_FAILURE = `${prefix} fetch eventsWaitlist failure`;

export const employeesFetchEventsWaitlist = payload => ({
  type: EMPLOYEES_FETCH_EVENTS_WAITLIST,
  payload,
});
export const employeesFetchEventsWaitlistRequest = () => ({type: EMPLOYEES_FETCH_EVENTS_WAITLIST_REQUEST});
export const employeesFetchEventsWaitlistSuccess = payload => ({
  type: EMPLOYEES_FETCH_EVENTS_WAITLIST_SUCCESS,
  payload,
});
export const employeesFetchEventsWaitlistFailure = payload => ({
  type: EMPLOYEES_FETCH_EVENTS_WAITLIST_FAILURE,
  payload,
});

export const EMPLOYEES_UPDATE_EVENTS = `${prefix} update employees events`;

export const employeesUpdateEvents = payload => ({
  type: EMPLOYEES_UPDATE_EVENTS,
  payload,
});

export const EMPLOYEES_SAVE_ROLE = `${prefix} save role`;
export const EMPLOYEES_SAVE_ROLE_REQUEST = `${prefix} save role request`;
export const EMPLOYEES_SAVE_ROLE_SUCCESS = `${prefix} save role success`;
export const EMPLOYEES_SAVE_ROLE_FAILURE = `${prefix} save role failure`;

export const employeesSaveRole = payload => ({
  type: EMPLOYEES_SAVE_ROLE,
  payload,
});
export const employeesSaveRoleRequest = () => ({type: EMPLOYEES_SAVE_ROLE_REQUEST});
export const employeesSaveRoleSuccess = payload => ({
  type: EMPLOYEES_SAVE_ROLE_SUCCESS,
  payload,
});
export const employeesSaveRoleFailure = payload => ({
  type: EMPLOYEES_SAVE_ROLE_FAILURE,
  payload,
});

export const EMPLOYEES_SAVE_VERIFICATION = `${prefix}/save/verification`;
export const EMPLOYEES_SAVE_VERIFICATION_REQUEST = `${prefix}/save/verification/request`;
export const EMPLOYEES_SAVE_VERIFICATION_SUCCESS = `${prefix}/save/verification/success`;
export const EMPLOYEES_SAVE_VERIFICATION_FAILURE = `${prefix}/save/verification/failure`;

export const employeesSaveVerification = payload => ({
  type: EMPLOYEES_SAVE_VERIFICATION,
  payload,
});
export const employeesSaveVerificationRequest = () => ({type: EMPLOYEES_SAVE_VERIFICATION_REQUEST});
export const employeesSaveVerificationSuccess = payload => ({
  type: EMPLOYEES_SAVE_VERIFICATION_SUCCESS,
  payload,
});
export const employeesSaveVerificationFailure = payload => ({
  type: EMPLOYEES_SAVE_VERIFICATION_FAILURE,
  payload,
});

export const EMPLOYEES_SAVE_REQUIREMENTS = `${prefix} save requirements`;
export const EMPLOYEES_SAVE_REQUIREMENTS_REQUEST = `${prefix} save requirements request`;
export const EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS = `${prefix} save requirements success`;
export const EMPLOYEES_SAVE_REQUIREMENTS_FAILURE = `${prefix} save requirements failure`;

export const employeesSaveRequirements = payload => ({
  type: EMPLOYEES_SAVE_REQUIREMENTS,
  payload,
});
export const employeesSaveRequirementsRequest = () => ({type: EMPLOYEES_SAVE_REQUIREMENTS_REQUEST});
export const employeesSaveRequirementsSuccess = payload => ({
  type: EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS,
  payload,
});
export const employeesSaveRequirementsFailure = payload => ({
  type: EMPLOYEES_SAVE_REQUIREMENTS_FAILURE,
  payload,
});

export const EMPLOYEES_REMOVE_REQUIREMENTS = `${prefix} remove requirements`;
export const EMPLOYEES_REMOVE_REQUIREMENTS_REQUEST = `${prefix} remove requirements request`;
export const EMPLOYEES_REMOVE_REQUIREMENTS_SUCCESS = `${prefix} remove requirements success`;
export const EMPLOYEES_REMOVE_REQUIREMENTS_FAILURE = `${prefix} remove requirements failure`;

export const employeesRemoveRequirements = payload => ({
  type: EMPLOYEES_REMOVE_REQUIREMENTS,
  payload,
});
export const employeesRemoveRequirementsRequest = () => ({type: EMPLOYEES_REMOVE_REQUIREMENTS_REQUEST});
export const employeesRemoveRequirementsSuccess = payload => ({
  type: EMPLOYEES_REMOVE_REQUIREMENTS_SUCCESS,
  payload,
});
export const employeesRemoveRequirementsFailure = payload => ({
  type: EMPLOYEES_REMOVE_REQUIREMENTS_FAILURE,
  payload,
});

export const EMPLOYEES_WORKLIST_GET = 'EMPLOYEES_WORKLIST_GET';
export const EMPLOYEES_WORKLIST_REQUEST = 'EMPLOYEES_WORKLIST_REQUEST';
export const EMPLOYEES_WORKLIST_SUCCESS = 'EMPLOYEES_WORKLIST_SUCCESS';
export const EMPLOYEES_WORKLIST_FAILURE = 'EMPLOYEES_WORKLIST_FAILURE';

export const employeesWorklistRequest = () => ({type: EMPLOYEES_WORKLIST_REQUEST});

export const employeesWorklistGet = payload => ({
  type: EMPLOYEES_WORKLIST_GET,
  payload,
});

export const employeesWorklistSuccess = payload => ({
  type: EMPLOYEES_WORKLIST_SUCCESS,
  payload,
});

export const employeesWorklistFailure = payload => ({
  type: EMPLOYEES_WORKLIST_FAILURE,
  payload,
});

export const EMPLOYEES_WORKLIST_REMOVE = 'EMPLOYEES_WORKLIST_REMOVE';
export const EMPLOYEES_WORKLIST_ADD = 'EMPLOYEES_WORKLIST_ADD';
export const EMPLOYEES_WORKLIST_CLEAR = 'EMPLOYEES_WORKLIST_CLEAR';

export const employeesWorklistAdd = payload => ({
  type: EMPLOYEES_WORKLIST_ADD,
  payload,
});

export const employeesWorklistRemove = payload => ({
  type: EMPLOYEES_WORKLIST_REMOVE,
  payload,
});

export const employeesWorklistClear = () => ({type: EMPLOYEES_WORKLIST_CLEAR});

export const EMPLOYEES_WORKLIST_SEND_MAIL = 'EMPLOYEES_WORKLIST_SEND_MAIL';
export const EMPLOYEES_WORKLIST_SEND_MAIL_REQUEST = 'EMPLOYEES_WORKLIST_SEND_MAIL_REQUEST';
export const EMPLOYEES_WORKLIST_SEND_MAIL_SUCCESS = 'EMPLOYEES_WORKLIST_SEND_MAIL_SUCCESS';
export const EMPLOYEES_WORKLIST_SEND_MAIL_FAILURE = 'EMPLOYEES_WORKLIST_SEND_MAIL_FAILURE';

export const employeesWorklistSendMail = payload => ({
  type: EMPLOYEES_WORKLIST_SEND_MAIL,
  payload,
});

export const employeesWorklistSendMailRequest = payload => ({
  type: EMPLOYEES_WORKLIST_SEND_MAIL_REQUEST,
  payload,
});

export const employeesWorklistSendMailSuccess = payload => ({
  type: EMPLOYEES_WORKLIST_SEND_MAIL_SUCCESS,
  payload,
});

export const employeesWorklistSendMailFailure = payload => ({
  type: EMPLOYEES_WORKLIST_SEND_MAIL_FAILURE,
  payload,
});

export const EMPLOYEES_WORKLIST_ADD_ROLES = 'EMPLOYEES_WORKLIST_ADD_ROLES';
export const EMPLOYEES_WORKLIST_ADD_ROLES_REQUEST = 'EMPLOYEES_WORKLIST_ADD_ROLES_REQUEST';
export const EMPLOYEES_WORKLIST_ADD_ROLES_SUCCESS = 'EMPLOYEES_WORKLIST_ADD_ROLES_SUCCESS';
export const EMPLOYEES_WORKLIST_ADD_ROLES_FAILURE = 'EMPLOYEES_WORKLIST_ADD_ROLES_FAILURE';

export const employeesWorklistAddRoles = payload => ({
  type: EMPLOYEES_WORKLIST_ADD_ROLES,
  payload,
});

export const employeesWorklistAddRolesRequest = payload => ({
  type: EMPLOYEES_WORKLIST_ADD_ROLES_REQUEST,
  payload,
});

export const employeesWorklistAddRolesSuccess = payload => ({
  type: EMPLOYEES_WORKLIST_ADD_ROLES_SUCCESS,
  payload,
});

export const employeesWorklistAddRolesFailure = payload => ({
  type: EMPLOYEES_WORKLIST_ADD_ROLES_FAILURE,
  payload,
});
