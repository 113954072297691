import React from 'react';
import {Field} from 'redux-form';
import FormDatePicker from '@components/form-datepicker';
import FormInput from '@components/form-input';
import {i18n, i18nLocaleDateStrToStdDateStr} from '@src/i18n';
import dayjs from 'dayjs';

const validateRequired = input => {
  if (!input || !input.trim()) {
    return i18n('globals.required-field');
  }

  return undefined;
};

const validateIsFourdigitYear = input => {
  if(!input) {
    return undefined;
  }

  const parsedInt = Number.parseInt(input);

  if (Number.isNaN(parsedInt) || (parsedInt < 1900 || parsedInt > 2200)) {
    return i18n('globals.invalid-year-field');
  }

  return undefined;
};

const validateAlsoHasStartyear = (input, allInputs) => {
  if(!input) {
    return undefined;
  }

  if(input && !allInputs.startyear) {
    return i18n('cv.no-endyear-without-startyear');
  }
};

const validateAlsoHasStartdate = (input, allInputs) => {
  if(!input) {
    return undefined;
  }

  if(input && !allInputs.startdate) {
    return i18n('cv.no-endyear-without-startdate');
  }
};

const validateStartdateIsBeforeEnddate = (enddateInput, allInputs) => {
  if(!enddateInput) {
    return undefined;
  }

  if(dayjs(enddateInput, 'DD.MM.YYYY').isBefore(dayjs(allInputs.startdate, 'DD.MM.YYYY'))) {
    return i18n('cv.enddate-is-before-startdate');
  }

  return undefined;
};

const validateStartyearIsBeforeEndyear = (endyearInput, allInputs) => {
  if(!endyearInput) {
    return undefined;
  }

  if(endyearInput < allInputs.startyear) {
    return i18n('cv.endyear-is-before-startyear');
  }

  return undefined;
};

export const ProjectFormElements = () => (
  <>
    <Field
      component={FormInput}
      autoFocus
      label={i18n('cv.form-elements.project-name')}
      name="designation"
      validate={[validateRequired]}
    />

    <Field
      component={FormInput}
      label={i18n('cv.form-elements.project-description')}
      name="name"
      validate={[validateRequired]}
    />

    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="startdate"
      label={i18n('cv.form-elements.project-from-date')}
      type="text"

    />

    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="enddate"
      label={i18n('cv.form-elements.project-to-date')}
      type="text"
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
    />

  </>
);

export const LanguageFormElements = () => (
  <>
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.language-name')}
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.language-skill')}
      name="designation"
      validate={[validateRequired]}
    />
  </>
);


export const WorkExperiencesFormElements = () => (
  <>
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.work-position')}
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.work-company')}
      name="name"
      validate={[validateRequired]}
    />


    <Field
      component={FormDatePicker}
      label={i18n('cv.form-elements.work-fromdate')}
      normalize={i18nLocaleDateStrToStdDateStr}
      name="startdate"
      type="text"
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
      name="enddate"
      type="text"
      label={i18n('cv.form-elements.work-todate')}
    />
  </>
);

export const HonoraryOrVolunteerElements = () => (
  <>
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.elected-role')}
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.elected-company')}
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      label={i18n('cv.form-elements.elected-fromdate')}
      name="startdate"
      type="text"
    />
    <Field
      component={FormDatePicker}
      normalize={i18nLocaleDateStrToStdDateStr}
      label={i18n('cv.form-elements.elected-todate')}
      name="enddate"
      validate={[validateAlsoHasStartdate, validateStartdateIsBeforeEnddate]}
      type="text"
    />
  </>
);

export const EducationFormElements = () => (
  <>
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.location')}
      name="designation"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      label={i18n('cv.form-elements.education-name')}
      name="name"
      validate={[validateRequired]}
    />
    <Field
      component={FormInput}
      type="number"
      label={i18n('cv.form-elements.education-start-year')}
      step="1"
      name="startyear"
      validate={[validateIsFourdigitYear]}
    />
    <Field
      component={FormInput}
      type="number"
      label={i18n('cv.form-elements.education-end-year')}
      step="1"
      name="endyear"
      validate={[validateIsFourdigitYear, validateAlsoHasStartyear, validateStartyearIsBeforeEndyear]}
    />
  </>
);
