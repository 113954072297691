import {darken} from 'polished';

const red = {
  articleCards: {subTitleColor: '#c25047'},
  coursegroups: {
    tiles: {
      active: {
        color: '#fff',
        backgroundColor: '#4E5BA6',
      },
      inactive: {
        color: '#ffffffd8',
        backgroundColor: '#3E4784',
      },
      hover: {
        color: '#fff',
        backgroundColor: '#3E4784',
      },
    },
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#B42318',
      },
      inactive: {
        color: '#2e2d2d',
        backgroundColor: '#f2e4e3',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.05, '#f2e4e3'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#8b5d5b',
      backgroundColor: '#ebebeb',
    },
    inactive: {
      color: '#5f5959',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#8b5d5b',
      background: 'transparent',
    },
  },
};

const blue = {
  articleCards: {subTitleColor: '#456ca5'},
  coursegroups: {
    tiles: {
      active: {
        color: '#fff',
        backgroundColor: '#4E5BA6',
      },
      inactive: {
        color: '#ffffff',
        backgroundColor: '#3E4784',
      },
      hover: {
        color: '#fff',
        backgroundColor: '#3E4784',
      },
    },
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#0A5CD5',
      },
      inactive: {
        color: '#0c0d13',
        backgroundColor: '#c7d7f0',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#c7d7f0'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#324b74',
      backgroundColor: '#ededed',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      background: 'transparent',
    },
  },
};

const green = {
  articleCards: {subTitleColor: '#2e7655'},
  coursegroups: {
    tiles: {
      active: {
        color: '#fff',
        backgroundColor: '#095C37',
      },
      inactive: {
        color: '#ffffffd8',
        backgroundColor: '#7C168F',
      },
      hover: {
        color: '#fff',
        backgroundColor: '#7C168F',
      },
    },
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#095C37',
      },
      inactive: {
        color: '#191927',
        backgroundColor: '#e1eae6',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#e1eae6'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#0d683f',
      backgroundColor: '#e8e8e8',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      background: 'transparent',
    },
  },
};

const orange = {
  articleCards: {subTitleColor: '#FF692E'},
  coursegroups: {
    tiles: {
      active: {
        color: '#fff',
        backgroundColor: '#FF692E',
      },
      inactive: {
        color: '#ffffffd8',
        backgroundColor: '#BC1B06',
      },
      hover: {
        color: '#fff',
        backgroundColor: '#771A0D',
      },
    },
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#FF4405',
      },
      inactive: {
        color: '#191927',
        backgroundColor: '#e1eae6',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#e1eae6'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#771A0D',
      backgroundColor: '#e8e8e8',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      background: 'transparent',
    },
  },
};


const defaultTheme = {
  articleCards: {subTitleColor: '#6B46C1'},
  coursegroups: {
    tiles: {
      active: {
        color: '#fff',
        backgroundColor: '#095C37',
      },
      inactive: {
        color: '#ffffffd8',
        backgroundColor: '#7C168F',
      },
      hover: {
        color: '#fff',
        backgroundColor: '#7C168F',
      },
    },
    buttons: {
      active: {
        color: '#fff',
        backgroundColor: '#4A1FB8',
      },
      inactive: {
        color: '#191927',
        backgroundColor: '#EBE9FE',
      },
      hover: {
        color: '#191927',
        backgroundColor: darken(.025, '#EBE9FE'),
      },
    },
  },
  toggleSwitch: {
    active: {
      color: '#7352c7',
      backgroundColor: '#ededed',
    },
    inactive: {
      color: '#667085',
      backgroundColor: 'transparent',
    },
    hover: {
      color: '#667085',
      background: 'transparent',
    },
  },
};

const gray = defaultTheme;
const snapperTheme = defaultTheme;

export const courseCatalogThemes = {
  blue,
  green,
  gray,
  orange,
  snapperTheme,
  defaultTheme,
  red,
};

export default courseCatalogThemes;
