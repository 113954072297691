import {i18n} from '@src/i18n';
import dayjs from 'dayjs';

import {faTachometerAltFast} from '@fortawesome/pro-light-svg-icons/faTachometerAltFast';
import {faRoute} from '@fortawesome/pro-light-svg-icons/faRoute';
import {faUserTag} from '@fortawesome/pro-light-svg-icons/faUserTag';
import {createSelector} from 'reselect';

export const getWorklistSelector = ({employees: {worklist}}) => worklist;

export const getEmployees = createSelector(
  ({employees: {list, checklists}}) => ({
    list,
    checklists,
  }),
  ({list, checklists}) => {
    if (!list.data) {
      return list;
    }

    return {
      ...list,
      data: list.data.map(employee => ({
        ...employee,
        pending_checklists:
        checklists.data
          && checklists.data.filter(cl => cl.person_id === employee.person_id).length
        || 0,
      })),
    };
  },
);

export const getFilteredEmployees = ({employees: {filteredList}}) => filteredList;

export const getReport = ({employees: {report}}) => report;

export const getSelectedPersonReport = ({employees: {selectedPerson: {report}}}) => report;

export const getSelectedPerson = createSelector(
  ({employees: {selectedPerson}}) => selectedPerson,
  selectedPerson => ({
    ...selectedPerson,
    data: selectedPerson.data && {
      ...selectedPerson.data,
      roles:
      Array.isArray(selectedPerson.data.roles)
      && selectedPerson.data.roles.map(role => ({
        ...role,
        taskdone:
          Array.isArray(role.competences)
            && role.competences.length
            && role.competences.reduce(
              (taskdone, {passed}) => taskdone + (passed === 100 && 1 || 0),
              0,
            )
          || 0,
        tasks: role.competences.length,
        progress:
          Array.isArray(role.competences)
            && role.competences.length
            && role.competences.reduce(
              (progress, {passed}) => progress + (passed || 0),
              0,
            )
              / role.competences.length
              / 100
          || 0,
        competences:
          Array.isArray(role.competences)
          && role.competences.map(competence => ({
            id: competence.id,
            passed: competence.passed,
            date: competence.date ? dayjs(competence.date) : null,
            title: competence.competence.title,
            competenceType: competence.competence.competence_type,
          })),
      })),
    },
  }),
);

export const getSelectedPersonUsername = ({employees: {selectedPerson: {data: {user_name: userName}}}}) => userName;
export const getSelectedPersonId = ({employees: {selectedPerson: {data: {id}}}}) => id;

export const getIsFetchingEmployees = ({
  employees: {
    list: {status},
    filteredList: {isFetching: isFetchingFiltered},
  },
}) => status || isFetchingFiltered;

export const getSearchPhraze = ({employees: {searchPhraze}}) =>
  searchPhraze;

export const getEmployeesStatistics = ({employees: {statistics}}) =>
  statistics;

export const getStatisticsKindsForView = createSelector(
  ({employees: {statistics}}) => statistics,
  statistics => {
    if(!statistics.data) {
      return [];
    }

    const stats = [
      {
        id: 'dashboard',
        data: {},
        icon: faTachometerAltFast,
        name: i18n('employees.report-search'),
        gotoName: '/dashboard/search',
      },
    ];

    stats.push({
      id: 'totalt',
      data: statistics.data.progress,
      name: i18n('employees.total'),
      gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.total')}}),
    });

    statistics.data.functions.forEach(item => {
      stats.push({
        id: item.role_id,
        data: item.progress,
        name: item.title,
        gotoName: i18n('globals.goto-x', {functionArgs: {x: item.title}}),
      });
    });

    stats.push(
      {
        id: 'role',
        data: {},
        name: i18n('employees.report-roles'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x:  i18n('employees.report-roles')}}),
        icon: faUserTag,
      },
      {
        id: 'competence',
        data: {},
        name: i18n('employees.report-competences'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x:  i18n('employees.report-competences')}}),
        icon: faRoute,
      },
    );

    return stats;
  },
);

export const getEmployeesActivities = ({employees: {activities}}) =>
  activities;

export const getEmployeesExpiredCompetences = ({employees: {expiring}}) =>
  expiring;

export const getEmployeesChecklists = ({employees: {checklists}}) =>
  checklists;

export const getIsFetchingFunctions = ({employees: {functions: {isFetching}}}) => isFetching;

export const getFunctions = createSelector(
  ({employees: {functions: {data}}}) => data,
  data => !!data && data.reduce(
    (acc, cur) => {
      const {rolemetatype} = cur;
      const types = ['position', 'role'];

      types.forEach(type => {
        if (rolemetatype === type) {
          acc[type].push(cur);
        }
      });

      return acc;
    },
    {
      position: [],
      role: [],
    },
  ),
);

export const getIsSaving = ({employees: {saving: {isSaving}}}) => isSaving;

export const getPersonEvents = ({employees: {selectedPerson: {data}}}) => data && data.events;

export const getEmployeesEvents = ({employees: {events: {data}}}) => data;

export const getEvents = ({employees: {events: {data}}}) => data;
export const getEventsWaitlist = ({employees: {eventsWaitlist: {data}}}) => data;

export const getSelectedOrganisation = ({employees: {organisation}}) => organisation;
export const getTree = ({employees: {tree}}) => tree;

export const getCampExtraData = ({employees: {extraData}}) => extraData;

export const getNormalizedEmployeesEvents = ({employees: {normalizedData: {events}}}) => events;

export const getNormalizedEmployees = ({employees: {normalizedData: {employees}}}) => employees;

