import * as coursesActions from '@actions/courses.actions';
import {i18n} from '@src/i18n';
import * as T from '../../store/types/load.types';

const initialState = {
  competences: {
    isFetching: false,
    isFetchingInitialCompetences: false,
    data: null,
    error: null,
    isExpanded: false,
    searchTerm: '',
  },
  initializeMyCoursesView: {isFetching: false},
  featuredCompetences: {
    isFetching: false,
    data: null,
    error: null,
  },
  courseEvents: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedCompetencesIds: [],
  sorting: ['alpha', i18n('globals.sort-alpha')],
  competencetypes: {
    isFetching: false,
    data: null,
    error: null,
  },
  currentSignature: null,
  competencegroups: {
    isFetching: false,
    data: null,
    error: null,
  },
  courseCatalogNews: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedCompetencegroupId: undefined,
  selectedSubcompetencegroupId: null,
  selectedSubSubcompetencegroupId: null,
  filters: {
    selectedCompetencetypes: [],
    catalogView: {tab: 'competences'},
    courseKind: {tab: 'digital_courses'},
  },
  competenceDetails: {
    isFetching: false,
    data: null,
    error: null,
  },
  activeCourse: null,
  courseSignOn: {
    isFetching: false,
    ceid: null,
    error: null,
    results: {
      errors: [],
      correct: [],
    },
  },
  courseSignOff: {
    isFetching: false,
    status: T.LoadStatuses.FAILED,
  },
  courseSignCourse: {
    isFetching: false,
    ceid: null,
    error: null,
    success: false,
    status: '',
  },
  courseDeleteFileForPerson: {
    isFetching: false,
    error: null,
    success: false,
    status: false,
  },
  normalizedData: {
    competencegroups: {
      ids : [],
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competences: {
      ids: [],
      data: {},
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceIdsByGroupId: {
      data: {},
      status: T.LoadStatuses.NOT_LOADED,
    },
    subCategoriesByGroupId: {
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selectedCompetencegroups: {
      ids: null,
      data: {},
      status: T.LoadStatuses.NOT_LOADED,
    },
    courseEvents: {
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      allEventIds: [],
      eventById: {},
      eventIdsByCourseId: {},
      eventIdsByYearMonth: {},
    },
    eventParticipantsByEventId: {
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selfSignedOnEventIds: {
      eventIds: [],
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceDetails: {
      ids: [],
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competencesSearchResults: {
      searchTerm: '',
      ids: [],
      data: {},
      empty: true,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW_REQUEST:
    return {
      ...state,
      initializeMyCoursesView: {
        ...state.initializeMyCoursesView,
        isFetching: true,
      },
      competences: {
        isFetching: true,
        searchTerm: state.competences.searchTerm,
      },
      competencegroups: {isFetching: true},
      competencetypes: {isFetching: true},
    };
  case coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW_SUCCESS:
    return {
      ...state,
      initializeMyCoursesView: {
        ...state.initializeMyCoursesView,
        isFetching: false,
      },
    };
  case coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW_FAILURE:
    return {
      ...state,
      initializeMyCoursesView: {
        ...state.initializeMyCoursesView,
        isFetching: false,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCES_REQUEST:
    return {
      ...state,
      competences: {
        ...initialState.competences,
        isFetching: true,
        searchTerm: state.competences.searchTerm,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCES_SUCCESS:
    return {
      ...state,
      competences: {
        ...initialState.competences,
        data: action.payload.competences,
        isExpanded: false,
        searchTerm: state.competences.searchTerm,
      },
    };
  case coursesActions.COURSES_UPDATE_SEARCH_RESULTS_REQUEST:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competencesSearchResults: {
          ...initialState.normalizedData.competencesSearchResults,
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case coursesActions.COURSES_UPDATE_SEARCH_RESULTS_SUCCESS:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competencesSearchResults: {
          ...initialState.normalizedData.competencesSearchResults,
          status: T.LoadStatuses.LOADED,
          ...action.payload.reset
            ? {}
            : {
              searchTerm: action.payload.searchTerm,
              empty: action.payload.competences.length === 0,
              ...action.payload.competences?.reduce?.((acc, c) => {
                acc.data[c.id] = c;
                acc.ids.push(c.id);

                return acc;
              }, {
                ids: [],
                data: {},
              }) || {},
            },
        },
      },
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUPS_LIST_REQUEST:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competencegroups: {
          ...state.normalizedData.competencegroups,
          isFetching: true,
          status: T.LoadStatuses.IS_LOADING,
        },
        subCategoriesByGroupId: {
          ...state.normalizedData.subCategoriesByGroupId,
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUPS_LIST_SUCCESS:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competencegroups: {
          ...state.normalizedData.competencegroups,
          data: action.payload.data,
          ids: action.payload.ids,
          isFetching: false,
          isLoaded: true,
          status: T.LoadStatuses.LOADED,
        },
        subCategoriesByGroupId: {
          ...state.normalizedData.subCategoriesByGroupId,
          data: action.payload.subCategoriesByGroupId,
          status: T.LoadStatuses.LOADED,
        },
      },
    };
  case coursesActions.COURSES_LOAD_COMPETENCEGROUP_REQUEST:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        selectedCompetencegroups: {
          ...state.normalizedData.selectedCompetencegroups,
          data: null,
          ids: [action.payload.competencegroupId],
          subCategories: [],
          competences: [],
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case coursesActions.COURSES_LOAD_COMPETENCEGROUP_SUCCESS:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        selectedCompetencegroups: {
          ...state.normalizedData.selectedCompetencegroups,
          ids: [action.payload.competencegroupId],
          data: state.normalizedData.competencegroups.data[action.payload.competencegroupId],
          subCategories: state.normalizedData.competencegroups.data[action.payload.competencegroupId]?.children,
          competences: state.normalizedData.competenceIdsByGroupId.data[action.payload.competencegroupId]
            ?.map(id => state.normalizedData.competences.data[id]) || [],
          isFetching: false,
          status: T.LoadStatuses.LOADED,
        },
      },
    };
  case coursesActions.COURSES_LOAD_COMPETENCEGROUPS_SUBCATEGORIES_REQUEST:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        selectedCompetencegroups: {
          ...state.normalizedData.selectedCompetencegroups,
          ids: [...action.payload.parentGroupIds, ...action.payload.subCategoryIds],
          competences: [],
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case coursesActions.COURSES_LOAD_COMPETENCEGROUPS_SUBCATEGORIES_SUCCESS:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        selectedCompetencegroups: {
          ...state.normalizedData.selectedCompetencegroups,
          ids: [
            ...action.payload.parentGroupIds,
            ...action.payload.subCategoryIds,
          ],
          competences: [...new Set([
            ...action.payload.subCategoryIds.length
              ? action.payload.subCategoryIds
              : action.payload.parentGroupIds,
          ]
            .flatMap(groupId => {
              const competenceIds = state.normalizedData
                .competenceIdsByGroupId.data[groupId];

              return competenceIds;
            }))]
            .map(competenceId => {
              const competence = state.normalizedData
                .competences.data[competenceId];

              return competence;
            })
            .filter(Boolean) || [],
          status: T.LoadStatuses.LOADED,
        },
      },
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUP_CHILDREN_REQUEST:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competenceIdsByGroupId: {
          ...state.normalizedData.competenceIdsByGroupId,
          status: T.LoadStatuses.IS_LOADING,
        },
        competences: {
          ...state.normalizedData.competences,
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUP_CHILDREN_SUCCESS:
    return {
      ...state,
      normalizedData: {
        ...state.normalizedData,
        competenceIdsByGroupId: {
          ...state.normalizedData.competenceIdsByGroupId,
          data: {
            ...state.normalizedData.competenceIdsByGroupId.data,
            [action.payload.competencegroupId]: action.payload.ids,
          },
          error: null,
          status: T.LoadStatuses.LOADED,
        },
        competences: {
          ...state.normalizedData.competences,
          ids: [...new Set([
            ...state.normalizedData.competences.ids,
            ...action.payload.ids,
          ])],
          data: {
            ...state.normalizedData.competences.data,
            ...action.payload.data,
          },
          error: null,
          status: T.LoadStatuses.LOADED,
        },
      },
    };
  case coursesActions.COURSES_SET_SEARCHTERM:
    return {
      ...state,
      competences: {
        ...state.competences,
        searchTerm: action.payload.searchTerm,
      },
    };
  case coursesActions.COURSES_SET_EXPAND:
    return {
      ...state,
      competences: {
        ...state.competences,
        isExpanded: true,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCES_FAILURE:
    return {
      ...state,
      competences: {
        ...initialState.competences,
        error: action.payload.error,
        searchTerm: state.competences.searchTerm,
      },
    };
  case coursesActions.COURSES_GET_COURSEEVENTS_REQUEST:
    return {
      ...state,
      courseEvents: {
        ...initialState.courseEvents,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case coursesActions.COURSES_GET_COURSEEVENTS_SUCCESS:
    return {
      ...state,
      courseEvents: {
        ...initialState.courseEvents,
        data: action.payload.courseEvents,
        status: T.LoadStatuses.LOADED,
      },
    };
  case coursesActions.COURSES_GET_COURSEEVENTS_FAILURE:
    return {
      ...state,
      courseEvents: {
        ...initialState.courseEvents,
        error: action.payload.error,
        status: T.LoadStatuses.FAILED,
      },
    };
  case coursesActions.COURSES_UPDATE_EVENTS:
    return {
      ...state,
      courseEvents: {
        ...initialState.courseEvents,
        data: action.payload.courseEvents,
        status: action.payload.partial
          ? state.courseEvents.status !== T.LoadStatuses.LOADED
            ? T.LoadStatuses.LOADED_PARTIAL
            : T.LoadStatuses.LOADED
          : T.LoadStatuses.LOADED,
      },
      normalizedData: {
        ...state.normalizedData,
        courseEvents: {
          ...state.normalizedData.courseEvents,
          allEventIds: [
            ...new Set([
              ...state.normalizedData.courseEvents.allEventIds,
              ...action.payload.ids,
            ]),
          ],
          eventById: {
            ...state.normalizedData.courseEvents.eventById,
            ...action.payload.eventById,
          },
          eventIdsByCourseId: {
            ...state.normalizedData.courseEvents.eventIdsByCourseId,
            ...action.payload.eventIdsByCourseId,
          },
          eventIdsByYearMonth: {
            ...state.normalizedData.courseEvents.eventIdsByYearMonth,
            ...action.payload.eventIdsByYearMonth,
          },
          status: action.payload.partial
            ? state.courseEvents.status !== T.LoadStatuses.LOADED
              ? T.LoadStatuses.LOADED_PARTIAL
              : T.LoadStatuses.LOADED
            : T.LoadStatuses.LOADED,
        },
      },
    };
  case coursesActions.COURSES_BEGIN_SIGNATURE:
    return {
      ...state,
      currentSignature: action.payload,
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUPS_REQUEST:
    return {
      ...state,
      competencegroups: {
        ...initialState.competencegroups,
        isFetching: true,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUPS_SUCCESS:
    return {
      ...state,
      competencegroups: {
        ...initialState.competencegroups,
        data: action.payload.competencegroups,
      },
      selectedCompetencegroupId:
          state.selectedCompetencegroupId === undefined
            && action.payload.competencegroups.length
            && (action.payload.defaultSelectedCompetenceGroup
              || action.payload.competencegroups[0].id)
          || state.selectedCompetencegroupId,
    };
  case coursesActions.COURSES_GET_COMPETENCEGROUPS_FAILURE:
    return {
      ...state,
      competencegroups: {
        ...initialState.competencegroups,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP:
    return {
      ...state,
      selectedCompetencegroupId: action.payload.competencegroupId,
      selectedSubcompetencegroupId: null,
      selectedSubSubcompetencegroupId: null,
    };
  case coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP:
    return {
      ...state,
      selectedSubcompetencegroupId:
          state.selectedSubcompetencegroupId
            !== action.payload.subcompetencegroupId
            && action.payload.subcompetencegroupId
          || null,
      selectedSubSubcompetencegroupId: null,
    };
  case coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP:
    return {
      ...state,
      selectedSubSubcompetencegroupId: action.payload.subSubcompetencegroupId,
    };
  case coursesActions.COURSES_FILTERS_SET_FILTERS:
    return {
      ...state,
      filters: {
        ...state.filters,
        ...Object.keys(action.payload).reduce(
          (filters, key) => ({
            ...filters,
            [key]: {
              ...state.filters[key] || {},
              ...action.payload[key],
            },
          }),
          {},
        ),
      },
    };
  case coursesActions.COURSES_GET_COMPETENCETYPES_REQUEST:
    return {
      ...state,
      competencetypes: {
        ...initialState.competencetypes,
        isFetching: true,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCETYPES_SUCCESS:
    return {
      ...state,
      competencetypes: {
        ...initialState.competencetypes,
        data: action.payload.competencetypes,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCETYPES_FAILURE:
    return {
      ...state,
      competencetypes: {
        ...initialState.competencetypes,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSE_CATALOG_NEWS_REQUEST:
    return {
      ...state,
      courseCatalogNews: {
        ...initialState.courseCatalogNews,
        isFetching: true,
      },
    };
  case coursesActions.COURSE_CATALOG_NEWS_SUCCESS:
    return {
      ...state,
      courseCatalogNews: {
        ...initialState.courseCatalogNews,
        data: action.payload.data,
      },
    };
  case coursesActions.COURSE_CATALOG_NEWS_FAILURE:
    return {
      ...state,
      courseCatalogNews: {
        ...initialState.courseCatalogNews,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSES_FILTERS_SET_COMPETENCETYPES_NO_REFETCH:
    return {
      ...state,
      filters: {
        ...state.filters,
        selectedCompetencetypes: action.payload.competenceTypes,
      },
    };
  case coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE:
    return {
      ...state,
      selectedCompetencetypes: ((set, toggleElement) => {
        set[set.has(toggleElement) ? 'delete' : 'add'](toggleElement);

        return set;
      })(
        new Set(state.selectedCompetencetypes),
        action.payload.competencetypeId,
      ),
    };
  case coursesActions.COURSES_GET_COMPETENCE_DETAILS_REQUEST:
    return {
      ...state,
      competenceDetails: {
        ...initialState.competenceDetails,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  case coursesActions.COURSES_GET_COMPETENCE_DETAILS_SUCCESS:
    return {
      ...state,
      competenceDetails: {
        ...initialState.competenceDetails,
        isFetching: false,
        data: action.payload.competenceDetails,
        error: null,
      },
      normalizedData: {
        ...state.normalizedData,
        competenceDetails: {
          ...state.normalizedData.competenceDetails,
          ids: [
            ...state.normalizedData.competenceDetails.ids,
            action.payload.cid,
          ],
          data: {
            ...state.normalizedData.competenceDetails.data,
            [action.payload.cid]: action.payload.competenceDetails,
          },
        },
      },
    };
  case coursesActions.COURSES_GET_COMPETENCE_DETAILS_FAILURE:
    return {
      ...state,
      competenceDetails: {
        ...initialState.competenceDetails,
        isFetching: false,
        data: null,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSES_START_COURSE:
    return {
      ...state,
      activeCourse: {
        ...state.activeCourse,
        type: action.payload.type,
        cid: action.payload.cid,
        status: 0,
      },
    };
  case coursesActions.COURSES_RUN_COURSE:
    return {
      ...state,
      activeCourse: {
        ...state.activeCourse,
        type: state.activeCourse.type,
        cid: state.activeCourse.cid,
        url: action.payload.url,
        opened: action.payload.opened,
        status: 1,
      },
    };
  case coursesActions.COURSES_RUN_NANO_COURSE:
    return {
      ...state,
      activeCourse: {
        ...state.activeCourse,
        type: action.payload.type || state.activeCourse.type,
        cid: action.payload.cid || state.activeCourse.cid,
        url: action.payload.url,
        status: 1,
      },
    };
  case coursesActions.COURSES_SET_ACTIVE_COURSE:
    return {
      ...state,
      activeCourse: {
        ...action.payload,
        status: 0,
      },
    };
  case coursesActions.COURSES_CLOSE_COURSE:
    return {
      ...state,
      activeCourse: null,
    };
  case coursesActions.COURSE_SIGNOFF_REQUEST:
    return {
      ...state,
      courseSignOff: {
        ...state.courseSignOff,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case coursesActions.COURSE_SIGNOFF_SUCCESS:
    return {
      ...state,
      courseSignOff: {
        ...state.courseSignOff,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
      },
    };
  case coursesActions.COURSE_SIGNOFF_FAILURE:
    return {
      ...state,
      courseSignOff: {
        ...initialState.courseSignOff,
        isFetching: false,
        status: T.LoadStatuses.FAILED,
      },
    };
  case coursesActions.COURSE_SIGNON_REQUEST:
    return {
      ...state,
      courseSignOn: {
        ...initialState.courseSignOn,
        isFetching: true,
        ceid: action.payload.ceid,
        results: null,
      },
    };
  case coursesActions.COURSE_SIGNON_SUCCESS:
    return {
      ...state,
      courseSignOn: {
        ...state.courseSignOn,
        isFetching: false,
        status: true,
      },
    };
  case coursesActions.COURSE_SIGNON_FAILURE:
    return {
      ...state,
      courseSignOn: {
        ...state.courseSignOn,
        isFetching: false,
        ceid: state.courseSignOn.ceid,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSE_SIGNON_RESULTS:
    return {
      ...state,
      courseSignOn: {
        ...state.courseSignOn,
        results: action.payload.results,
        isFetching: false,
      },
    };
  case coursesActions.COURSES_SIGN_COURSE:
    return {
      ...state,
      courseSignCourse: {
        ...initialState.courseSignCourse,
        isFetching: true,
        ceid: action.payload.courseId,
      },
    };
  case coursesActions.COURSES_SIGN_COURSE_SUCCESS:
    return {
      ...state,
      courseSignCourse: {
        isFetching: false,
        success: true,
        status: action.payload.status,
      },
    };
  case coursesActions.COURSES_SIGN_COURSE_ERROR:
    return {
      ...state,
      courseSignCourse: {
        isFetching: false,
        error: action.payload.error,
      },
    };
  case coursesActions.COURSES_SET_SORTING:
    return {
      ...state,
      sorting: action.payload,
    };
  case coursesActions.COURSES_GET_FEATURED_COMPETENCES_REQUEST:
    return {
      ...state,
      featuredCompetences: {
        ...initialState.featuredCompetences,
        isFetching: true,
      },
    };
  case coursesActions.COURSES_GET_FEATURED_COMPETENCES_SUCCESS:
    return {
      ...state,
      featuredCompetences: {
        ...initialState.featuredCompetences,
        data: action.payload.featuredCompetences,
      },
    };

  case coursesActions.COURSES_GET_FEATURED_COMPETENCES_FAILURE:
    return {
      ...state,
      featuredCompetences: {...initialState.featuredCompetences},
    };

  case coursesActions.COURSE_DELETE_FILE_FOR_PERSON_REQUEST:
    return {
      ...state,
      courseDeleteFileForPerson: {
        ...initialState.courseDeleteFileForPerson,
        isFetching: true,
      },
    };
  case coursesActions.COURSE_DELETE_FILE_FOR_PERSON_SUCCESS:
    return {
      ...state,
      courseDeleteFileForPerson: {
        ...state.courseDeleteFileForPerson,
        isFetching: false,
        status: true,
      },
    };
  case coursesActions.COURSE_DELETE_FILE_FOR_PERSON_FAILURE:
    return {
      ...state,
      courseDeleteFileForPerson: {
        ...state.courseDeleteFileForPerson,
        isFetching: false,
        error: action.payload.error,
        status: false,
      },
    };
  case coursesActions.COURSE_DELETE_FILE_FOR_PERSON_RESULTS:
    return {
      ...state,
      courseDeleteFileForPerson: {
        ...state.courseDeleteFileForPerson,
        isFetching: false,
      },
    };
  default:
    return state;
  }
};

export default profile;
