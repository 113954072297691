import {useCallback, useState} from 'react';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/misc.utils';
import {getConfigObject} from '@selectors/config.selectors';
import {useSelector} from 'react-redux';

const localStorageLang = localStorage.getItem('language');

const getCurrentLanguage = configObj => localStorageLang
  || configObj.getProperty('params.default-lang')
  || getTwoletterLangCodeFromNavigatorLanguage()
  || 'no';

export const useCurrentLanguage = () => {
  const configObject = useSelector(getConfigObject);
  const [currLanguage, setCurrLanguage] = useState(() => getCurrentLanguage(configObject));

  const changeLanguage = useCallback(langcode => {
    localStorage.setItem('language', langcode);
    setCurrLanguage(langcode);
  }, []);

  return [currLanguage, changeLanguage];
};
