import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import './form-input.scss';
import {
  StyledInput,
  InputWrapper,
  CheckInputWrapper,
  StyledCheckInput,
  StyledRadioDot,
  RadioInputWrapper,
  StyledLabel,
  StyledErrorMsg,
  StyledHelpText,
  StyledRadioInput,
  CustomStyledRadio,
} from './form-input-styles';

const FormInput = ({className, input, type = 'text', label, verticalSpacing, horizontalSpacing, meta, helpText, ...props}) => {
  const InputComponent = {
    text: StyledInput,
    radio:StyledRadioInput,
    checkbox:StyledCheckInput,
  }[type] || StyledInput;

  const WrapperComponent = {
    text: InputWrapper,
    radio: RadioInputWrapper,
    checkbox: CheckInputWrapper,
  }[type] || InputWrapper;

  const inputRef = useRef();
  const inputId = type === 'radio' ? `${input.name}_${input.value}` : input.name;

  return (
    <WrapperComponent
      key={inputId}
      verticalSpacing={verticalSpacing}
      horizontalSpacing={horizontalSpacing}
    >
      {label && (
        <StyledLabel
          htmlFor={inputId}
          disabled={props.disabled}
        >
          {label}
        </StyledLabel>
      )}
      <InputComponent
        ref={inputRef}
        empty={!input.value || input.value.length === 0}
        error={meta.touched && meta.error}
        type={type}
        id={inputId}

        data-label={label}
        value={label}
        autoComplete="off"
        tabIndex={type === 'radio' ? '-1' : '0'}
        {...props}
        {...input}
      />

      {type === 'radio'
        && (
          <CustomStyledRadio
            tabIndex= {props.disabled ? '-1' :  '0'}
            disabled={props.disabled}
            aria-hidden="true"
            onClick={() => inputRef.current.click()}
            onKeyUp={evt => {
              if(evt.key === 'Enter' || evt.key === 'Space') {
                inputRef.current.click();
              }
            }}
            checked={input.checked}
          >
            <StyledRadioDot disabled={props.disabled}/>
          </CustomStyledRadio>
        )}
      {meta.touched && meta.error
      &&       (
        <StyledErrorMsg role="alert">
          {meta.error}
        </StyledErrorMsg>
      )}

      {helpText
      && <StyledHelpText disabled={props.disabled}>{helpText}</StyledHelpText>}
    </WrapperComponent>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormInput.defaultProps = {
  className: '',
  label: '',
};

export default FormInput;
