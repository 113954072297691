/* eslint-disable camelcase */
/* eslint-disable no-console */
import axios from 'axios';
import dayjs from 'dayjs';
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import {authUnauthorized} from '@actions/auth.actions';
import {
  EMPLOYEE_MOVE,
  employeeMoveFailure,
  employeeMoveRequest,
  employeeMoveSuccess,
  EMPLOYEES_ADD_PERSON,
  EMPLOYEES_EDIT_PASSWORD,
  EMPLOYEES_EDIT_SELECTED_PERSON,
  EMPLOYEES_EDIT_VIEW_INIT,
  EMPLOYEES_FETCH_ACTIVITIES,
  EMPLOYEES_FETCH_CHECKLISTS,
  EMPLOYEES_FETCH_EVENTS,
  EMPLOYEES_FETCH_EVENTS_WAITLIST,
  EMPLOYEES_FETCH_EXPIRING_COMPETENCES,
  EMPLOYEES_FETCH_EXTRADATA,
  EMPLOYEES_FETCH_FUNCTIONS,
  EMPLOYEES_FETCH_SELECTED_PERSON,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES,
  EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS,
  EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT,
  EMPLOYEES_FETCH_STATISTICS,
  EMPLOYEES_GET,
  EMPLOYEES_REMOVE_REQUIREMENTS,
  EMPLOYEES_REPORT_VIEW_INIT,
  EMPLOYEES_RESET_PASSWORD,
  EMPLOYEES_SAVE_REQUIREMENTS,
  EMPLOYEES_SAVE_ROLE,
  EMPLOYEES_SAVE_VERIFICATION,
  EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT,
  EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES,
  EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS,
  employeesAddPersonPostFailure,
  employeesAddPersonPostRequest,
  employeesAddPersonPostSuccess,
  employeesApproveRejectSelfSign,
  employeesApproveRejectSelfSignFailure,   employeesApproveRejectSelfSignRequest,
  employeesApproveRejectSelfSignSuccess,   employeesEditPasswordFailure,
  employeesEditPasswordRequest,
  employeesEditPasswordSuccess,
  employeesEditSelectedPersonPutFailure,
  employeesEditSelectedPersonPutRequest,
  employeesEditSelectedPersonPutSuccess,
  employeesFetchActivitiesGetFailure,
  employeesFetchActivitiesGetRequest,
  employeesFetchActivitiesGetSuccess,
  employeesFetchChecklists,
  employeesFetchChecklistsGetFailure,
  employeesFetchChecklistsGetRequest,
  employeesFetchChecklistsGetSuccess,
  employeesFetchEventsFailure,
  employeesFetchEventsRequest,
  employeesFetchEventsSuccess,
  employeesFetchEventsWaitlistFailure,
  employeesFetchEventsWaitlistRequest,
  employeesFetchEventsWaitlistSuccess,
  employeesFetchExpiringCompetencesFailure,
  employeesFetchExpiringCompetencesSuccess,
  employeesFetchExtraDataFailure,
  employeesFetchExtraDataRequest,
  employeesFetchExtraDataSuccess,
  employeesFetchFunctionsFailure,
  employeesFetchFunctionsRequest,
  employeesFetchFunctionsSuccess,
  employeesFetchOrganisationFailure,
  employeesFetchOrganisationRequest,
  employeesFetchOrganisationSuccess,
  employeesFetchSelectedPerson,
  employeesFetchSelectedPersonChecklistsFailure,
  employeesFetchSelectedPersonChecklistsRequest,
  employeesFetchSelectedPersonChecklistsSuccess,
  employeesFetchSelectedPersonCompetencesFailure,
  employeesFetchSelectedPersonCompetencesRequest,
  employeesFetchSelectedPersonCompetencesSuccess,
  employeesFetchSelectedPersonEventsFailure,
  employeesFetchSelectedPersonEventsRequest,
  employeesFetchSelectedPersonEventsSuccess,
  employeesFetchSelectedPersonExpiring,
  employeesFetchSelectedPersonFailure,
  employeesFetchSelectedPersonRequest,
  employeesFetchSelectedPersonSuccess,
  employeesFetchStatisticsGetFailure,
  employeesFetchStatisticsGetRequest,
  employeesFetchStatisticsGetSuccess,
  employeesGet,
  employeesRemoveRequirementsRequest,
  employeesReportFailure,
  employeesReportRequest,
  employeesReportSuccess,
  employeesResetPasswordFailure,
  employeesResetPasswordRequest,
  employeesResetPasswordSuccess,
  employeesSaveVerificationFailure,
  employeesSaveVerificationRequest,
  employeesSaveVerificationSuccess,
  employeesSearchPhrazeChange,
  employeesUpdateSelectedPersonEmploymentFailure,
  employeesUpdateSelectedPersonEmploymentRequest,
  employeesUpdateSelectedPersonEmploymentSuccess,
  employeesUpdateSelectedPersonRolesPostFailure,
  employeesUpdateSelectedPersonRolesPostRequest,
  employeesUpdateSelectedPersonRolesPostSuccess,
} from '@actions/employees.actions';
import * as EA from '@actions/employees.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {
  PROFILE_FETCH_PERSON_SUCCESS,
  profileFetchPerson,
  profileFetchPersonRequest,
} from '@actions/profile.actions';
import {routerEmployeesListDidMount} from '@actions/router.actions';
import {backendUrl, backendUrlV2} from '@config';
import reports from '@routes/reports.manifest';
import {
  getSelectedPerson,
  getSelectedPersonUsername,
  getWorklistSelector,
} from '@selectors/employees.selectors';
import {getOrganisationId, getProfile, getProfileId} from '@selectors/profile.selectors';
import {parseEvent} from '@src/hooks/store/course/util';
import {i18n} from '@src/i18n';
import {CourseType} from '@types/competence';
import {stringifyUrlParams} from '@utils/requests.utils';
import retry from '@utils/sagas.utils';
import * as T from '../../store/types/load.types';
import {fetchAllOrganisations} from './profile.sagas';


function* fetchEmployees(action) {
  try {
    const profile = yield select(getProfile);
    const limit = 100;

    yield put(EA.employeesGetRequest());

    const organisationId = action.payload?.orgId ? action.payload.orgId : yield select(getOrganisationId);
    let hasChildren = false;

    if (organisationId) {
      const organisationResponse = yield retry(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/api/organisations/${organisationId}`,
          params: {
            fields:
              'has_children',
            limit,
          },
          withCredentials: true,
        }));

      hasChildren = organisationResponse.data.organisations[0].has_children;
    }

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    const employeesResponse = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrlV2}/organisations/${organisationId}/persons`,
        params: {
          fields:
            'user_name,progress,email,phone,mobile,fullname,firstname,lastname,positions(role_id,title,organisation_id),profile_image,tasks,is_absent,absent_from,absent_to',
          limit,
          absent: '1',
        },
        withCredentials: true,
      }));

    let employees = employeesResponse.data;
    let hasMore = false;

    if (employees.length >= limit) {
      hasMore = true;
      employees = employees.slice(0, limit - 1);
    }

    yield put(EA.employeesGetSuccess({
      employees: employees?.map?.(employee => ({
        ...employee,
        fullname: employee.fullname || `${employee.firstname} ${employee.lastname}`,
      })),
      hasChildren,
      hasMore,
    }));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }

    yield put(EA.employeesGetFailure({error}));
  }
}

function* searchEmployees(action) {
  const {searchPhraze: query} = action.payload;
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }

  if (query.length > 3) {
    yield put(EA.employeesSearchRequest());

    try {
      const {persons} = yield call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/search?items=persons&mode=partial&organisation_ids=${organisationId}`,
            params: {
              query,
              fields:
                'user_name,email,phone,mobile,fullname,firstname,lastname,profile_image,positions(role_id,title,organisation_id)',
            },
            withCredentials: true,
          })
          .then(({data}) => data));

      yield put(EA.employeesSearchSuccess({employees: persons}));
    } catch (error) {
      console.error(error);
      if (error.status === 401) {
        yield put(authUnauthorized({error}));
      }
      yield put(EA.employeesSearchFailure({error}));
    }
  }
}

function* fetchSelectedPersonReport(payload) {
  const {userName} = payload.payload;

  yield put(EA.employeesFetchSelectedPersonReportRequest());

  try {
    const {data: {persons}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/persons/${userName}`,
        params: {fields: 'id'},
        withCredentials: true,
      }));
    const personId = persons[0].id;
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/reports/personfullcompetencereportjson/${personId}`,
        params: {
          fields:
            'id,passed,date,certificate_url,competence_title,competence(competence_type,files)',
        },
        withCredentials: true,
      }));

    yield put(EA.employeesFetchSelectedPersonReportSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(EA.employeesFetchSelectedPersonReportFailure());
  }
}

function* fetchSelectedPersonActivites(payload) {
  yield put(EA.employeesFetchSelectedPersonActivitiesRequest());
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: payload.payload.userName,
          fields:
            'id,passed,date,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'missing',
        },
        withCredentials: true,
      }));
    const selectedPerson = yield select(getSelectedPerson);

    for (const competence of competences) {
      competence.children = [];
    }
    selectedPerson.data.summary.activities.children = competences;
    selectedPerson.data.summary.activities.isFetching = false;
    const new_data = selectedPerson.data;

    yield put(EA.employeesFetchSelectedPersonActivitiesSuccess({data: new_data}));
  } catch (error) {
    console.error(error);
    yield put(EA.employeesFetchSelectedPersonActivitiesFailure());
  }
}

function* fetchSelectedPersonExpiring(payload) {
  yield put(EA.employeesFetchSelectedPersonExpiringRequest());
  const userName = payload ? payload.payload.user_name : null;

  try {
    const {data: {personcompetences: data}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,valid_until,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expiring',
        },
        withCredentials: true,
      }));

    yield put(EA.employeesFetchSelectedPersonExpiringSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(EA.employeesFetchSelectedPersonExpiringFailure());
  }
}

/*
function* fetchPersonRoles({ userName }) {
  try {
    const {
      data: { roles },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles/`,
        params: {
          user_name: userName,
          summary: 1,
          role_meta_types: 'position,role',
          fields: 'title,id',
        },
        withCredentials: true,
      })
    );

    return yield all(
      roles.map(role => call(fetchPersonRoleCompetences, { role, userName }))
    );
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    return new Error(error);
  }
}
*/

function* fetchSelectedReport(action) {
  const {reportId, orgId} = action.payload;

  try {
    yield put(employeesReportRequest({reportId}));
    if (reportId === 'role') {
      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/organisations/statistics_json/${orgId}/person-role`,
          withCredentials: true,
        }));

      yield put(employeesReportSuccess({
        data: {
          ...data.data,
          reportId,
        },
      }));
    }else if (reportId === 'competence') {
      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/organisations/statistics_json/${orgId}/competence`,
          withCredentials: true,
        }));

      yield put(employeesReportSuccess({
        data: {
          ...data.data,
          reportId,
        },
      }));
    }else {
      let cti = [];
      let title = '';

      if (reportId in reports) {
        // eslint-disable-next-line prefer-destructuring
        cti = reports[reportId].cti;
        title = reports[reportId].name;
      } else {
        const req_data = yield call(() =>
          axios.request({
            method: 'GET',
            url: `${backendUrl}/api/organisationalfunctions`,
            params: {
              organisation_id: orgId,
              fields: 'title,required_competences(competence_id)',
            },
            withCredentials: true,
          }));
        const id_data = req_data.data.roles.find(r => r.id === Number.parseInt(reportId, 0));

        // eslint-disable-next-line prefer-destructuring
        title = id_data.title;

        for (let i = 0; i < id_data.required_competences.length; i += 1) {
          cti.push(id_data.required_competences[i].id);
        }
        cti = cti.join(',');
      }

      const data = yield call(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/reports/coursematrixjson`,
          params: {
            organisation_id: orgId,
            competence_ids: cti,
          },
          withCredentials: true,
        }));

      data.data.reportTitle = title;
      data.data.reportCids = cti;
      yield put(employeesReportSuccess({
        data: {
          ...data.data,
          reportId,
        },
      }));
    }
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized());
    }

    yield put(employeesReportFailure({error}));
  }
}

function* setWorklist(payload) {
  try {
    const {pids, persons, clear} = payload || {};
    const ids = pids ? pids : persons?.map(p => p.person_id);

    if (clear || !ids.length) {
      sessionStorage.removeItem('working-pids');
    } else {
      sessionStorage.setItem('working-pids', JSON.stringify(ids));
    }
    yield put(EA.employeesWorklistSuccess({
      persons: persons || [],
      ids: ids || [],
    }));
  } catch (error) {
    console.error(error);
  }
}

function* clearWorklist() {
  try {
    yield call(setWorklist, {clear: true});
    yield put(notificationsAdd({
      notification: {
        color: 'green',
        text: i18n('employees.worklist-cleared'),
      },
    }));
  } catch (error) {
    console.error(error);
  }
}

function* fetchWorklist(pids = []) {
  yield put(EA.employeesWorklistRequest());
  try {
    const {data: {persons}} = yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/search/getpersonlist_json`,
        withCredentials: true,
        data: qs.stringify({pids: pids.join(',')}),
      }));

    yield call(setWorklist, {
      persons,
      pids: pids.length
        ? pids
        : persons.map(p => p.person_id),
    });
  } catch (error) {
    yield put(EA.employeesWorklistFailure({error}));
    console.error(error);
  }
}

function* getWorklist(action) {
  try {
    const worklist = yield select(getWorklistSelector);

    const pids = action?.payload?.pids || [];

    const unique = [...new Set([...pids, ...worklist.ids])];

    if (unique.length !== worklist.ids.length) {
      yield call(fetchWorklist, unique);
    }
  } catch (error) {
    console.error(error);
  }
}

function* removePersonsFromWorklist(action) {
  try {
    const {pids} = action.payload;

    if (!pids?.length) return;

    const set = new Set(pids);
    const {data: persons} = yield select(getWorklistSelector);

    const updated = {
      persons: [],
      ids: [],
    };

    persons.filter(p => !set.has(p.person_id)).forEach(p => {
      updated.persons.push(p);
      updated.ids.push(p.person_id);
    });

    if (updated.persons.length) {
      const diff = persons.length - updated.ids.length;

      yield call(setWorklist, updated);

      const text = diff === 1
        ? i18n('employees.person-removed')
        : i18n('employees.persons-removed');

      yield put(notificationsAdd({
        notification: {
          color: 'green',
          text,
        },
      }));
    } else {
      yield call(clearWorklist);
    }
  } catch (error) {
    console.error(error);
  }
}

function* addPersonsToWorklist(action) {
  try {
    const {pids} = action.payload;

    if (!pids?.length) return;

    yield call(getWorklist, {payload: {pids}});

    const text = pids.length === 1
      ? i18n('employees.person-added')
      : i18n('employees.persons-added');

    yield put(notificationsAdd({
      notification: {
        color: 'green',
        text,
      },
    }));
  } catch (error) {
    console.error(error);
  }
}

function* fetchExtraData() {
  try {
    yield put(employeesFetchExtraDataRequest());
    const data = yield all({
      downloads: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_downloads`,
            withCredentials: true,
          })
          .then(response => response.data)),
      metrics: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_metrics`,
            withCredentials: true,
          })
          .then(response => response.data)),
      orgs: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_orgs`,
            withCredentials: true,
          })
          .then(response => response.data)),
      employees: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/plugin/dashboard_employees`,
            withCredentials: true,
          })
          .then(response => response.data)),
    });

    yield put(employeesFetchExtraDataSuccess({data}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(employeesFetchExtraDataFailure({error}));
  }
}
function* fetchSelectedPersonCompetences(action) {
  try {
    const {personId, roleId, type, orgId} = action.payload;

    yield put(employeesFetchSelectedPersonCompetencesRequest({personId}));

    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/rolecompetence_json/${personId}/${roleId}/${type}/${orgId}/0`,
        params: {
          ajax: 1,
          role_meta_types: 'position,role',
        },
        withCredentials: true,
      }));
    const selectedPerson = yield select(getSelectedPerson);

    let section = null;

    if (type === 'mandatory') {
      section = selectedPerson.data.summary.requirement.filter(c => c.id === roleId);
    } else if (type === 'other') {
      // eslint-disable-next-line prefer-destructuring
      section = selectedPerson.data.summary.other;
    } else {
      section = selectedPerson.data.summary.optional.filter(c => c.id === roleId);
    }

    /* JUST SET UT DATA STRUCTURE */
    for (const datum of data) {
      datum.children = [];
      datum.isFetching = false;
    }

    if (section && section.length) {
      section[0].children = data;
    }

    selectedPerson.data.summary.isFetching = false;
    const new_data = selectedPerson.data;

    yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));

    /*
    yield put(
      employeesFetchSelectedPersonSuccess({
        person: {
          ...person,
          competencelevel,
          events,
          summary: personSummary,
          activities,
        },
      })
    );
    */
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(employeesFetchSelectedPersonCompetencesFailure({error}));
  }
}

/*
 * FETCH USERS CHECKLISTS AND RETURN DATA.
 * OTHER THINGS CONTROLL WHERE TO STORE IT.
 * */

function* fetchSelectedPersonChecklistsAPI(payload) {
  const {userName, organisationId} = payload;

  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/pendingchecklists/${organisationId}`,
        params: {
          user_name: userName,
          limit: 20,
        },
        withCredentials: true,
      }));

    return data.persons;
  } catch (error) {
    console.error(error);
  }

  return null;
}

function* fetchSelectedPersonChecklists(action) {
  let organisationId = yield select(getOrganisationId);

  try {
    while (organisationId === null) {
      yield take();
      organisationId = yield select(getOrganisationId);
    }
    yield put(employeesFetchSelectedPersonChecklistsRequest());
    const checklists = yield call(() =>
      fetchSelectedPersonChecklistsAPI({
        userName: action.payload.userName,
        organisationId,
      }));

    yield put(employeesFetchSelectedPersonChecklistsSuccess({data: checklists}));
  } catch (error) {
    console.error(error);

    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }

    yield put(employeesFetchSelectedPersonChecklistsFailure({error}));
  }
}

function* fetchSelectedPerson(action) {
  const {userName, extraFields = []} = action.payload;

  yield put(employeesFetchSelectedPersonRequest({userName}));
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }

  try {
    const {
      person,
      personSummary,
      competencelevel,
      events,
      checklists,
      activities,
    } = yield all({
      person: call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/persons/${userName}?fields=${extraFields?.length ? extraFields.map(ef => ef.id).join(',') + ',' : ''}fullname,is_absent,employee_code,birthdate,absent_from,absent_to,organisations(organisation_id,title,brand_id),email2,user_name,firstname,lastname,email,mobile,profile_image,positions(title,role_id,organisation_id)`,
            withCredentials: true,
          })
          .then(response => {
            const person = response.data.persons[0];

            if (person && person.is_absent) {
              person.absent_data = call(() =>
                axios
                  .request({
                    method: 'GET',
                    url: `${backendUrl}/persons/employment_json/${person.id}/${organisationId}`,
                    withCredentials: true,
                  })
                  .then(({data}) => data));
            }

            return person;
          })),
      competencelevel: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/competencelevel`,
            params: {user_name: userName},
            withCredentials: true,
          })
          .then(response => response.data.competencelevel[0].level)),
      events: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/personevents`,
            params: {
              user_name: userName,
              waitlist: 1,
              confirmed: 1,
              fields: 'title,location,competence_id,startdate,enddate,person,waitlist',
            },
            withCredentials: true,
          })
          .then(response => response.data.personevents)),
      personSummary: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/roles`,
            params: {
              user_name: userName,
              oid: organisationId,
              summary: 1,
            },
            withCredentials: true,
          })
          .then(response => response.data)),
      activities: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/organisations/${organisationId}/activities`,
            params: {
              user_name: userName,
              limit: 20,
              fields:
                'person(profile_image,fullname,firstname,lastname,user_name),competence_title,competence_id,passed,date,subject,ts',
            },
            withCredentials: true,
          })
          .then(({data}) => {
            const {activities} = data;

            activities.sort(({date: date1, ts: ts1}, {date: date2, ts: ts2}) =>
              date1 && (date1 > date2 && -1 || date1 < date2 && 1)
                || ts1 && (ts1 > ts2 && -1 || ts1 < ts2 && 1)
                || 0);

            return activities;
          })),

    });

    personSummary.isFetching = false;
    personSummary.activities = {};
    personSummary.activities.isFetching = false;
    personSummary.activities.children = [];
    for (let i = 0; i < personSummary.requirement.length; i += 1) {
      personSummary.requirement[i].children = [];
      personSummary.requirement[i].isFething = false;
    }
    for (let i = 0; i < personSummary.other.length; i += 1) {
      personSummary.other[i].children = [];
      personSummary.other[i].isFething = false;
    }
    for (let i = 0; i < personSummary.optional.length; i += 1) {
      personSummary.optional[i].children = [];
      personSummary.optional[i].isFething = false;
    }

    yield put(employeesFetchSelectedPersonSuccess({
      person: {
        ...person,
        competencelevel,
        events,
        checklists,
        summary: personSummary,
        activities,
      },
    }));
    yield put(employeesFetchSelectedPersonExpiring({user_name: userName}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    if (error.response.status === 403 || error.response.status === 404) {
      yield put(notificationsAdd({
        notification: {
          text:  i18n('employees.no-access-to-profile'),
          color: 'red',
          redirectTo: `/employees/${organisationId}`,
        },
      }));
    }

    yield put(employeesFetchSelectedPersonFailure({error}));
  }
}

function* addPerson(action) {
  const {employee, callback} = action.payload;
  const organisationId = yield select(getOrganisationId);
  const {
    send_login,
    user_name,
    firstname,
    lastname,
    email,
    role_id,
    role_ids,
    mobile,
    employee_code,
  } = employee;
  let new_role_ids = '';

  if (role_ids) {
    new_role_ids = role_ids.map(r => r.id);
    new_role_ids = new_role_ids.join(',');
  }

  const do_send_login = send_login ? '1' : '';
  const newPerson = {
    user_name:
      user_name
      || `${firstname}.${lastname}`.replace(/[^.@_a-z-]/gi, '').toLowerCase(),
    firstname,
    lastname,
    ajax: 1,
    send_login: do_send_login,
    role_id,
    employee_code,
    mobile,
    email,
    'role_ids[]': new_role_ids,
    oid: organisationId,
  };

  try {
    yield put(employeesAddPersonPostRequest());

    const data = yield call(() =>
      axios
        .request({
          method: 'POST',
          data: qs.stringify(newPerson),
          url: `${backendUrl}/persons/save`,
          withCredentials: true,
        })
        .then(response => response.data));

    if (data.statuscode === 0) {
      yield call(() => callback({employee: newPerson}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('employees.create-new-success'),
          color: 'green',
        },
      }));
      yield put(employeesAddPersonPostSuccess({data}));
    } else {
      yield put(notificationsAdd({
        notification: {
          text: data.status,
          color: 'red',
        },
      }));
      yield put(employeesAddPersonPostFailure());
    }
  } catch (error) {
    console.error(error);
    yield put(notificationsAdd({
      notification: {
        text: i18n('employees.create-new-failure'),
        color: 'red',
      },
    }));
    yield put(employeesAddPersonPostFailure({error}));
  }
}

function* editSelectedPerson(action) {
  const {person: editedPerson, callback, refetchAfterEdit} = action.payload;

  try {
    yield put(employeesEditSelectedPersonPutRequest());

    const userName = yield select(getSelectedPersonUsername);

    const {user_name, ...personData} = editedPerson;

    const params = encodeURI(stringifyUrlParams(personData, 'person_data', true));
    const {person} = yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      }));


    yield call(() => callback({employee: editedPerson}));

    yield put(employeesEditSelectedPersonPutSuccess({person}));

    yield put(notificationsAdd({
      notification: {
        text: i18n('person.user-updated-success'),
        color: 'green',
      },
    }));

    const {data: profile} = yield select(getProfile);

    if (user_name === profile.user_name) {
      yield put(profileFetchPerson());
    }

    if(refetchAfterEdit) {
      yield put(employeesFetchSelectedPerson({userName: user_name}));
    }
  } catch (error) {
    console.error(error);
    yield put(employeesEditSelectedPersonPutFailure({message: 'failed to save employee'}));
  }
}

function* updateSelectedPersonEmployment(action) {
  try {
    const {personId, callback} = action.payload;
    const orgId = yield select(getOrganisationId);
    const {payload} = action;
    const params = {
      ...payload.data,
      ajax: 1,
    };

    yield put(employeesUpdateSelectedPersonEmploymentRequest());

    const {data: return_data} = yield call(() =>
      axios.request({
        method: 'POST',
        params,
        url: `${backendUrl}/persons/saveajax/${personId}/${orgId}/0`,
        withCredentials: true,
      }));

    if (return_data.statuscode === 0) {
      yield put(employeesUpdateSelectedPersonEmploymentSuccess({personId}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.change-employment-success'),
          color: 'green',
        },
      }));
      yield call(() => callback(return_data));
    } else {
      yield put(employeesUpdateSelectedPersonEmploymentFailure({}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.change-employment-failure'),
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(employeesUpdateSelectedPersonEmploymentFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.change-employment-failure'),
        color: 'red',
      },
    }));
  }
}

function* moveEmployee(action) {
  try {
    const {personId, move_type, position} = action.payload;
    const orgId = yield select(getOrganisationId);

    yield put(employeeMoveRequest());
    let operation = 'add';

    if (move_type === '2') {
      operation = 'move';
    }
    const params = {
      person_id: personId,
      organisation_id: orgId,
      position_id: position,
      json: 1,
      operation,
    };

    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params,
        url: `${backendUrl}/persons/move`,
        withCredentials: true,
      }));

    yield put(employeeMoveSuccess({personId}));
    yield put(notificationsAdd({
      notification: {
        text: data.message,
        color: 'green',
      },
    }));

    yield put(employeesGet());
  } catch (error) {
    console.error(error);
    yield put(employeeMoveFailure({error}));
  }
}

function* updateSelectedPersonRoles(action) {
  try {
    const userName = yield select(getSelectedPersonUsername);
    const {roles} = action.payload;
    const params = encodeURI(stringifyUrlParams({role_ids: roles.map(role => role.id)}));

    yield put(employeesUpdateSelectedPersonRolesPostRequest());
    yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}/roles?${params}`,
        withCredentials: true,
      }));
    yield put(employeesUpdateSelectedPersonRolesPostSuccess({userName}));
  } catch (error) {
    console.error(error);
    yield put(employeesUpdateSelectedPersonRolesPostFailure({error}));
  }
}

function* fetchChecklists(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchChecklistsGetRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data: {competences}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/pendingchecklists/${organisationId}`,
        withCredentials: true,
      }));

    yield put(employeesFetchChecklistsGetSuccess({competences}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchChecklistsGetFailure({message: 'falied to save employee'}));
  }
}

function searchItems(node, find) {
  if (node.organisation_id === find) {
    return node;
  } else if (node.children != null) {
    let result = null;

    for (let i = 0; result == null && i < node.children.length; i++) {
      result = searchItems(node.children[i], find);
    }

    return result;
  }

  return null;
}

function* fetchOrganisationProgress(action) {
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  yield put(EA.employeesFetchTreeProgressRequest({
    orgId: organisationId,
    fetchMore: action.payload.fetchMore,
  }));
  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/getorgstats_json?oid=${organisationId}`,
        withCredentials: true,
      }));

    yield put(EA.employeesFetchTreeProgressSuccess({
      orgId: organisationId,
      data,
    }));
  } catch (error) {
    console.error(error);
    yield put(EA.employeesFetchTreeProgressFailure({message: 'falied to save employee'}));
  }
}
function* fetchOrganisationTree(action) {
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  yield put(EA.employeesFetchTreeRequest({
    orgId: organisationId,
    fetchMore: action.payload.fetchMore,
  }));
  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/organisationmaplevel_json/${organisationId}`,
        withCredentials: true,
      }));

    data.children.forEach(child => {
      child.children = [];
      child.progress_status = T.LoadStatuses.NOT_LOADED;
      child.status = T.LoadStatuses.NOT_LOADED;
    });

    yield action.payload.fetchMore
      ? put(EA.employeesFetchTreeSuccess({
        data,
        fetchMore: true,
        orgId: organisationId,
      }))
      : put(EA.employeesFetchTreeSuccess({data}));
    yield put(EA.employeesFetchTreeProgress({orgId: organisationId}));
    yield all(data.children.map(item => put(EA.employeesFetchTreeProgress({orgId: item.organisation_id}))));
  } catch (error) {
    console.error(error);
    yield put(EA.employeesFetchTreeFailure({message: 'falied to save employee'}));
  }
}

function* fetchOrganisation(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchOrganisationRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data} = yield call(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/organisations/organisationmaplevel_json/${organisationId}?simple=1`,
        withCredentials: true,
      }));

    yield put(employeesFetchOrganisationSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchOrganisationFailure({message: 'falied to save employee'}));
  }
}

function* approveRejectSelfSignAction(action) {
  const {actionId, task, callback, orgId} = action.payload;

  yield put(employeesApproveRejectSelfSignRequest());
  try {
    const data = {
      personId: task.person_id,
      courseId: task.course_id,
      passed: 0,
    };

    if (actionId === 'reject') {
      yield retry(() =>
        axios.request({
          method: 'DELETE',
          url: `${backendUrlV2}/personcompetences/${task.phce_id}`,
          data,
          withCredentials: true,
        }));

      yield put(notificationsAdd({
        notification: {
          color: 'red',
          text: i18n('employees.task-rejected', {
            functionArgs: {
              course: task.course,
              first: task.firstname,
              last: task.lastname,
            },
          }),
        },
      }));
    } else if (actionId === 'approve') {
      if (task.course_type?.course_type === CourseType.checklist_item) {
        yield retry(() =>
          axios.request({
            method: 'POST',
            url: `${backendUrl}/courses/setchecklistitem`,
            params: {
              value: true,
              person_id: task.person_id,
              name: 'checklist_item_manager_' + task.course_id,
            },
            withCredentials: true,
          }));

        yield put(notificationsAdd({
          notification: {
            color: 'green',
            text: i18n('employees.checklist-approved', {
              functionArgs: {
                course: task.course,
                first: task.firstname,
                last: task.lastname,
              },
            }),
          },
        }));
      }else {
        data.passed = 100;
        yield retry(() =>
          axios.request({
            method: 'PUT',
            url: `${backendUrlV2}/personcompetences/${task.phce_id}`,
            data,
            withCredentials: true,
          }));

        yield put(notificationsAdd({
          notification: {
            color: 'green',
            text: i18n('employees.task-approved', {
              functionArgs: {
                course: task.course,
                first: task.firstname,
                last: task.lastname,
              },
            }),
          },
        }));
      }
    }
    yield put(employeesFetchChecklists({orgId}));

    if (callback) {
      callback();
    }

    yield put(employeesApproveRejectSelfSignSuccess({}));
  } catch (error) {
    console.error(error);
    yield put(employeesApproveRejectSelfSignFailure({message: 'falied to save employee'}));
  }
}

function* fetchStatistics(action) {
  const profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(profileFetchPersonRequest());
    }
    yield take(PROFILE_FETCH_PERSON_SUCCESS);
  }
  yield put(employeesFetchStatisticsGetRequest());
  const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);

  try {
    const {data: {statistics}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/statistics`,
        withCredentials: true,
      }));

    yield put(employeesFetchStatisticsGetSuccess({statistics}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchStatisticsGetFailure({message: 'falied to save employee'}));
  }
}

function* fetchExpiringCompetences(action) {
  try {
    const profile = yield select(getProfile);

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    yield put(employeesFetchActivitiesGetRequest());
    const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          limit: 20,
          organisation_ids: organisationId,
          state: 'expired',
          fields: 'valid_until,person,competence_title,competence_id',
        },
        withCredentials: true,
      }));

    yield put(employeesFetchExpiringCompetencesSuccess({expiring: data.personcompetences}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchExpiringCompetencesFailure({message: 'failed to fetch activities'}));
  }
}

function* fetchActivities(action) {
  try {
    const profile = yield select(getProfile);

    if (!profile.data) {
      if (!profile.isFetching) {
        yield put(profileFetchPersonRequest());
      }
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
    }

    yield put(employeesFetchActivitiesGetRequest());
    const organisationId = action.payload.orgId ? action.payload.orgId : yield select(getOrganisationId);
    const {data: {activities}} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/organisations/${organisationId}/activities`,
        params: {
          limit: 20,
          fields:
            'person(profile_image,fullname,firstname,lastname,user_name),competence_title,competence_id,passed,date,subject,ts',
        },
        withCredentials: true,
      }));

    activities.sort(({date: date1, ts: ts1}, {date: date2, ts: ts2}) =>
      date1 && (date1 > date2 && -1 || date1 < date2 && 1)
        || ts1 && (ts1 > ts2 && -1 || ts1 < ts2 && 1)
        || 0);
    yield put(employeesFetchActivitiesGetSuccess({activities}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchActivitiesGetFailure({message: 'failed to fetch activities'}));
  }
}

function* fetchFunctions() {
  let organisationId = yield select(getOrganisationId);

  while (organisationId === null) {
    yield take();
    organisationId = yield select(getOrganisationId);
  }
  yield put(employeesFetchFunctionsRequest());

  try {
    const {data: {roles: functions}} = yield call(axios.request, {
      method: 'GET',
      url: `${backendUrl}/api/roles`,
      params: {
        limit: 101,
        fields: 'title,id,rolemetatype',
        organisation_ids: organisationId,
        role_meta_types: 'role,position',
      },
      withCredentials: true,
    });

    yield put(employeesFetchFunctionsSuccess({functions}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchFunctionsFailure({error}));
  }
}

function* fetchSelectedPersonEvents(payload) {
  yield put(employeesFetchSelectedPersonEventsRequest());
  try {
    const userName = yield select(getSelectedPersonUsername);
    const {data: {personevents: events}} = yield call(axios.request, {
      method: 'GET',
      url: `${backendUrl}/api/personevents`,
      params: {
        user_name: userName,
        include_person: 1,
        fields:
          'title,location,competence_id,startdate,enddate,person(profile_image,fullname,firstname,lastname,user_name)',
      },
      withCredentials: true,
    });

    yield put(employeesFetchSelectedPersonEventsSuccess({data: events}));
  } catch (error) {
    yield put(employeesFetchSelectedPersonEventsFailure({error}));
  }
}

function* fetchEventsApi({organisationId, waitlist, includePerson}) {
  const {data: {personevents: events}} = yield call(axios.request, {
    method: 'GET',
    url: `${backendUrl}/api/personevents`,
    params: {
      organisation_id: organisationId,
      include_person: !includePerson ? 0 : 1,
      all: 1,
      waitlist: 1,
      confirmed: 1,
      fields:
          'title,location,competence_id,startdate,enddate,sign_on_deadline,sign_off_deadline,person,available_count,max_participants,participants_count,waitlist,confirmed(profile_image,fullname,firstname,lastname,user_name)',
    },
    withCredentials: true,
  });

  return events;
}

export function* updateEmployeesEvents(events) {
  if (!events) return;

  if (events?.length === 0) {
    yield put(EA.employeesUpdateEvents({empty: true}));

    return;
  }

  try {
    const profileId = yield select(getProfileId);

    if (!profileId) {
      return;
    }

    const eventIdsByEmployeeId = {};
    const employeeIdsByEventId = {};

    const employeesWaitlistByEventId = {};
    const employeesConfirmedByEventId = {};

    const employeeIdsWaitlistByEventId = {};
    const employeeIdsConfirmedByEventId = {};

    const uniqueEventIds = new Set();
    const uniqueEvents = [];

    events.forEach(event => {
      const person = event.person;
      const personId = person.id;
      const eventId = event.id;

      const {waitlist, confirmed} = event;
      const parsedEvent = parseEvent(event);

      // parseEvent returns null if event startdate is today or in the past
      if (!parsedEvent) return;

      if (!uniqueEventIds.has(eventId)) {
        uniqueEventIds.add(eventId);
        uniqueEvents.push(parsedEvent);
      }

      if (personId === profileId) {
        return;
      }

      if (eventIdsByEmployeeId[personId]) {
        if (!eventIdsByEmployeeId[personId].includes(eventId)) {
          eventIdsByEmployeeId[personId].push(eventId);
        }
      } else {
        eventIdsByEmployeeId[personId] = [eventId];
      }

      const participantObj = {
        ...person,
        waitlist,
        confirmed,
      };

      if (!employeeIdsByEventId[eventId]) {
        employeeIdsByEventId[eventId] = [personId];
      } else {
        if (!employeeIdsByEventId[eventId].includes(personId)) {
          employeeIdsByEventId[eventId].push(personId);
        }
      }

      if (waitlist) {
        if (!employeesWaitlistByEventId[eventId]) {
          employeesWaitlistByEventId[eventId] = [participantObj];
          employeeIdsWaitlistByEventId[eventId] = [personId];
        } else {
          if (!employeeIdsWaitlistByEventId[eventId].includes(personId)) {
            employeeIdsWaitlistByEventId[eventId].push(personId);
            employeesWaitlistByEventId[eventId].push(participantObj);
          }
        }
      } else if (confirmed) {
        if (!employeesConfirmedByEventId[eventId]) {
          employeesConfirmedByEventId[eventId] = [participantObj];
          employeeIdsConfirmedByEventId[eventId] = [personId];
        } else {
          if (!employeeIdsConfirmedByEventId[eventId].includes(personId)) {
            employeeIdsConfirmedByEventId[eventId].push(personId);
            employeesConfirmedByEventId[eventId].push(participantObj);
          }
        }
      }
    });

    yield put(EA.employeesUpdateEvents({
      employeeIdsByEventId,
      eventIdsByEmployeeId,
      employeesWaitlistByEventId,
      employeesConfirmedByEventId,
      employeeIdsWaitlistByEventId,
      employeeIdsConfirmedByEventId,
      eventsSortedByDate: uniqueEvents.sort((a, b) => {
        const aDate = dayjs(a.startdate);
        const bDate = dayjs(b.startdate);

        return aDate.isBefore(bDate) ? 1 : -1;
      }),
    }));
  } catch (error) {
    console.error(error);
  }
}

function* fetchEmployeesEvents(action) {
  yield put(employeesFetchEventsRequest());

  try {
    let organisationId = yield select(getOrganisationId);

    if (!organisationId) {
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
      organisationId = yield select(getOrganisationId);
    }

    const employeesAndSelfEvents = yield call(fetchEventsApi, {organisationId});

    yield call(updateEmployeesEvents, employeesAndSelfEvents);

    yield put(employeesFetchEventsSuccess({events: employeesAndSelfEvents}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchEventsFailure({error}));
  }
}

function* fetchEventsWaitlist(action) {
  yield put(employeesFetchEventsWaitlistRequest());

  try {
    let organisationId = yield select(getOrganisationId);

    if (!organisationId) {
      yield take(PROFILE_FETCH_PERSON_SUCCESS);
      organisationId = yield select(getOrganisationId);
    }

    const events = yield call(fetchEventsApi, {
      waitlist: true,
      organisationId,
    });

    yield put(employeesFetchEventsWaitlistSuccess({eventsWaitlist: events}));
  } catch (error) {
    console.error(error);
    yield put(employeesFetchEventsWaitlistFailure({error}));
  }
}

function* resetPassword({payload: {identifier}}) {
  yield put(employeesResetPasswordRequest);
  try {
    yield put(employeesResetPasswordSuccess());
    // TODO: some sort of feedback with result for the user
    yield call(axios.request, {
      method: 'POST',
      url: `${backendUrl}/api/forgot_password`,
      params: {identification: identifier},
      withCredentials: true,
    });
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.send-new-password-to-user-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.send-new-password-to-user-failure'),
        color: 'red',
      },
    }));
    yield put(employeesResetPasswordFailure({error}));
  }
}

function* editPassword({
  payload: {
    data: {user_name: newUserName, newPassword},
    callback,
  },
}) {
  yield put(employeesEditPasswordRequest());
  try {
    const params = {};

    const {data} = yield select(getSelectedPerson);

    let userNameChanged = false;

    if (data.user_name !== newUserName) {
      /*
       * new user name add it.
       * */
      userNameChanged = true;
      params.user_name = newUserName;
    }
    if (newPassword) {
      params.password = newPassword;
    }

    const query = encodeURI(stringifyUrlParams({...params}, 'person_data'));

    // TODO: some sort of feedback with result for the user
    yield call(axios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${data.user_name}?${query}`,
      withCredentials: true,
    });
    yield put(employeesEditPasswordSuccess());
    yield put(notificationsAdd({
      notification: {
        // Password always changes
        text: userNameChanged
          ?  i18n('person.update-username-password-success')
          : 'Passord er endret',
        color: 'green',
      },
    }));
    yield call(() => callback({employee: {user_name: newUserName}}));
  } catch (error) {
    console.error(error);
    yield put(employeesEditPasswordFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.update-username-password-failure'),
        color: 'red',
      },
    }));
  }
}

function* saveVerification({payload: {data}}) {
  if (!data || !data.verifycourse_ids) return;

  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);
    const params = {
      ...data,
      person_id: id,
      json: 1,
      verifycourse_ids: data.verifycourse_ids,
    };
    const url = `${backendUrl}/persons/saveverify/${id}/${orgId}`;

    yield put(employeesSaveVerificationRequest());
    const {data: return_data} = yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield return_data.status
      ? all([
        put(employeesSaveVerificationSuccess()),
        put(employeesFetchSelectedPerson({userName})),
        put(notificationsAdd({
          notification: {
            text: return_data.message,
            color: 'green',
          },
        })),
      ])
      : all([
        put(employeesSaveVerificationFailure({})),
        put(notificationsAdd({
          notification: {
            text: return_data.message,
            color: 'red',
          },
        })),
      ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(employeesSaveVerificationFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* removeRequirements({payload: {data}}) {
  const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
  const orgId = yield select(getOrganisationId);
  const params = {
    ajax: 1,
    'course_ids_no_check[]': Object.keys(data.selectedItems).join(','),
  };

  const url = `${backendUrl}/persons/savecompetencerequirements/${id}/${orgId}`;

  try {
    yield put(employeesRemoveRequirementsRequest());

    const {data: return_data} = yield call(axios.request, {
      method: 'POST',
      url,
      params,
      withCredentials: true,
    });

    yield put(EA.employeesRemoveRequirementsSuccess());
    yield return_data.statuscode === -2
      ? all([
        put(EA.employeesSaveRequirementsSuccess()),
        put(EA.employeesFetchSelectedPerson({userName})),
        put(notificationsAdd({
          notification: {
            text: i18n('globals.requirement-deleted'),
            color: 'green',
          },
        })),
      ])
      : all([
        put(EA.employeesRemoveRequirementsFailure()),
        put(notificationsAdd({
          notification: {
            text: 'Error',
            color: 'red',
          },
        })),
      ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(EA.employeesRemoveRequirementsFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* saveRequirements({payload: {data}}) {
  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);

    if (data.addAs === 'competence') {
      const competenceParams = {
        ajax: 1,
        'course_ids[]': Object.keys(data.selectedItems).join(','),
      };
      const competenceUrl = `${backendUrl}/persons/savequal/${id}/${orgId}`;

      Object.keys(data.selectedItems).forEach(item => {
        competenceParams[`course_ids_${item}_comments`] = data.selectedItems[item].comments;
        competenceParams[`course_ids_${item}_joined`] = data.selectedItems[item].joined ? dayjs(data.selectedItems[item].joined).format('DD.MM.YYYY') : null;
        competenceParams[`course_ids_${item}_valid_until`] = data.selectedItems[item].valid_until ? dayjs(data.selectedItems[item].valid_until).format('DD.MM.YYYY') : null;
      });

      yield call(axios.request, {
        method: 'POST',
        url: competenceUrl,
        params: competenceParams,
        withCredentials: true,
      });
    }else {
      const params = {
        ajax: 1,
        'course_ids[]': Object.keys(data.selectedItems).join(','),
      };
      const url = `${backendUrl}/persons/savecompetencerequirements/${id}/${orgId}`;

      yield put(EA.employeesSaveRequirementsRequest());
      yield call(axios.request, {
        method: 'POST',
        url,
        params,
        withCredentials: true,
      });
    }
    yield all([
      put(EA.employeesSaveRequirementsSuccess()),
      put(EA.employeesFetchSelectedPerson({userName})),
      put(notificationsAdd({
        notification: {
          text: i18n('person.user-updated-success'),
          color: 'green',
        },
      })),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(EA.employeesSaveRequirementsFailure({error})),
      put(notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })),
    ]);
  }
}

function* saveRole({payload: {data}}) {
  try {
    const {data: {id, user_name: userName}} = yield select(getSelectedPerson);
    const orgId = yield select(getOrganisationId);

    const {position, roles} = data;

    const params = {
      ...data,
      person_id: id,
      ajax: 1,
      position_id: position,
    };

    delete params.position;

    if (roles) {
      params.roles_ids = roles.map(({id}) => id);
      delete params.roles;
    }

    const url = `${backendUrl}/persons/saveajax/${id}/${orgId}`;

    yield put(EA.employeesSaveRoleRequest());

    yield call(axios.request, {
      method: 'POST',
      params,
      url,
      withCredentials: true,
    });

    yield all([
      put(EA.employeesSaveRoleSuccess()),
      put(EA.employeesFetchSelectedPerson({userName})),
      put(notificationsAdd({
        notification: {
          text: i18n('person.user-updated-success'),
          color: 'green',
        },
      })),
    ]);
  } catch (error) {
    console.error(error);
    yield all([
      put(EA.employeesSaveRoleFailure({error})),
      put(notificationsAdd({
        notification: {
          text: i18n('globals.error'),
          color: 'red',
        },
      })),
    ]);
  }
}

function* sendWorklistMail(action) {
  const {title, body, callback} = action.payload;

  yield put(EA.employeesWorklistSendMailRequest());

  try {
    const {ids: worklistIds} = yield select(getWorklistSelector);

    if (!worklistIds.length) return;

    console.log({
      sendWorklistMailSaga: {
        title,
        body,
        worklistIds,
      },
    });

    yield put(EA.employeesWorklistSendMailSuccess());

    yield put(notificationsAdd({
      notification: {
        text: i18n('person.user-updated-success'),
        color: 'green',
      },
    }));

    if (callback) {
      yield callback();
    }
  } catch (error) {
    yield put(EA.employeesWorklistSendMailFailure());
    console.error(error);
  }
}

function* addWorklistRoles(action) {
  const {roles, callback} = action.payload;

  const {ids: worklistPids} = yield select(getWorklistSelector);

  const orgId = yield select(getOrganisationId);

  if (orgId == null) return;

  const organisation_id = Number.parseInt(orgId, 10);

  yield put(EA.employeesWorklistAddRolesRequest());

  try {
    const data = worklistPids.reduce((acc, pid) => (
      {
        ...acc,
        [pid]: roles.map(({role_id}) => role_id),
      }
    ), {});

    yield all(Object.entries(data).map(([pid, roleIds]) => call(axios.request, {
      method: 'PUT',
      url: `${backendUrlV2}/persons/${pid}/roles`,
      data: roleIds.map(role_id => ({
        role_id,
        organisation_id,
      })),
      withCredentials: true,
    })));

    yield put(EA.employeesWorklistAddRolesSuccess());

    const text = worklistPids.length === 1
      ? i18n('person.user-updated-success')
      : i18n('person.users-updated-success');

    yield put(notificationsAdd({
      notification: {
        text,
        color: 'green',
      },
    }));

    if (callback) {
      yield callback();
    }
  } catch (error) {
    yield put(EA.employeesWorklistAddRolesFailure());
    console.error(error);
  }
}

function* initEmployeesContainer({payload}) {
  const {orgId} = payload;
  const prevOrgId = yield select(state => state.profile.activeOrg.prevOrgId);

  const employees = yield select(state => state.employees);
  const tenets = yield select(state => state.profile.tenets);

  const calls = [];

  if (tenets.status === T.LoadStatuses.NOT_LOADED) {
    calls.push(fetchAllOrganisations);
  }

  const fns = {
    organisation: fetchOrganisation,
    list: fetchEmployees,
    statistics: fetchStatistics,
    checklists: fetchChecklists,
    expiring: fetchExpiringCompetences,
    events: fetchEmployeesEvents,
  };

  if (orgId === prevOrgId) {
    Object.keys(fns).forEach(key => {
      if (employees[key].status === T.LoadStatuses.NOT_LOADED) {
        calls.push(fns[key]);
      }
    });
  } else {
    calls.push(...Object.values(fns));
  }

  if (calls.length) {
    yield all(calls.map(fn => call(fn, {payload})));
  }
}

const employeeSagas = [
  takeLatest(routerEmployeesListDidMount().type, initEmployeesContainer),
  takeLatest(EA.employeesFetchTree().type, fetchOrganisationTree),
  takeEvery(EA.employeesFetchTreeProgress().type, fetchOrganisationProgress),

  takeLatest(EMPLOYEES_FETCH_CHECKLISTS, fetchChecklists),

  takeLatest(EMPLOYEES_FETCH_EVENTS, fetchEmployeesEvents),
  takeLatest(EMPLOYEES_GET, fetchEmployees),
  throttle(700, employeesSearchPhrazeChange().type, searchEmployees),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON, fetchSelectedPerson),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS, fetchSelectedPersonEvents),
  takeLatest(EMPLOYEES_EDIT_VIEW_INIT, fetchSelectedPerson),
  takeLatest(EMPLOYEES_FETCH_SELECTED_PERSON_REPORT, fetchSelectedPersonReport),

  takeEvery(EMPLOYEES_REPORT_VIEW_INIT, fetchSelectedReport),
  takeLatest(EMPLOYEES_ADD_PERSON, addPerson),
  takeLatest(EMPLOYEES_EDIT_SELECTED_PERSON, editSelectedPerson),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS,
    fetchSelectedPersonChecklists,
  ),
  takeLatest(EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES, updateSelectedPersonRoles),
  takeLatest(
    EMPLOYEES_UPDATE_SELECTED_PERSON_EMPLOYMENT,
    updateSelectedPersonEmployment,
  ),
  takeLatest(
    EMPLOYEES_UPDATE_SELECTED_PERSON_ROLES_POST_SUCCESS,
    fetchSelectedPerson,
  ),

  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES,
    fetchSelectedPersonCompetences,
  ),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES,
    fetchSelectedPersonActivites,
  ),
  takeLatest(
    EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING,
    fetchSelectedPersonExpiring,
  ),
  takeLatest(EMPLOYEE_MOVE, moveEmployee),
  takeLatest(employeesApproveRejectSelfSign().type, approveRejectSelfSignAction),
  takeLatest(EMPLOYEES_FETCH_STATISTICS, fetchStatistics),
  takeLatest(EMPLOYEES_FETCH_ACTIVITIES, fetchActivities),
  takeLatest(EMPLOYEES_FETCH_EXPIRING_COMPETENCES, fetchExpiringCompetences),
  takeLatest(EMPLOYEES_FETCH_FUNCTIONS, fetchFunctions),
  takeLatest(EMPLOYEES_RESET_PASSWORD, resetPassword),
  takeLatest(EA.employeesWorklistGet().type, getWorklist),
  takeLatest(EA.EMPLOYEES_WORKLIST_ADD, addPersonsToWorklist),
  takeLatest(EA.EMPLOYEES_WORKLIST_REMOVE, removePersonsFromWorklist),
  takeLatest(EA.EMPLOYEES_WORKLIST_CLEAR, clearWorklist),
  takeLatest(EA.EMPLOYEES_WORKLIST_SEND_MAIL, sendWorklistMail),
  takeLatest(EA.EMPLOYEES_WORKLIST_ADD_ROLES, addWorklistRoles),
  takeLatest(EMPLOYEES_EDIT_PASSWORD, editPassword),
  takeLatest(EMPLOYEES_FETCH_EXTRADATA, fetchExtraData),
  takeLatest(EMPLOYEES_FETCH_EXTRADATA, fetchEmployees),
  takeLatest(EMPLOYEES_SAVE_VERIFICATION, saveVerification),
  takeLatest(EMPLOYEES_SAVE_ROLE, saveRole),
  takeLatest(EMPLOYEES_REMOVE_REQUIREMENTS, removeRequirements),
  takeLatest(EMPLOYEES_SAVE_REQUIREMENTS, saveRequirements),
  takeLatest(EMPLOYEES_FETCH_EVENTS_WAITLIST, fetchEventsWaitlist),
];

export default employeeSagas;
