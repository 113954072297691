import {
  authLoginRequest,
  authAttempt,
  authLoginNewPasswordRequest,
  authGotoNext,
  authCloseResetPassowrdForm,
} from '@actions/auth.actions';

export const authDispatch = dispatch => ({
  login: (username, password) => dispatch(authLoginRequest({
    username,
    password,
  })),
  attempt: () => dispatch(authAttempt()),
  forgot: username => dispatch(authLoginNewPasswordRequest(username)),
  select: type => dispatch(authGotoNext(type)),
  reset: () => dispatch(authCloseResetPassowrdForm()),
});
