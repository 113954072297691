import * as manageActions from '@actions/manage.actions';
import * as T from '@types/load.types';

const initialState = {
  roles: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    stats: null,
    error: null,
  },
  competences: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    stats: null,
    error: null,
  },
};

const manage = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case manageActions.MANAGE_ROLES_GET_REQUEST:
    return {
      ...state,
      roles: {
        ...state.roles,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  case manageActions.MANAGE_ROLES_GET_SUCCESS:
    return {
      ...state,
      roles: {
        ...state.roles,
        data: action.payload.roles,
        stats: {competences: 3},
        status: T.LoadStatuses.LOADED,
        error: null,
      },
    };
  case manageActions.MANAGE_COMPETENCE_GET_REQUEST:
    return {
      ...state,
      competences: {
        ...state.competences,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  case manageActions.MANAGE_COMPETENCE_GET_SUCCESS:
    return {
      ...state,
      competences: {
        ...state.competences,
        data: action.payload.competences,
        stats: {competences: 3},
        status: T.LoadStatuses.LOADED,
        error: null,
      },
    };
  default:
    return state;
  }
};

export default manage;
