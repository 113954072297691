import {i18n} from '@src/i18n';

export const employees = {
  dashboard:        '/dashboard/:orgId',
  employees:        '/employees/:orgId',
  worklist:         '/employees/:orgId/worklist',
  addEmployee:      '/employees/:orgId/user/add',
  employeePreview:  '/employees/:orgId/user/:userName',
  employeeReport:   '/employees/:orgId/user/:userName/fullreport',
  employeeFiles:    '/employees/:orgId/user/:userName/files',
  employeeCV:       '/employees/:orgId/user/:userName/cvs/:id?/:edit?',
  employeeEdit:     '/employees/:orgId/user/:userName/edit',
  employeeMessage:  '/employees/:orgId/user/:userName/message',
  reportSearch:     '/employees/:orgId/report/dashboard',
  reportRole:       '/employees/:orgId/report/role',
  reportCompetence: '/employees/:orgId/report/competence',
  reportPreview:    '/employees/:orgId/report/:reportId',
};

export const dashboard = {
  search:           '/dashboard/search',
  expire:           '/dashboard/expire',
  content:          '/dashboard/content',
  userPreview:      '/dashboard/search/:orgId/user/:userName',
  userPreviewEdit:  '/dashboard/search/:orgId/user/:userName/edit',
  userReport:       '/dashboard/search/:orgId/user/:userName/fullreport',
  company:          '/dashboard/company/:reportUrl/:subItem1?/:subItem2?',
};

export const manage = {
  roles:         '/manage/roles',
  events:        '/manage/events/?:eventId',
  competences:   '/manage/competences/:competenceId?',
};

export const courseCatalog = {
  main: {
    index: 0,
    path: '/course-catalog',
    active: '/course-catalog',
    breadcrumb: 'Kurskatalog',
  },
  category: {
    index: 1,
    path: '/course-catalog/category/:groupId',
    active: '/course-catalog/',
    breadcrumb: 'Kurskatalog',
  },
  coursePreview: {
    index: 2,
    path: '/course-catalog/:cid',
    active: '/course-catalog/',
    breadcrumb: 'Kurs',
  },
  contentView: {
    index: 3,
    path: '/course-catalog/content/:contentId',
    active: '/course-catalog/content/:contentId',
    breadcrumb: '',
  },
};

export const myEducation = {
  main: {
    index: 0,
    path: '/my-education',
    active: '/my-education',
    breadcrumb: 'Full rulle',
  },
  profileView: {
    index: 1,
    path: '/my-education/profile/:personId',
    active: '/my-education/profile/:personId',
    breadcrumb: 'Rediger profil',
  },
  courseView: {
    index: 3,
    path: '/my-education/course/:courseId',
    active: '/my-education/course',
    breadcrumb: 'Kurs',
  },
  cvView: {
    index: 4,
    path: '/my-education/cv/:personId',
    active: '/my-education/cv',
    breadcrumb: i18n('person.competence-report'),
  },
  cvEdit:{
    index: 8,
    path: '/my-education/profile/:personId/edit-cv/:cvId',
    breadcrumb: 'CV',
  },
  cvAdd:{
    index: 9,
    path: '/my-education/profile/:personId/add-cv',
    breadcrumb: 'CV',
  },
  messagesView: {
    index: 7,
    path: '/my-education/messages/:personId',
    active: '/my-education/messages',
    breadcrumb: i18n('message.my-messages'),
  },
  contentList:{
    index: 10,
    path: '/my-education/content/all/:parentContentId',
    active: '/my-education/content/all',
    breadcrumb: i18n('role.content'),
  },
  contentView:{
    index: 11,
    path: '/my-education/content/:contentId',
    active: '/my-education/content',
    breadcrumb: i18n('role.content'),
  },
  roleView: {
    index: 2,
    path: '/my-education/role/:roleId',
    active: '/my-education/role',
    exact: true,
    breadcrumb: 'Rolle',
  },
  roleCourseView: {
    index: 12,
    path: '/my-education/role/:roleId/:courseId',
    exact: true,
    active: '/my-education/role/:roleId/',
    breadcrumb: 'Kurs',
  },
  roleCompetenceView: {
    index: 6,
    path: '/my-education/role/:roleId/competence/:competenceId',
    active: '/my-education/role/:roleId/competence',
    breadcrumb: 'Kurs',
  },
};

export const atlas = {
  main: {
    index: 0,
    path: '/atlas/:id?',
    breadcrumb: 'Kart',
  },
};

export const content = {
  main: {
    index: 0,
    path: '/content/:contentId',
    active: '/content/:contentId',
    breadcrumb: '',
  },
};

export const nanoLearning = {
  main: {
    index: 0,
    path: '/nano-learning',
    active: '/nano-learning/',
    breadcrumb: 'Nano Learning',
  },
  player: {
    index: 0,
    path: '/nano-learning/:courseId',
    active: '/nano-learning/:courseId',
    breadcrumb: 'Nano Learning',
  },
};

export const learningPath = {
  main: {
    index: 0,
    path: '/learning-path/:id?',
    active: '/learning-path/',
    breadcrumb: 'Læringsløp oversikt',
  },
  track: {
    index: 1,
    path: '/learning-path/:id/:mapId?',
    active: '/learning-path/:id/:mapId?',
    breadcrumb: 'Læringsløp',
  },
  // map: {
  //   index: 2,
  //   path: '/learning-path/map/:id',
  //   active: '/learning-path/map/:id/',
  //   breadcrumb: 'Kart',
  // },
};

export const onboarding = {
  main: {
    index: 0,
    path: '/onboarding/:id?',
    active: '/onboarding/',
    breadcrumb: 'Onboarding',
  },
  track: {
    index: 1,
    path: '/onboarding/:id/:mapId?',
    active: '/onboarding/:id/:mapId?',
    breadcrumb: 'Læringsløp',
  },
  // map: {
  //   index: 2,
  //   path: '/onboarding/map/:id',
  //   active: '/onboarding/map/:id/',
  //   breadcrumb: 'Kart',
  // },
};

export const logout = '/logout';
