import axios from 'axios';
import dayjs from 'dayjs';
import {END, eventChannel} from 'redux-saga';
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as alertActions from '@actions/alert.actions';
import * as coursesActions from '@actions/courses.actions';
import {
  employeesFetchEvents,
  employeesFetchEventsWaitlist,
  employeesGet,
} from '@actions/employees.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {
  profileFetchPersonEvents,
  profileUpdateOneCompetences,
} from '@actions/profile.actions';
import {ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT} from '@actions/router.actions';
import {backendUrl, backendUrlV2, nanoLearningUrl} from '@config';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {
  getCompetencegroups as getCompetencegroupsSelector,
  getCompetencesSearchTerm,
  getGroupedCompetenceIds,
  getInitializeMyCoursesView,
  getNormalizedCompetenceDetails,
  getNormalizedCourseEvents,
  getSelectedCompetencegroupId,
  getSelectedCompetencetypes,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
} from '@selectors/courses.selectors';
import {
  getEmployeesEvents,
  getNormalizedEmployees,
  getNormalizedEmployeesEvents,
} from '@selectors/employees.selectors';
import {
  getNormalizedProfileEvents,
  getProfile,
  getProfileUserName,
  isManager as getIsManager,
} from '@selectors/profile.selectors';
import {parseEvent} from '@src/hooks/store/course/util';
import {getChildrenDataRecurse} from '@utils/misc.utils';
import {stringifyUrlParams} from '@utils/requests.utils';
import retry from '@utils/sagas.utils';
import * as T from '../../store/types/load.types';
import * as CT from '../types/competence';

const defaultFieldsCompetences = [
  'short_description',
  'files',
  'title',
  'modified',
  'course_type',
  'competence_type_id',
  'competence_type_key',
  'durations',
  'has_parents',
  'competence_type',
  'url',
  'category',
  'description',
  'content(image,imageAltText,teaserForListView)',
];

const defaultFieldsCmsContent = [
  'title',
  'image',
  'video',
  'imageCaption',
  'imageAltText',
  'authorText',
  'authorImage',
  'files',
  'teaserForListView',
  'category',
  'short_description',
  'description',
];

const CourseAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* getCompetenceById(action) {
  const {cid} = action.payload;

  const fields = defaultFieldsCompetences.join(',');

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const competence = yield retry(() =>
    CourseAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
      .then(response => response.data.competences));

  return {
    ...competence[0],
    cid: competence[0].id,
  };
}

function* waitForInitializeMyCoursesViewToFinish() {
  const initializeMyCoursesView = yield select(getInitializeMyCoursesView);

  if (initializeMyCoursesView.isFetching) {
    yield take([
      coursesActions.coursesInitializeMyCoursesViewSuccess,
      coursesActions.coursesInitializeMyCoursesViewFailure,
    ]);
  }
}

function* getCompetencegroupChildren(action) {
  const {competencegroupId} = action?.payload || {};

  const configObject = yield select(getConfigObject);
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);
  const allowChildCompetenceForGroupIds = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

  try {
    const types = 'complex,ecourse,equivalents,course,content,nano';

    const fields = (
      action && action.payload && action.payload.fields
      || defaultFieldsCompetences
    ).join(',');

    const baseCompetenceGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    const params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]': competencegroupId || baseCompetenceGroupId || '',
      'types[]': types,
    }));

    yield put(coursesActions.coursesGetCompetencegroupChildrenRequest({competencegroupId}));

    try {
      const limit = 100;

      const {competenceIds, data} = yield retry(() =>
        CourseAxios
          .request({
            method: 'GET',
            params: {
              children: '1',
              limit: limit + 1,
            },
            url: `${backendUrl}/api/competences/${params ? '?' + params : ''}`,
          })
          .then(response => {
            const {competences} = response.data;

            const ids = [];
            const data = {};

            if (!competences) return {
              competenceIds: ids,
              data,
            };

            const filtered = !!competencegroupId && !allowChildCompetenceForGroupIds.includes(competencegroupId)
              ? competences.filter(c => !c.has_parents)
              : competences;

            filtered.forEach(competence => {
              ids.push(competence.id);
              data[competence.id] = competence;
            });

            return {
              competenceIds: ids,
              data,
            };
          }));

      yield put(coursesActions.coursesGetCompetencegroupChildrenSuccess({
        ids: competenceIds,
        data,
        competencegroupId,
      }));
    } catch (error) {
      console.error(error);
      yield put(coursesActions.coursesGetCompetencegroupChildrenFailure({error}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* loadCompetencegroup(action) {
  const {competencegroupId, subCategoryIds} = action?.payload || {};

  if (!competencegroupId) return;

  const groupedCompetences = yield select(getGroupedCompetenceIds);

  const isLoaded = !!groupedCompetences?.data?.[competencegroupId];

  if (!isLoaded) {
    const action = {
      payload: {
        competencegroupId,
        subCategoryIds,
      },
    };

    try {
      yield put(coursesActions.coursesLoadCompetencegroupRequest({competencegroupId}));
      yield call(getCompetencegroupChildren, action);
      yield put(coursesActions.coursesLoadCompetencegroupSuccess({competencegroupId}));
    } catch (error) {
      console.error(error);
    }
  } else {
    yield put(coursesActions.coursesLoadCompetencegroupSuccess({
      competencegroupId,
      subCategoryIds,
    }));
  }
}

function* loadCompetencegroupsSubCategories(action) {
  const {parentGroupIds, subCategoryIds} = action?.payload || {};

  if (!parentGroupIds?.length && !subCategoryIds?.length) return;

  const groupedCompetences = yield select(getGroupedCompetenceIds);

  const groupsNotLoaded = groupedCompetences?.data
    ? [...parentGroupIds || [], ...subCategoryIds || []].filter(id => !groupedCompetences?.data?.[id])
    : [...parentGroupIds || [], ...subCategoryIds || []];

  if (groupsNotLoaded.length) {
    const mapToAction = competencegroupId => call(getCompetencegroupChildren, {payload: {competencegroupId}});

    try {
      yield put(coursesActions.coursesLoadCompetencegroupsSubCategoriesRequest({
        parentGroupIds,
        subCategoryIds,
      }));
      yield all(groupsNotLoaded.map(mapToAction));
      yield put(coursesActions.coursesLoadCompetencegroupsSubCategoriesSuccess({
        parentGroupIds,
        subCategoryIds,
      }));
    } catch (error) {
      console.error(error);
    }
  } else {
    yield put(coursesActions.coursesLoadCompetencegroupsSubCategoriesSuccess({
      parentGroupIds,
      subCategoryIds,
    }));
  }
}

function* getCompetencegroupsList(action) {
  yield put(coursesActions.coursesGetCompetencegroupsListRequest());

  const configObject = yield select(getConfigObject);
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

  const baseCompetenceGroupId = configObject?.isMapActivated
    ? !!configForCurrLangAndTrack?.courseCatalog?.startAtGroupId && configForCurrLangAndTrack.courseCatalog.startAtGroupId
    : configObject?.getProperty?.('routes.course-catalog.startAtGroupId');

  const disallowedGroupIds = configObject?.getProperty?.('routes.course-catalog.disalowwedCompetenceGroupIds') || [];

  try {
    const {
      data,
      ids: competencegroupIds,
      subCategoriesByGroupId,
    } = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/competencegroups${
          baseCompetenceGroupId
            ? `/${baseCompetenceGroupId}`
            : ''
        }`,
        params: {
          fields: 'color,title,files,children(title)',
          ...action?.payload?.params || {},
        },
      }).then(response => {
        const groups = response?.data?.competencegroups
          ? baseCompetenceGroupId
            ? response?.data?.competencegroups?.[0]?.children
            : response?.data?.competencegroups
          : {};

        if (!groups?.length) return {};

        const ids = [];
        const data = {};
        const subCategoriesByGroupId = {};

        groups.filter(({id}) => !disallowedGroupIds?.includes(id))?.forEach?.(group => {
          const {id: groupId} = group;

          ids.push(groupId);

          const subCategories = group?.children?.reduce(getChildrenDataRecurse, []);

          subCategoriesByGroupId[groupId] = subCategories;

          data[groupId] = {
            ...group,
            subCategoryIds: subCategories?.map(({id}) => id),
          };
        });

        return {
          ids,
          data,
          subCategoriesByGroupId,
        };
      }));

    yield put(coursesActions.coursesGetCompetencegroupsListSuccess({
      ids: competencegroupIds,
      data: data && data || [],
      subCategoriesByGroupId,
    }));

    if (competencegroupIds !== undefined) {
      yield call(loadCompetencegroup, {
        payload: {
          competencegroupId: competencegroupIds[0],
          subCategoryIds: subCategoriesByGroupId[competencegroupIds[0]],
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencegroupsListFailure({error}));
  }
}

function* getCompetences(action) {
  try {
    const searchTerm = yield select(getCompetencesSearchTerm) || null;

    yield searchTerm
      ? put(coursesActions.coursesUpdateSearchResultsRequest({}))
      : put(coursesActions.coursesUpdateSearchResultsSuccess({reset: true}));

    const selectedSubcompetencegroupId = yield select(getSelectedSubcompetencegroupId);

    const selectedSubSubcompetencegroupId = yield select(getSelectedSubSubcompetencegroupId);

    const selectedCompetencetypes = [
      ...yield select(getSelectedCompetencetypes),
    ];
    const selectedCompetencegroupId = [
      yield select(getSelectedCompetencegroupId),
    ];

    const notStartedByInitializeMyCoursesView
      = action
      && action.type !== coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW;

    if (notStartedByInitializeMyCoursesView) {
      // to prevent race conditions between this request and the one initialized by initialzie mycourses view

      yield waitForInitializeMyCoursesViewToFinish(action);
    }

    const configObject = yield select(getConfigObject);

    const types
      = selectedCompetencetypes.length && selectedCompetencetypes.join(',')
      || 'complex,ecourse,equivalents,course,content,nano';

    const fields = (
      action && action.payload && action.payload.fields
      || defaultFieldsCompetences
    ).join(',');

    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const baseCompetenceGroupId
      = configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.startAtGroupId;

    let params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
          selectedSubSubcompetencegroupId
          || selectedSubcompetencegroupId
          || selectedCompetencegroupId
          || baseCompetenceGroupId
          || '',
      'types[]': types,
    }));

    const disalowwedCompetenceGroupIds
      = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];
    const allowChildCompetenceForGroupIds
      = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

    if (params) {
      params = `?${params}`;
    }

    yield put(coursesActions.coursesGetCompetencesRequest());

    try {
      const limit = 100;

      const competences = yield searchTerm
        ? retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                term: searchTerm,
                not_course_groups: disalowwedCompetenceGroupIds.join(','),
              },
              url: `${backendUrlV2}/competences/search`,
            })
            .then(response => response.data))
        : retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                children: '1',
                limit: limit + 1,
              },
              url: `${backendUrl}/api/competences/${params}`,
            })
            .then(response => response.data.competences));

      let filter_out = null;

      filter_out = selectedCompetencegroupId
        && !allowChildCompetenceForGroupIds.includes(selectedCompetencegroupId[0])
        ? competences.filter(c => !c.has_parents)
        : competences;

      let hasMore = false;

      if (filter_out.length >= limit) {
        hasMore = true;
        filter_out = filter_out.slice(0, limit - 1);
      }
      yield put(coursesActions.coursesGetCompetencesSuccess({
        competences: filter_out,
        hasMore,
      }));

      if (searchTerm) {
        yield put(coursesActions.coursesUpdateSearchResultsSuccess({
          competences: filter_out,
          hasMore,
          searchTerm,
        }));
      }
    } catch (error) {
      console.error(error);
      yield put(coursesActions.coursesGetCompetencesFailure({error}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFeaturedCompetences(action) {
  const featuredCompetenceIdsToGet
    = action && action.payload && action.payload.cids;

  yield put(coursesActions.coursesGetFeaturedCompetencesRequest());
  try {
    const featuredCompetences = yield all(featuredCompetenceIdsToGet.map(cid =>
      call(getCompetenceById, {payload: {cid}})));

    yield put(coursesActions.coursesGetFeaturedCompetencesSuccess({featuredCompetences}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetFeaturedCompetencesFailure({error}));
  }
}

export function getCourseEventsAPI(payload) {
  const fields = [
    'short_description',
    'title',
    'startdate',
    'competence_type',
    'competence_id',
    'enddate',
    'available_count',
    'participants_count',
    'sign_on_deadline',
    'sign_off_deadline',
    'max_participants',
    'waitlist',
    'confirmed',
    'description',
    'confirmed(profile_image,fullname,firstname,lastname,user_name)',
    'waitlist',
    'person',
    'location',
  ].join(',');

  let params = '';

  params = payload.userName
    ? encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      waitlist: 1,
      confirmed: 1,
      user_name: payload.userName,
    }))
    : encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
    }));

  if (params) {
    params = `?${params}`;
  }
  let gourl = `${backendUrl}/api/events${params}`;

  if (payload.userName) {
    gourl = `${backendUrl}/api/personevents${params}`;
  }

  return CourseAxios
    .request({
      method: 'get',
      url: gourl,
      params: {limit: 100},
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    });
}

export function* updateCourseEvents(action) {
  const {events, partial} = action.payload;

  if (!events?.length) return;

  try {
    const allEventIds = [];

    const eventById = {};
    const eventIdsByCourseId = {};
    const eventIdsByYearMonth = {};

    events
      .map(parseEvent)
      .sort((a, b) => a.startDate - b.startDate)
      ?.forEach(event => {
        const {id, courseId} = event;

        if (!id || !courseId) {
          return;
        }

        allEventIds.push(id);
        eventById[id] = event;

        if (eventIdsByCourseId[courseId]) {
          eventIdsByCourseId[courseId].push(id);
        } else {
          eventIdsByCourseId[courseId] = [id];
        }
        const yearMonth = dayjs(event.startDate).format('YYYY-MM');

        if (eventIdsByYearMonth[yearMonth]) {
          eventIdsByYearMonth[yearMonth].push(id);
        } else {
          eventIdsByYearMonth[yearMonth] = [id];
        }
      });

    yield put(coursesActions.coursesUpdateEvents({
      ids: allEventIds,
      eventById,
      eventIdsByCourseId,
      eventIdsByYearMonth,
      partial,
    }));
  } catch (error) {
    console.error(error);
  }
}

function* getCourseEvents(action) {
  yield put(coursesActions.coursesGetCourseEventsRequest());

  try {
    const {events} = yield call(getCourseEventsAPI, {});

    yield call(updateCourseEvents, {payload: {events}});

    // temp to not break existing code
    const courseEvents = events
      .sort((a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf());

    yield put(coursesActions.coursesGetCourseEventsSuccess({courseEvents}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCourseEventsFailure({error}));
  }
}

function* getCompetencegroups(action) {
  yield put(coursesActions.coursesGetCompetencegroupsRequest());
  const selectedCompetencegroupId = yield select(getSelectedCompetencegroupId);
  const currCompetencegroups = yield select(getCompetencegroupsSelector);

  const configObject = yield select(getConfigObject);

  // get groups bellow this group
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

  let baseCompetenceGroupId = null;

  baseCompetenceGroupId = configObject.isMapActivated
    ? configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.startAtGroupId
    : configObject.getProperty('routes.course-catalog.startAtGroupId');

  const disalowwedCompetenceGroupIds
    = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];

  try {
    let competencegroups = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/competencegroups${
            (!selectedCompetencegroupId
              || currCompetencegroups.data === null)
            && baseCompetenceGroupId
              ? `/${baseCompetenceGroupId}`
              : ''
          }`,
        params: {
          fields: 'color,title,files,children(title)',
          ...action && action.payload && action.payload.params || {},
        },
      })
        .then(response => response.data.competencegroups));

    if (baseCompetenceGroupId) {
      competencegroups = competencegroups[0].children;
    }

    competencegroups
      = competencegroups
      && competencegroups.filter(({id}) => !disalowwedCompetenceGroupIds.includes(id));

    const configDefaultCompetenceGroup
      = configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.defaultSelectedCompetenceGroupId;

    const defaultSelectedCompetenceGroup = competencegroups.find(c => c.id === configDefaultCompetenceGroup);

    yield put(coursesActions.coursesGetCompetencegroupsSuccess({
      competencegroups,
      defaultSelectedCompetenceGroup: defaultSelectedCompetenceGroup
        ? defaultSelectedCompetenceGroup.id
        : null,
    }));
    if (action && action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(competencegroups);
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencegroupsFailure({error}));
  }
}

function* getCompetencetypes() {
  yield put(coursesActions.coursesGetCompetencetypesRequest());
  try {
    const competencetypes = yield retry(() =>
      CourseAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencetypes/`,
        })
        .then(response => response.data.competencetypes));

    yield put(coursesActions.coursesGetCompetencetypesSuccess({competencetypes}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencetypesFailure({error}));
  }
}

function* fetchMyCoursesViewData(action) {
  yield put(coursesActions.coursesInitializeMyCoursesViewRequest());
  /*
    yield put(coursesActions.coursesSetCompetencegroup({competenceGroupId: null}));
    yield put(coursesActions.coursesFiltersSetSubcompetencegroup({competenceGroupId: null}));
    yield put(coursesActions.coursesFiltersSetSubSubcompetencegroup({competenceGroupId: null}));
  */
  try {
    yield all([yield call(getCompetencegroups)]);
    yield call(getCompetences, action);
    const featuredCompetencesToGet
      = action && action.payload && action.payload.alsoGetFeaturedCompetences;

    if (featuredCompetencesToGet) {
      yield call(getFeaturedCompetences, {payload: featuredCompetencesToGet});
    }

    yield put(coursesActions.coursesInitializeMyCoursesViewSuccess());
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesInitializeMyCoursesViewFailure());
  }
}

function* fetchCompetenceDetails(action) {
  const {cid} = action.payload;

  yield put(coursesActions.coursesEndCourse());

  const fields = [
    'description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'competence_id',
    'competence_type_key',
    'durations',
    'children(competence_type,description,competence_type_key,competence_type_id,title,competence_id,files)',
  ].join(',');

  const fields_events = ['title', 'location'].join(',');

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const params_events = encodeURI(stringifyUrlParams({
    fields_events,
    view: 'full',
  }));

  yield put(coursesActions.coursesFetchCompetenceDetailsGetRequest({cid}));

  try {
    const {data: {competences: [competenceDetails]}} = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        params: {children: '1'},
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      }));

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
      */
      const {data: {events: eventsDetails}} = yield retry(() =>
        CourseAxios.request({
          method: 'GET',
          params: {children: '1'},
          url: `${backendUrl}/api/competences/${cid}/events?${params_events}`,
        }));

      yield call(updateCourseEvents, {
        payload: {
          events: eventsDetails,
          partial: true,
        },
      });

      competenceDetails.events = eventsDetails;

      /*
      # if manager, and course details */

      let userName = yield select(getProfileUserName);

      while (userName === null) {
        yield take();
        userName = yield select(getProfileUserName);
      }
      const isUserManager = yield select(getIsManager);

      if (isUserManager) {
        const userEvents = yield select(getEmployeesEvents);

        if (!userEvents) {
          yield put(employeesFetchEvents({}));
          yield put(employeesFetchEventsWaitlist({}));
        }

        const {status: employeesStatus} = yield select(getNormalizedEmployees);
        const employeesLoaded = employeesStatus === T.LoadStatuses.LOADED;

        if (!employeesLoaded) yield put(employeesGet({all: true}));
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (let i = 0; i < competenceDetails.children.length; i += 1) {
        competenceDetails.children[i].competence_type = {
          competence_type: competenceDetails.children[i].competence_type,
          competence_type_id: competenceDetails.children[i].competence_type_id,
        };
      }
    }
    yield put(coursesActions.coursesFetchCompetenceDetailsGetSuccess({
      competenceDetails,
      cid,
    }));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesFetchCompetenceDetailsGetFailure({error}));
  }
}

function* loadCompetenceDetails(action) {
  const {cid, refetch} = action.payload;

  if (!cid) return;

  yield put(coursesActions.coursesLoadCompetenceDetailsRequest());

  try {
    const {data: competenceById} = yield select(getNormalizedCompetenceDetails);

    if (!competenceById[cid] || refetch) {
      yield call(fetchCompetenceDetails, {payload: {cid}});
    }

    yield put(coursesActions.coursesLoadCompetenceDetailsSuccess({cid}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesFetchCompetenceDetailsGetFailure({error}));
  }
}

export const getIncludesPerson = (personsArray, person) => {
  if (!personsArray?.length || !person || !person?.id && !person?.user_name) return false;

  for (const element of personsArray) {
    const {id, user_name} = element || {};

    if (!id && !user_name) continue;
    if (id === person.id || user_name === person.user_name) return true;
  }

  return false;
};

export function* getIncludesProfile(personsArray) {
  if (!personsArray?.length) return false;

  const {data} = yield select(getProfile);
  const {id, user_name} = data || {};

  if (!id && !user_name) return false;

  return getIncludesPerson(personsArray, {
    id,
    user_name,
  });
}

function* courseEventsGetShouldRefetch(signedPersons) {
  if (!signedPersons?.length) return false;

  const includesSelf = yield call(getIncludesProfile, signedPersons);
  const refetchEmployeesEvents = signedPersons.length > 1 || !includesSelf;

  return {
    refetchCourseEvents: true,
    refetchProfileEvents: includesSelf,
    refetchEmployeesEvents,
  };
}

function* courseEventsRefetchIfNeeded(signedPersons) {
  const shouldRefetch = yield call(courseEventsGetShouldRefetch, signedPersons);

  if (!shouldRefetch) return;

  yield put(coursesActions.coursesLoadCourseEventsFull(shouldRefetch));
}

function* courseSignOn(action) {
  const {courseEvent, employees, onSuccess} = action.payload;

  const courseEventId = courseEvent.id;

  const {data: person} = yield select(getProfile);

  const signedEmployees = employees || [person];

  yield put(coursesActions.courseSignOnRequest({ceid: courseEventId}));

  try {
    const results = {
      correct: [],
      errors: [],
    };

    for (const signedEmployee of signedEmployees) {
      const {firstname, lastname, user_name} = signedEmployee;
      const fullname = `${firstname} ${lastname}`;
      const user = fullname.trim() !== '' ? fullname : user_name;

      yield CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/api/personevents/${courseEventId}`,
          params: {user_name},
          withCredentials: true,
        })
        .then(response => {
          results.correct.push({
            user,
            waitlist: response.data.personevents?.[0]?.waitlist,
            response,
          });
        })
        .catch(error => {
          console.error(error);
          results.errors.push({
            user,
            error,
          });

          return error;
        });
    };

    if (!employees) {
      let notification;

      if (results.correct && results.correct.length > 0) {
        notification = {
          text: results.correct[0].response.data.message,
          color: 'green',
        };
      } else if (results.errors && results.errors.length > 0) {
        notification = {
          title: 'Feil',
          text: results.errors[0].error.message,
          color: 'red',
        };
      }
      yield put(notificationsAdd({notification}));
    }
    yield put(coursesActions.courseSignOnResults({results}));
    yield put(coursesActions.courseSignOnSuccess());

    yield call(courseEventsRefetchIfNeeded, signedEmployees);

    if (onSuccess) onSuccess();
  } catch (error) {
    console.error(error);
    yield put(coursesActions.courseSignOnFailure({error}));
  }
}

function* courseSignOff(action) {
  const {courseEventId, employees, onSuccess} = action.payload;
  const {data: person} = yield select(getProfile);

  const signedEmployees = employees
    ? Array.isArray(employees) && employees || [employees]
    : [person];

  yield put(coursesActions.courseSignOffRequest({ceid: courseEventId}));

  try {
    const results = {
      correct: [],
      errors: [],
    };

    yield all(signedEmployees.map(({fullname, user_name}) => {
      const user = fullname && fullname.trim() !== '' ? fullname : user_name;

      return call(() =>
        CourseAxios
          .request({
            method: 'PUT',
            url: `${backendUrl}/api/personevents/${courseEventId}`,
            params: {
              action: 'off',
              user_name,
            },
            withCredentials: true,
          })
          .then(response => {
            results.correct.push({
              user,
              response,
            });

            return response;
          })
          .catch(error => {
            console.error(error);
            results.errors.push({
              user,
              error,
            });

            return error;
          }));
    }));

    let notification;

    if (results.correct && results.correct.length > 0) {
      notification = {
        text: results.correct[0].response.data.message,
        color: 'green',
      };
    } else if (results.errors && results.errors.length > 0) {
      notification = {
        title: 'Feil',
        text: results.errors[0].error.message,
        color: 'red',
      };
    }

    yield put(notificationsAdd({notification}));

    yield call(courseEventsRefetchIfNeeded, signedEmployees);

    if (onSuccess) onSuccess();

    yield put(coursesActions.courseSignOffSuccess());
  } catch (error) {
    console.error(error);
    yield put(coursesActions.courseSignOffFailure({error}));
  }
}

function* coursePreviewLoaded(action) {
  try {
    yield call(fetchCompetenceDetails, action);
  } catch (error) {
    console.error(error);
  }
  yield put(alertActions.actionClear());
}

function* onCourseStart(action) {
  try {
    const {cid, type} = action.payload;

    if (CT.CourseTypes.ecourse.includes(type)) {
      const {valid, lmsstarturls} = yield call(() =>
        axios
          .request({
            method: 'get',
            url: `${backendUrl}/api/lmsstarturls?competence_ids=${cid}&external=1`,
            withCredentials: true,
          })
          .then(response => response.data));

      const [{urls}] = lmsstarturls;

      if (valid === true && urls[0]) {
        // CLEAR THE INFO BOX.
        const open_url = `${urls[0].id}`;
        const win = window.open(open_url, '_blank');

        yield put(coursesActions.coursesLmsRunning({
          url: open_url,
          opened: !!win,
        }));
      } else {
        yield put(coursesActions.coursesLmsError());
      }
    } else if (CT.CourseTypes.nano.includes(type)) {
      const nanoCourseStartUrl = `${nanoLearningUrl}?id=${cid}`;

      yield put(coursesActions.coursesRunNanoCourse({
        url: nanoCourseStartUrl,
        cid,
        type,
      }));
    } else {
      const iframeMessageChannel = yield call(() =>
        eventChannel(emmiter => {
          window.addEventListener('message', ({data}) => {
            if (
              data === 'IFRAME_COURSE_FINISHED'
              || data === 'TAB_COURSE_FINISHED'
            ) {
              emmiter();
              emmiter(END);
            }
          });

          return () => {
            window.removeEventListener('message');
          };
        }));

      yield take(iframeMessageChannel);
      yield put(coursesActions.coursesCourseFinished({cid}));
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesCourseFailure({error}));
  }
}

function* onCourseSign(action) {
  const {courseId, password, checked} = action.payload;
  const bodyFormData = new FormData();

  bodyFormData.set('formIndex', '0');

  if (!checked) {
    bodyFormData.set('password', password);
  } else {
    bodyFormData.set('checked', '1');
  }

  let status;

  try {
    const data = yield call(() =>
      CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/courses/sign_digitally/${courseId}`,
          data: bodyFormData,
          config: {headers: {'Content-Type': 'multipart/form-data'}},
        })
        .then(response => response.data));

    ({status} = data);
    if (data.statuscode === -1) {
      yield put(coursesActions.coursesSignCourseSuccess({
        status: data.status,
        courseId,
      }));
      yield put(notificationsAdd({
        notification: {
          text: data.status,
          color: 'green',
        },
      }));
      yield put(profileUpdateOneCompetences());
      yield put(coursesActions.coursesBeginSignature(null));
    } else {
      if (status) {
        yield put(notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        }));
      }
      coursesActions.coursesSignCourseError();
    }
  } catch (error) {
    console.error(error);
    if (status) {
      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'red',
        },
      }));
    }
    coursesActions.coursesSignCourseError({error});
  }
}

export function* courseCatalogFetchNews(action) {
  yield put(coursesActions.fetchCourseCatalogNewsRequest());
  const {ids} = action.payload;

  try {
    const catalogNews = yield all(ids.map(id =>
      retry(() =>
        CourseAxios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/cms/${id}/pages?fields=teaser,body,image,category,author`,
          })
          .then(res => res.data))));

    yield put(coursesActions.fetchCourseCatalogNewsSuccess({data: catalogNews}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.fetchCourseCatalogNewsFailure({error}));
  }
}

export function* loadCourseEventsFull(action) {
  const {
    refetchCourseEvents,
    refetchEmployees,
    refetchEmployeesEvents,
    refetchProfileEvents,
  } = action?.payload || {};

  try {
    const {status: allEventsStatus} = yield select(getNormalizedCourseEvents);
    const allEventsLoaded = allEventsStatus === T.LoadStatuses.LOADED;

    if (!allEventsLoaded || refetchCourseEvents) {
      yield put(coursesActions.coursesGetCourseEvents());
    }

    const {status: profileEventsStatus} = yield select(getNormalizedProfileEvents);
    const profileEventsLoaded = profileEventsStatus === T.LoadStatuses.LOADED;

    if (!profileEventsLoaded || refetchProfileEvents) {
      yield put(profileFetchPersonEvents());
    }

    const isManager = yield select(getIsManager);

    if (isManager) {
      const {status: employeesEventsStatus} = yield select(getNormalizedEmployeesEvents);
      const {status: employeesStatus} = yield select(getNormalizedEmployees);

      const employeesEventsLoaded = employeesEventsStatus === T.LoadStatuses.LOADED;
      const employeesLoaded = employeesStatus === T.LoadStatuses.LOADED;

      const shouldFetchEmployees = !employeesLoaded || refetchEmployees;
      const shouldFetchEmployeesEvents = !employeesEventsLoaded || refetchEmployeesEvents;

      if (shouldFetchEmployees) {
        yield put(employeesGet({all: true}));
      }
      if (shouldFetchEmployeesEvents) {
        yield put(employeesFetchEvents({}));
      }
    }

    yield put(coursesActions.coursesLoadCourseEventsFullSuccess({}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesLoadCourseEventsFullFailure({error}));
  }
}

const exportObj = [
  takeLatest(coursesActions.COURSE_SIGNON, courseSignOn),
  takeLatest(coursesActions.COURSE_SIGNOFF, courseSignOff),
  takeLatest(coursesActions.COURSES_GET_COMPETENCES, getCompetences),
  takeLatest(
    coursesActions.COURSES_GET_FEATURED_COMPETENCES,
    getFeaturedCompetences,
  ),
  takeLatest(coursesActions.COURSES_GET_COMPETENCEGROUPS, getCompetencegroups),
  takeLatest(coursesActions.COURSES_GET_COMPETENCETYPES, getCompetencetypes),
  takeLatest(
    coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW,
    fetchMyCoursesViewData,
  ),
  takeLatest(coursesActions.COURSES_FILTERS_SET_FILTERS, getCompetences),
  debounce(700, coursesActions.COURSES_SET_SEARCHTERM, getCompetences),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_GET_COMPETENCEGROUP_CHILDREN,
    getCompetencegroupChildren,
  ),
  takeLatest(
    coursesActions.COURSES_LOAD_COMPETENCEGROUP,
    loadCompetencegroup,
  ),
  takeLatest(
    coursesActions.COURSES_LOAD_COMPETENCEGROUPS_SUBCATEGORIES,
    loadCompetencegroupsSubCategories,
  ),
  takeLatest(
    coursesActions.COURSES_GET_COMPETENCEGROUPS_LIST,
    getCompetencegroupsList,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE,
    getCompetences,
  ),
  takeLatest(coursesActions.COURSES_GET_COURSEEVENTS, getCourseEvents),
  takeLatest(
    ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT,
    coursePreviewLoaded,
  ),
  takeLatest(
    coursesActions.fetchCourseCatalogNews().type,
    courseCatalogFetchNews,
  ),
  takeLatest(coursesActions.coursesStartCourse().type, onCourseStart),
  takeEvery(coursesActions.COURSES_SIGN_COURSE, onCourseSign),
  takeLatest(
    coursesActions.COURSES_LOAD_COURSE_EVENTS_FULL,
    loadCourseEventsFull,
  ),
  takeLatest(coursesActions.COURSES_UPDATE_EVENTS, updateCourseEvents),
  takeLatest(coursesActions.COURSES_LOAD_COMPETENCE_DETAILS, loadCompetenceDetails),
];

export default exportObj;
