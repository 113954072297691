export const CourseTypes = {
  ecourse: ['ecourse'],
  course: ['course', 'elkemcourse'],
  digitalsignature: ['digital_signature', 'digitalsignature'],
  complex: ['complex'],
  equivalents: ['equivalents'],
  checklist: ['checklist'],
  checklist_item: ['checklist_item'],
  certificate: ['certificate'],
  verification: ['verification'],
  formal: ['formalcompetence'],
  selfsign: ['formalcompetence', 'verification', 'certificate'],
  nano: ['nano'],
  chat: ['chat'],
  track: ['track'],
};

export const CourseType = {
  ecourse: 'ecourse',
  course: 'course',
  certificate: 'certificate',
  digitalsignature: 'digitalsignature',
  complex: 'complex',
  verification: 'verification',
  equivalents: 'equivalents',
  checklist: 'checklist',
  checklist_item: 'checklist_item',
  nano: 'nano',
  chat: 'chat',
  track: 'track',
};

