import {getConfigObject} from '@selectors/config.selectors';

export const getSelectedMapCourse = state => state.map.selectedMapCourse.data;

export const getMapCourses = state => state.map.mapCourses;

// given a course-title like this This is a title :param1: :param2:
// return a list of the params like this ["param1","param2"]
export const getCourseTitleParams = courseTitle => {
  const eatFirstAndLastChar = str => str.slice(1, -1);

  if (courseTitle === undefined) {
    return [];
  }
  const params = courseTitle.match(/:[^:]+?:/g);

  if (params) {
    return params.map(p => eatFirstAndLastChar(p).toLowerCase());
  }

  return [];
};

export const getMapOutro = state => {
  const dotts
    = state.map.mapCourses.data && state.map.mapCourses.data.tracks[0].dotts;

  if (dotts === null) {
    return null;
  }

  return dotts.find(t => getCourseTitleParams(t.title).includes('outro'));
};


export const getMapVerification = state => {
  const dotts
    = state.map.mapCourses.data && state.map.mapCourses.data.tracks[0].dotts;

  if (dotts === null) {
    return null;
  }

  return dotts.find(t =>
    getCourseTitleParams(t.title).includes('verification'));
};


// get courses in map, with outro and verification filtered out if exists
export const getMapDotts = state => {
  const dotts
    = state.map.mapCourses.data && state.map.mapCourses.data.tracks[0].dotts;

  if (dotts === null) {
    return null;
  }

  // if the course title contains any of the marker parameters verification or outro, we dont want to show them in the map
  return dotts.filter(d =>
    !['verification', 'outro'].some(hideCourseWithParam =>
      getCourseTitleParams(d.title).includes(hideCourseWithParam)));
};

export const getIsAllMapDotsCompleted = state => {
  const dotsShownInMap = getMapDotts(state);

  if (!dotsShownInMap) {
    return null;
  }

  return (
    dotsShownInMap && dotsShownInMap.every(dott => dott.status === 'DONE')
  );
};

export const getIsMapVerified = state => {
  const doneStatus
    = state.map && state.map.mapCourses && state.map.mapCourses.mapIsVerified;

  if (doneStatus === null) {
    return null;
  }

  return doneStatus;
};

export const getIsFirstMapDotCompleted = state => {
  const tracks = state.map.mapCourses.data && state.map.mapCourses.data.tracks;

  return tracks && tracks[0].dotts[0]?.status === 'DONE';
};

export const getOutroIsCompleted = state => {
  const doneStatus
    = state.map && state.map.mapCourses && state.map.mapCourses.outroIsDone;

  if (doneStatus === null) {
    return null;
  }

  return doneStatus;
};

// return value:
// true if the entire map is completed(including possible outro and/or verification)
// false if the map is not completed
// null if we dont know yet(we are still waiting for the answer)

export const getIsMapCompleted = state => {
  // if we use map, there are 3 possible ways to detect if the map is completed or not
  // 1. if we have no outro and no verification -> all dots are completed
  // 2. if we have both outro AND verification -> the verification is complete
  // 3. if we have either outro OR verification (but not both) -> the outro or the verification is complete
  const useAtlas = getConfigObject(state).isMapActivated;

  if ([undefined, false].includes(useAtlas)) {
    return true;
  }
  const mapVerification = getMapVerification(state);
  const mapOutro = getMapOutro(state);
  const allDotsInMapCompleted = getIsAllMapDotsCompleted(state);

  const waiting = mapVerification === null || mapOutro === null;

  if (waiting) {
    return null;
  }

  // unncomment the following to disable the map
  // return true;

  const outroIsDone = getOutroIsCompleted(state);
  const verifyIsDone = getIsMapVerified(state);

  if (!mapVerification && !mapOutro) {
    return allDotsInMapCompleted === null ? null : allDotsInMapCompleted;
  } else if (mapVerification && mapOutro) {
    return outroIsDone === null ? null : outroIsDone;
  } else if (mapVerification) {
    return verifyIsDone === null ? null : verifyIsDone;
  } else if (mapOutro) {
    return outroIsDone === null ? null : outroIsDone;
  } else {
    return null;
  }
};

export const a = 123;

export const getInitializeMyCoursesView = state => state.map.initializeMyCoursesView;

export const getMapVerificationCompetence = state => state.map.mapVerificationCompetence;
