import styled from '@emotion/styled';
import {baseStyles} from './mixins/base-styles';
import {selectors} from './selectors';
import {buildMediaQueriesString} from './mixins/utils';

// base is for screens larger than largest breakpoint
const styles = {
  [selectors.global.container]: {fontSize: {tablet: '.97rem'}},
  [selectors.global.pageSection]: {
    marginTop: {
      base: '5rem',
      tablet: '4rem',
      mobileXs: '3rem',
    },
  },
  [selectors.global.paddedPageSection]: {
    padding: {
      base: '1.5em',
      tablet: '1em',
    },
  },
  [selectors.global.heading]: {fontSize: {tablet: '1.75rem'}},
  [selectors.global.subHeading]: {
    fontSize: {
      tablet: '.97rem',
      mobileSm: '.95rem',
    },
    paddingTop: {
      tablet: '1em',
      mobileSm: '0',
    },
    paddingBottom: {
      tablet: '1em',
      mobileSm: '1em',
    },
  },
  [selectors.header.wrapper]: {
    paddingBottom: {
      tablet: '1.75em',
      mobile: '1.5em',
    },
  },
  [selectors.header.top]: {
    paddingBottom: {
      tablet: '1em',
      mobile: '.75em',
    },
  },
  [selectors.header.backButton]: {
    height: {mobile: '2.75rem'},
    width: {mobile: '2.75rem'},
  },
  [selectors.header.title]: {
    fontSize: {
      mobileSm: '1.25rem',
      mobileXs: '1.167rem',
    },
  },
  [selectors.utils.smallerHeaderTitle]: {
    fontSize: {
      tablet: '1.75rem',
      mobile: '1.4rem',
    },
  },
};

const mediaQueries = buildMediaQueriesString(styles);

export const ResponsiveStyles = styled.div`
    ${baseStyles}
    ${mediaQueries}
`;
