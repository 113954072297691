/* eslint-disable react/jsx-fragments */
import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {i18n} from '@src/i18n';
import SearchBar from '../search-bar/search-bar';
import {appTitle} from '@config';
import {connect, useSelector} from 'react-redux';
import {
  getProfileId,
  getOrganisationId,
  getMessageUnreadCount,
} from '@selectors/profile.selectors';
import {getAccess} from '@utils/misc.utils';
import {getRouteWithParams} from '@utils/routes.utils';
import {css} from '@emotion/react';
import {
  courseCatalog, dashboard,
  employees as employeeRoutes,
  myEducation,
} from '@routes/routes.manifest';
import {withRouter, matchPath} from 'react-router';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import DesktopMenu from './desktop-menu/desktop-menu';
import {MenuTwo} from '@components/menu/menu-two/MenuTwo';
import {useHistory} from 'react-router-dom';
import {getWorklistSelector} from '@selectors/employees.selectors';

export const getColorConfigForGroup = ({
  courseGroupId,
  pathname,
  isMobile,
  configObject,
  forMenu,
}) => {
  const defaultColor
    = configObject.getProperty(`params.menu.${isMobile ? 'baseColorMobile' : 'baseColorDesktop'}`) || 'rgb(1, 0, 49)';

  if (forMenu) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  if (!isMobile) {
    return {
      color: configObject.getProperty('params.menu.baseColorDesktop'),
      createGradient: false,
    };
  }
  const noActiveCourseGroup
    = isMobile && pathname === '/course-catalog'
    || !isMobile && !courseGroupId
    || pathname === '/atlas';

  if (noActiveCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  return {
    color:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.baseColor`) || defaultColor,
    createGradient:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.gradient`) || false,
  };
};

const getMenuItems = ({
  configObject,
  access,
  history,
  orgId,
  isManager,
  showDashboard,
  location,
  worklistCount,
}) => {
  if (access.learingportal) {
    const baseTitle
      = configObject.getProperty('params.default-title-key')
      && i18n(configObject.getProperty('params.default-title-key'));

    const accessLevel = configObject.getProperty('params.menu.access');
    const menuLinksManager = [
      {
        name: i18n('menu.my-page'),
        title: i18n('menu.my-page'),
        label: i18n('menu.my-page'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.main.path, {}));
        },
        type: 'featureLink',
        attach: ['mobile'],
        path: '/my-education',
        active: matchPath(location.pathname, {
          path: '/my-education',
          exact: false,
        }),
      },
      {
        name: i18n('menu.course-catalog'),
        label: i18n('menu.course-catalog'),
        title: `${i18n('menu.course-catalog')}${
          baseTitle && ` - ${baseTitle}` || ''
        }`,
        onClick: () => {
          history.push(getRouteWithParams(courseCatalog.main.path, {}));
        },
        path: '/course-catalog',
        type: 'featureLink',
        attach: ['mobile'],
        active: matchPath(location.pathname, {
          path: '/course-catalog',
          exact: false,
        }),
      },
      {
        name: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        label: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        title: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(employeeRoutes.employees, {orgId}));
        },
        path: getRouteWithParams(employeeRoutes.employees, {orgId}),
        active: !location.pathname.includes('worklist') && matchPath(location.pathname, {
          path: '/employees',
          exact: false,
        }),
      },
    ];

    if (worklistCount && worklistCount > 0) {
      menuLinksManager.push({
        name: `${i18n('menu.worklist')} (${worklistCount})`,
        label: i18n('menu.worklist'),
        title: i18n('menu.worklist'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(employeeRoutes.worklist, {orgId}));
        },
        path: getRouteWithParams(employeeRoutes.worklist, {orgId}),
        active: location.pathname.includes('worklist'),
      });
    }

    if (showDashboard) {
      menuLinksManager.push({
        name: i18n('menu.dashboard'),
        label: i18n('menu.dashboard'),
        title: i18n('menu.dashboard'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(dashboard.search, {orgId}));
        },
        path: getRouteWithParams(dashboard.search, {orgId}),
        active: matchPath(location.pathname, {
          path: '/dashboard/',
          exact: false,
        }),
      });
    }

    const menuLinksEmployee = [
      {
        name: i18n('menu.my-page'),
        title: i18n('menu.my-page'),
        label: i18n('menu.my-page'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.main.path, {}));
        },
        type: 'featureLink',
        attach: ['mobile'],
        path: '/my-education',
        active: matchPath(location.pathname, {
          path: '/my-education',
          exact: false,
        }),
      },
      {
        name: i18n('menu.course-catalog'),
        label: i18n('menu.course-catalog'),
        title: `${i18n('menu.course-catalog')}${
          baseTitle && ` - ${baseTitle}` || ''
        }`,
        onClick: () => {
          history.push(getRouteWithParams(courseCatalog.main.path, {}));
        },
        path: '/course-catalog',
        type: 'featureLink',
        attach: ['mobile'],
        active: matchPath(location.pathname, {
          path: '/course-catalog',
          exact: false,
        }),
      },
    ];

    if (access.userAccess !== 'crew') {
      let menuData = [];

      if (access.userAccess === 'superuser' || access.userAccess === 'admin') {
        menuData = configObject.getProperty('params.menu.data');
      }else if (new Date() === false) {
        menuData.push({
          label: i18n('menu.admin'),
          type: 'menu',
          items: [
            {
              label: i18n('menu.administration'),
              onClick: () => window.location = '/admin',
              type: 'title',
            },
            {
              type: 'separator',
              margin: ['0', '0', '20px', '0'],
            },
          ],
        });
        /* CUSTOMER HAS GATHERINGS */
        menuData[0].items.push({
          label: i18n('menu.roles'),
          subLabel: i18n('menu.roles-text'),
          onClick: () => window.location = '/manage/roles',
          type: 'featureLink',
        });
      }

      return isManager && {
        menuLinks: menuLinksManager,
        adminLinks: menuData,
      } || {menuLinks: menuLinksEmployee};
    }

    return isManager && {
      menuLinks: menuLinksManager,
      adminLinks: [],
    } || {menuLinks: menuLinksEmployee};
  }else {
    const menuData = [{
      label: i18n('menu.admin'),
      type: 'menu',
      items: [
        {
          label: i18n('menu.administration'),
          onClick: () => window.location = '/admin',
          type: 'title',
        },
        {
          type: 'separator',
          margin: ['0', '0', '20px', '0'],
        },
      ],
    }];

    /* CUSTOMER HAS ONBOARDING */
    access.onboarding && menuData[0].items.push({
      label: i18n('menu.onboarding'),
      subLabel: i18n('menu.my-onboarding-text'),
      onClick: () => window.location = '/admin/onboardings',
      type: 'featureLink',
    });

    /* CUSTOMER HAS LEARNINGPATHS */
    access.learning && menuData[0].items.push({
      label: i18n('menu.maps'),
      subLabel: i18n('menu.my-maps-text'),
      onClick: () => window.location = '/admin/maps',
      type: 'featureLink',
    });

    /* CUSTOMER HAS GATHERINGS */
    access.gathering && menuData[0].items.push({
      label: i18n('menu.gathering'),
      subLabel: i18n('menu.my-gathering-text'),
      onClick: () => window.location = '/admin/gathering',
      type: 'featureLink',
    });

    /* IF YOU HAVE GATHERING OR LEARNING PATH ADD RESOURCES */
    Boolean(access.gathering || access.learning) && menuData[0].items.push({
      label: i18n('menu.resource-pages'),
      subLabel: i18n('menu.resource-pages-text'),
      onClick: () => window.location = '/admin/resources',
      type: 'featureLink',
    });

    menuData[0].items.push({
      label: i18n('menu.persons'),
      subLabel: i18n('menu.persons-text'),
      onClick: () => window.location = '/admin/persons',
      type: 'featureLink',
    });

    return {
      menuLinks: [],
      adminLinks: menuData,
    };
  }
};

const Menu = ({
  onLogoutClick,
  isManager,
  isMobile,
  orgId,
  personId,
  profileData,
  messagesCount,
  location,
}) => {
  const history = useHistory();
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  const configObject = useSelector(getConfigObject);
  const menuConfig = configObject.getProperty('params.menu') || {};
  const selectedCourseGroupId = useSelector(getSelectedCompetencegroupId);
  const isMobileCourseCatalog = useSelector(getIsMobileCourseCatalog);


  const access = useMemo(
    () =>
      getAccess(configObject.getProperty('params.customerType') && configObject.getProperty('params.customerType').split('') || ['2', '10'], configObject.getProperty('params.access'))
    , [configObject],
  );

  const showDashboard  = configObject.isModuleEnabled('reports');
  const useMap = configObject.isMapActivated;

  const {ids: worklistIds} = useSelector(getWorklistSelector);
  const worklistCount = worklistIds.length;

  // const menuLinks = useMap ? null : isManager ? menuLinksManager : menuLinksEmployee;

  const {menuLinks, adminLinks} = useMemo(() => getMenuItems({
    configObject,
    history,
    access,
    isManager,
    orgId,
    showDashboard,
    location,
    worklistCount,
  }), [
    access,
    configObject,
    history,
    orgId,
    location,
    showDashboard,
    isManager,
    worklistCount,
  ]);

  const setDocumentTitle = links =>
    links
      && links.map(({title, active, children}) => {
        if (active && title) {
          document.title = title + (appTitle ? ` - ${appTitle}` : '');
        }

        return setDocumentTitle(children);
      })
    ;

  // setDocumentTitle(menuLinksManager);

  const colorConfigForGroup = getColorConfigForGroup({
    courseGroupId: selectedCourseGroupId,
    pathname: location.pathname,
    isMobile: isMobileCourseCatalog,
    configObject,
    forMenu: true,
  });

  const enableEditProfile = configObject.getProperty('routes.profile.enable');

  return (
    <div
      css={css`
        display: contents;
      `}
    >
      {menuConfig && menuConfig.type === 'menu-two' && (
        <MenuTwo
          onLogoutClick={onLogoutClick}
          menuConfig={menuConfig}
          variant={isMobile ? 'hamburger' : 'horizontal'}
          createGradient={colorConfigForGroup.createGradient}
          baseColor={colorConfigForGroup.color}
        />
      ) || menuConfig && menuConfig.type === 'menu-default' && useMap && (
        <DesktopMenu
          orgId={orgId}
          history={history}
          isMobile={isMobile}
          data={{
            config: {
              align: 'center',
              width: 1500,
              height: '70px',
              primaryColor: menuConfig.color,
              dropdownWidth: 800,
              dropdownAlign: 'center',
            },
            buttons: [{
              label: i18n('menu.logout'),
              onClick: onLogoutClick,
              icon: 'fas door-open',
              type: 'simpleButton',
            }],
            logo: {src: menuConfig.logo},
            items: menuConfig.access && menuConfig.access >= 2
              ? adminLinks
              : null,
          }}
          menuLinks={null}
          onLogoutClick={onLogoutClick}
        />
      ) || (
        <DesktopMenu
          orgId={orgId}
          isMobile={isMobile}
          data={!profileData
            ? {config:{primaryColor: '#f0f0f0'}}
            : {
              config: {
                height: '70px',
                primaryColor: menuConfig.color,
              },
              search: isManager
                ? {
                  placeholder: i18n('globals.search'),
                  search: (searchTerm, context) => ({
                    result: <SearchBar
                      term={searchTerm}
                      showMenu={context.closeMenu}
                    />,
                  }),
                }
                : null,
              logo: {
                src: menuConfig.logo && menuConfig.logo || '/images/snapper.svg',
                href: '/',
              },
              items: isMobile ? menuLinks : adminLinks,
              profile: {
                displayName: profileData.fullname,
                notification: messagesCount,
                items: [
                  {
                    label: i18n('person.menu.competences'),
                    subLabel: i18n('person.menu.competencesTxt'),
                    onClick: () => {
                      history.push(getRouteWithParams(myEducation.cvView.path, {personId: profileData.id}));
                    },
                    type: 'featureLink',
                  },
                  ...enableEditProfile
                    ? [{
                      label: i18n('person.menu.profile'),
                      subLabel: i18n('person.menu.profileTxt'),
                      onClick: () => {
                        history.push(getRouteWithParams(myEducation.profileView.path, {personId: profileData.id}));
                      },
                      type: 'featureLink',
                    }]
                    : [],
                  {
                    label: i18n('person.menu.messages'),
                    subLabel: messagesCount && i18n('person.menu.messagesTxtUnread', {functionArgs: {x: messagesCount}}) || i18n('person.menu.messagesTxt'),
                    onClick: () => {
                      history.push(getRouteWithParams(myEducation.messagesView.path, {personId: profileData.id}));
                    },
                    type: 'featureLink',
                  },
                  {type: 'separator'},
                  {
                    label: i18n('person.menu.logout'),
                    subLabel: i18n('person.menu.logoutTxt'),
                    onClick: () => {
                      history.push('/logout');
                    },
                    type: 'featureLink',
                  },
                ],
              },
            }}
          menuLinks={menuLinks}
          onLogoutClick={onLogoutClick}
        />
      )}
    </div>
  );
};

Menu.defaultProps = {
  personId: -1,
  orgId: -1,
  messagesCount: 0,
};

Menu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  profileData: PropTypes.shape({}),
  orgId: PropTypes.number,
  personId: PropTypes.number,
  messagesCount: PropTypes.number,
  location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
};

Menu.defaultProps = {profileData: null};

const mapStateToProps = state => ({
  orgId: getOrganisationId(state),
  personId: getProfileId(state),
  messagesCount: getMessageUnreadCount(state),
  isMobile: getIsMobile(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
