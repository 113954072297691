import {put, select, takeLatest} from 'redux-saga/effects';
import * as componentsActions from '@actions/components.actions';
import {getCourseCatalogComponentState} from '@selectors/components.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {defaultCourseCatalogLayout, initLayoutConfig} from '../util/components.util';

function* initCourseCatalogLayout() {
  yield put(componentsActions.setCourseCatalogLayoutRequest());

  try {
    const configObject = yield select(getConfigObject);

    const layoutConfig = configObject.getProperty('routes.course-catalog.layout') || defaultCourseCatalogLayout;

    const config = initLayoutConfig(layoutConfig);

    yield put(componentsActions.setCourseCatalogLayoutSuccess({config}));
  } catch (error) {
    yield put(componentsActions.setCourseCatalogLayoutFailure(error));
  }
}

function* courseCatalogSetActiveView(action) {
  const {activeView} = action.payload;
  const {activeView: prevActiveView} = yield select(getCourseCatalogComponentState);

  if (activeView !== prevActiveView) {
    yield put(componentsActions.setCourseCatalogComponentsState({activeView}));
  }
}

const exportObj = [
  takeLatest(componentsActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT, initCourseCatalogLayout),
  takeLatest(componentsActions.COMPONENTS_SET_COURSE_CATALOG_ACTIVE_VIEW, courseCatalogSetActiveView),
];

export default exportObj;
