import axios from 'axios';
import dayjs from 'dayjs';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as alertAction from '@actions/alert.actions';
import {AUTH_LOGIN_SUCCESS, authLoginSuccess, authUnauthorized} from '@actions/auth.actions';
import {CONFIG_GET_CONFIG_SUCCESS} from '@actions/config.actions';
import {
  COURSES_COURSE_FINISHED,
  coursesEndCourse,
} from '@actions/courses.actions';
import {
  employeesFetchSelectedPersonCompetencesChildrenFailure,
  employeesFetchSelectedPersonCompetencesChildrenRequest,
  employeesFetchSelectedPersonCompetencesSuccess,
} from '@actions/employees.actions';
import {
  selectMapCourse,
  setMapIsNotVerified,
  setMapIsVerified,
  setOutroIsCompleted,
  setOutroIsNotCompleted,
} from '@actions/map.actions';
import * as mapCoursesActions from '@actions/map.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {profileFetchPersonEventsSuccess} from '@actions/profile.actions';
import * as PA from '@actions/profile.actions';
import {fetchRole} from '@actions/roles.actions';
import {
  ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
  routerEmployeesListDidMount,
} from '@actions/router.actions';
import {backendUrl, backendUrlV2} from '@config';
import {getCourseEventsAPI} from '@sagas/courses.sagas';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';
import {
  getIsAllMapDotsCompleted,
  getMapCourses,
  getMapOutro,
  getMapVerification,
} from '@selectors/map.selectors';
import {
  getCompetences,
  getOrganisationId,
  getPassed,
  getPassedCompetences,
  getPassedCompetencesIds,
  getPassedIds,
  getProfile,
  getProfileId,
  getProfileUserName,
  getRoles,
  getSelectedRoleId,
  getSelfSign,
  getShowRoles,
} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {CourseType} from '@types/competence';
import * as T from '@types/load.types';
import {stringifyUrlParams} from '@utils/requests.utils';
import retry from '@utils/sagas.utils';
import {getSelectedPerson} from '../selectors/employees.selectors';


const ProfileAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

const delay = ms => new Promise(res => setTimeout(res, ms));

function* updateActiveOrgId(action) {
  const orgId = action.payload;

  localStorage.setItem('orgId', orgId);

  yield put(PA.setActiveOrgId(orgId));
}

export function* fetchAllOrganisations() {
  yield put(PA.profileFetchAllOrganisations());
  try {
    const data = yield call(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/allMyOrganisations`,
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(PA.profileFetchAllOrganisationsSuccess({data}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchAllOrganisationsFailure({error}));
  }
}

function* createSelfSign(action) {
  const {formData, callback} = action.payload;

  // eslint-disable-next-line no-extend-native
  Date.prototype.toJSON = function () {
    return dayjs(this).format('YYYY-MM-DDThh:mm:00.000Z');
  };

  try {
    const data = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        url: `${backendUrlV2}/personcompetences/`,
        data: JSON.stringify(formData),
        withCredentials: true,
      }).then(response => response.data));

    if (formData.files && Object.keys(formData.files).length !== 0) {
      yield all(Object.keys(formData.files).map(file => call(() => {
        if (formData['files'][file].length !== 0) {
          const formPost = new FormData();

          formPost.append('file', formData['files'][file][0]);
          formPost.append('timestamp', Math.round(Date.now() / 1000));

          return ProfileAxios.request({
            method: 'POST',
            url: `${backendUrl}/files/savemanagefiles/person_has_course_event/${data.id}`,
            data: formPost,
            withCredentials: true,
          }).then(response => response.data);
        }
      })));
    }
    yield put(notificationsAdd({
      notification: {
        color: 'green',
        text: data.passed === 100 && i18n('person.registered-competence-passed') || i18n('person.registered-competence'),
      },
    }));
    yield put(PA.selfSignProfile({reload: true}));
    const profile = yield select(getProfile);

    if (data.passed === 100) {
      yield window.location.href.includes('/role') ? put(PA.profileFetchPersonSummary()) : put(PA.fetchReport());
    }

    yield put(PA.updateOneCompetence({
      cid: formData.competenceId,
      userName: profile.user_name,
    }));

    if (callback) {
      callback();
    };
  }catch (error) {
    console.log(error);
  };
}

function* fetchPerson() {
  yield put(PA.profileFetchPersonRequest());
  try {
    const {profile, competencelevel} = yield all({
      profile: retry(() =>
        ProfileAxios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/person`,
            params: {
              fields:
                'person_id,firstname,lastname,profile_image,email,mobile,fullname,user_name,roles(title,role_type_role_type,organisation_id),positions(organisation_id,title,role_type_role_type),organisations(organisation_id,extern_organisation_id,id,title,brand_id),data(avatar,last_message)',
            },
            withCredentials: true,
          })
          .then(response => response.data.persons[0])),
      competencelevel: retry(() =>
        ProfileAxios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/competencelevel`,
            withCredentials: true,
          })
          .then(response => response.data.competencelevel[0].level)),
    });

    const cheif_names = new Set([
      'employeemanager',
      'admin',
      'manager',
      'superadmin',
      'superuser',
    ]);

    let isCheif = false;
    let pos = null;

    if (Array.isArray(profile.positions)) {
      pos = profile.positions.some(p => cheif_names.has(p.role_type_role_type));
      isCheif = pos;
    }

    if (!isCheif && Array.isArray(profile.roles)) {
      pos = profile.roles.some(p => cheif_names.has(p.role_type_role_type));
      isCheif = pos;
    }

    if (isCheif) {
      /*
       * IF WE ARE CHEIF, CHECK ALL ORGS AND REMOVE ANY THAT ARE NOT CHEIF.
       *
       * */
      if (profile.positions.length >= 1) {
        const rpos = profile.positions.filter(p =>
          cheif_names.has(p.role_type_role_type));

        if (rpos.length !== 0 && !localStorage.getItem('orgId')) {
          localStorage.setItem('orgId', rpos[0].organisation_id);
        }
      }

      /*
      *  IF USER IS NOT LEADER FOR ITS ORG, CHECK ITS ROLES.
      * */
      if (!localStorage.getItem('orgId')) {
        const rrpos = profile.roles.filter(p =>
          cheif_names.has(p.role_type_role_type));

        console.log(rrpos[0]);

        if (rrpos.length !== 0 && !localStorage.getItem('orgId')) {
          localStorage.setItem('orgId', profile.positions[0].organisation_id);
        }
      }

      yield put(PA.profileSetManager());
    }

    profile.competencelevel = competencelevel;
    yield put(PA.profileFetchPersonSuccess({
      person: profile,
      positions: profile.positions,
      competencelevel,
    }));

    const superuser
      = !!profile.roles.filter(p => p.role_type_role_type === 'superuser').length || false;

    yield put(PA.profileSetSpecialroles({
      data: null,
      superuser,
    }));
  } catch (error) {
    console.error(error);
    yield put(authUnauthorized({error}));
    yield put(PA.profileFetchPersonFailure({error}));
  }
}

function* fetchCompetencesChildrenAPI(payload) {
  const {courseId, root, isOtherCompetences} = payload.payload;
  let profile = null;
  const orgId = yield select(getOrganisationId);

  switch (root) {
  case 'show-role': {
    profile = yield select(getProfile);
    yield put(PA.fetchShowRoleChildrenRequest());

    break;
  }
  case 'employee-activites': {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());

    break;
  }
  case 'show-employee': {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());

    break;
  }
  default: {
    profile = yield select(getProfile);
    yield put(PA.fetchCompetencesChildrenRequest());
  }
  }

  const personId = profile.data.id;

  let competences = [];

  switch (root) {
  case 'show-role': {
    competences = yield select(getShowRoles);
    if (competences.data) {
      competences = competences.data;
    }

    break;
  }
  case 'employee-activites': {
    competences = yield select(getSelectedPerson);

    break;
  }
  case 'show-employee': {
    competences = yield select(getSelectedPerson);

    break;
  }
  default: {
    competences = yield select(getCompetences);
  }
  }

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    switch (root) {
    case 'show-role': {
      for (let i = 0; i < competences.requirements.length; i += 1) {
        if (competences.requirements[i].competence_id === courseId) {
          competences.requirements[i].competence.children = data;
        }
      }
      yield put(PA.fetchShowRoleChildrenSuccess({competences}));

      break;
    }
    case 'employee-activites': {
      for (
        let i = 0;
        i < competences.data.summary.activities.children.length;
        i += 1
      ) {
        if (
          competences.data.summary.activities.children[i].competence_id
          === courseId
        ) {
          competences.data.summary.activities.children[i].children = data;
          break;
        }
      }
      const new_data = competences.data;

      yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));

      break;
    }
    case 'show-employee': {
      const targetCompetences = competences.data.summary[isOtherCompetences ? 'other' : 'requirement'];

      for (const targetCompetence of targetCompetences) {
        for (
          let ii = 0;
          ii < targetCompetence.children.length;
          ii += 1
        ) {
          if (
            targetCompetence.children[ii]
              .competence_id === courseId
          ) {
            targetCompetence.children[
              ii
            ].children = data;
          }
        }
      }
      const new_data = competences.data;

      yield put(employeesFetchSelectedPersonCompetencesSuccess({data: new_data}));

      break;
    }
    default: {
      for (const competence of competences) {
        if (competence.competence_id === courseId) {
          if (root === 'show-role') {
            competence.competence.children = data;
          } else {
            competence.children = data;
          }
        }
      }
      yield put(PA.fetchCompetencesChildrenSuccess({competences}));
    }
    }
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    console.error(error);
    if (root === 'show-role') {
      yield put(PA.fetchShowRoleChildrenFailure({error}));
    } else if (root === 'show-employee') {
      yield put(employeesFetchSelectedPersonCompetencesChildrenFailure({error}));
    } else {
      yield put(PA.fetchCompetencesChildrenFailure({error}));
    }
  }
}


/*
 *
 *   -- updateOneCompetence --
 *
 *   Update one competence and go back to redux.
 *   and passed competence
 *
 * */

export function* updateOneCompetence(action) {
  const {cid, userName} = action.payload;

  try {
    console.log('update', cid);
    yield put(PA.updateOneCompetenceRequest());
    const competences = yield retry(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences/${cid}`,
          params: {
            fields:
              'title,description,valid_until,requirement_type,certificate_url,description,competence_id,competence_type,competence(files,competence_title,checked_by,short_description,title,competence_type,competence_type_id,competence_id),passed,manager_check_user_id',
            limit: 101,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data.personcompetences));

    console.log('competences', competences);
    if (competences && competences.length !== 0) {
      console.log('competences', competences);
      const isPasssed = competences[0].passed === 100;

      console.log('isPassed', isPasssed);

      if (window.location.href.includes('/role')) {
        const userRolesCompetences = yield select(getShowRoles);
        const userCompetence = userRolesCompetences.data.requirements.findIndex(urc => urc.competence_id === competences[0].competence_id);

        competences[0].competence.competence_type = competences[0].competence_type;
        competences[0].competence.competence_title = competences[0].competence.title;
        competences[0].requirement_type = 'Mandatory';
        if (userCompetence !== -1) {
          userRolesCompetences.data.requirements[userCompetence] = competences[0];
        } else {
          userRolesCompetences.data.requirements.push(competences[0]);
        }
        yield put(PA.fetchRoleRequestSuccess({role: userRolesCompetences.data}));
      };

      if (isPasssed) {
        /* UPDATE PASSED COMPETENCES */
        const usersPassed = yield select(getPassed);
        const userPassedCompetence = usersPassed.data.findIndex(urc => urc.competence_id === competences[0].competence_id);

        if (userPassedCompetence !== -1) {
          console.log('exists');
          console.log('userPassedCompetence', userPassedCompetence);
        } else {
          usersPassed.data.push({
            competence_id: competences[0].competence_id,
            date: competences[0].date,
            requirement: competences[0].requirement,
            valid_until: competences[0].valid_until,
            passed: 100,
            id: competences[0].id,
          });
        }
        yield put(PA.fetchPassedCompetencesSuccess({competences: usersPassed.data}));
      }
    }
    yield put(PA.updateOneCompetenceSuccess());
  }catch(error) {
    console.log('err');
    console.log(error);
  }
};

/*
 *
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 *
 * */

export function* fetchPersonsSummary() {
  yield put(PA.profileFetchPersonSummaryRequest());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const summary = yield retry(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/roles`,
          params: {
            fields: 'description,files',
            role_meta_types: 'position,role',
            summary: 1,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(PA.profileFetchPersonSummarySuccess({summary}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPersonSummaryFailure({error}));
  }
}

function* fetchPassedCompetences(action) {
  yield put(PA.fetchPassedCompetencesRequest());

  // TODO use this when we can fetch a single competence

  let params = {
    state: 'passed',
    limit: 100,
    // lms: 1,
    fields: 'passed,competence_id,points,date,valid_until',
  };

  if (action.dirty) {
    params = {
      state: 'passed',
      limit: 100,
      // lms: 1,
      dirty: 1,
      fields: 'passed,competence_id,points,date',
    };
  }
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params,
        withCredentials: true,
      }));

    yield put(PA.fetchPassedCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.fetchPassedCompetencesFailure({error}));
  }
}

function fetchSinglePassedCompetenceAPI(action) {
  const params = {
    state: 'passed',
    fields: 'passed,competence_id,points',
  };

  return ProfileAxios
    .request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences/${action.pid}`,
      params,
      withCredentials: true,
    })
    .then(({data}) => data);
}

function* fetchSelfSign(action) {
  const selfSign = yield select(getSelfSign);

  if (selfSign.status === T.LoadStatuses.LOADED && !action.payload?.reload) {
    return;
  }
  yield put(PA.selfSignProfileRequest());
  try {
    const {data: {competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/pendingchecklists`,
        withCredentials: true,
      }));

    console.log('competences', competences);
    console.log('competences', competences.filter(cc => cc.course_type.course_type !== CourseType.checklist_item));
    yield put(PA.selfSignProfileSuccess({competences: competences.filter(cc => cc.course_type.course_type !== CourseType.checklist_item)}));
  }catch (error) {
    yield put(PA.selfSignProfileFailure({error}));
  }
}

function* cancelMySelfSign(action) {
  const {courseId, phceId} = action.payload;
  const personId = yield select(getProfileId);

  yield put(PA.cancelSelfSignRequest());
  try {
    const data = {
      personId,
      courseId,
      passed: 0,
    };

    yield retry(() =>
      ProfileAxios.request({
        method: 'DELETE',
        url: `${backendUrlV2}/personcompetences/${phceId}`,
        data,
        withCredentials: true,
      }));
    yield put(PA.cancelSelfSignSuccess({}));
    yield put(PA.selfSignProfile({}));
    yield put(notificationsAdd({
      notification: {
        color: 'red',
        text: i18n('person.cancel-competence-success'),
      },
    }));
  }catch (error) {
    yield put(PA.cancelSelfSignFailure({error}));
  }
};

function* fetchPassedCompetencesFull(action) {
  yield put(PA.fetchPassedCompetencesFullRequest());
  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'passed',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    const {allowSign} = action?.payload || {};

    if (allowSign) {
      const {data: {competences}} = yield retry(() =>
        ProfileAxios.request({
          method: 'GET',
          url: `${backendUrl}/persons/pendingchecklists`,
          withCredentials: true,
        }));

      yield put(PA.selfSignProfileSuccess({competences: competences.filter(cc => cc.course_type.course_type !== CourseType.checklist_item)}));
    }

    for (const competence of competences) {
      competence.competence_type_id = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.fetchPassedCompetencesFullSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.fetchPassedCompetencesFullFailure({error}));
  }
}

function* fetchMissingCompetencesAPI() {
  yield put(PA.fetchCompetencesRequest());

  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'missing',
          limit: 100,
          isthins: 1,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
        = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.fetchCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.fetchCompetencesFailure({error}));
  }
}

function* updateMapCompetences(action) {
  const {alreadyFetchedOutroAndVerification} = action || {};

  const passedCompetencesIds = yield select(getPassedCompetencesIds);

  const mapOutroDot = yield select(getMapOutro);
  const mapVerificationDot = yield select(getMapVerification);

  const outroId = mapOutroDot && mapOutroDot.id;
  const verifyId = mapVerificationDot && mapVerificationDot.id;

  let mapOutroPassedCompetence = passedCompetencesIds.find(id => id === outroId);
  let mapVerificationPassedCompetence = passedCompetencesIds.find(id => id === outroId);

  if (outroId || verifyId) {
    // TODO, when we are able to specify multiple competences to fetch at the same time, use this instead of
    // fetching two times
    if (!mapOutroPassedCompetence && !alreadyFetchedOutroAndVerification) {
      mapOutroPassedCompetence = yield call(fetchSinglePassedCompetenceAPI, {pid: outroId}).personcompetences[0];
    }
    if (
      !mapVerificationPassedCompetence
      && !alreadyFetchedOutroAndVerification
    ) {
      mapVerificationPassedCompetence = yield call(
        fetchSinglePassedCompetenceAPI,
        {pid: verifyId},
      ).personcompetences[0];
    }

    yield mapOutroPassedCompetence ? put(setOutroIsCompleted()) : put(setOutroIsNotCompleted());
    yield mapVerificationPassedCompetence ? put(setMapIsVerified()) : put(setMapIsNotVerified());
  }
}

function* fetchCompetencesAPI() {
  yield put(PA.fetchCompetencesRequest());

  try {
    const {data: {personcompetences: competences}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'all',
          limit: 100,
          fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }));

    for (const competence of competences) {
      competence.competence_type_id
          = competence.competence_type.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.fetchCompetencesSuccess({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.fetchCompetencesFailure({error}));
  }
}

function* updateProgress() {
  const person = yield select(getProfile);
  const roles = yield select(getRoles);
  const passed_ids = yield select(getPassedCompetences);

  if (person.data && Array.isArray(roles.data) && passed_ids) {
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];
      let comp_passed_ids = 0;

      if (passed_ids.keys.length > 0) {
        for (let ii = 0; ii < pos.required_competences.length; ii += 1) {
          if (passed_ids.keys.includes(pos.required_competences[ii].id)) {
            comp_passed_ids += 1;
          }
        }
      }
      pos.progress = 0;
      pos.taskdone = comp_passed_ids;
      pos.tasks = pos.required_competences.length;
      if (comp_passed_ids !== 0) {
        pos.progress = Math.round(comp_passed_ids / pos.tasks * 100);
      }
    }
    const roles_update = [];

    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];

      if (pos.id === person.data.positions[0].id) {
        roles_update.push(pos);
        break;
      }
    }
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];

      if (pos.required_competences.length !== 0 && pos.id !== person.data.positions[0].id) {
        roles_update.push(pos);
      }
    }
    yield put(PA.fetchRolesRequestSuccess({roles: roles_update}));
  }
}

export function* fetchCourseEvents() {
  yield put(PA.profileFetchPersonEventsRequest());

  const userName = yield select(getProfileUserName);
  const my_events = yield call(getCourseEventsAPI, {userName});

  yield put(PA.profileFetchPersonEventsSuccess({events: my_events}));
}

export function* fetchPersonEvents(userName) {
  const my_events = yield call(getCourseEventsAPI, {userName});

  yield put(profileFetchPersonEventsSuccess({events: my_events}));
}

export function* fetchFullPerson() {
  try {
    yield put(PA.profileFetchFullPerson());
    const configObject = yield select(getConfigObject);

    const atlasActivated = configObject.isMapActivated;
    const myEducationEnabled = configObject.isModuleEnabled('my-education');

    if (myEducationEnabled) {
      yield all([yield call(fetchPerson, {})]);
      const userName = yield select(getProfileUserName);

      yield call(fetchPersonEvents, userName);

      yield call(fetchPassedCompetences, {userName});
      const my_events = yield call(getCourseEventsAPI, {userName});

      yield put(PA.profileFetchPersonEventsSuccess({events: my_events}));
    }

    if (atlasActivated) {
      yield call(updateMapCompetences, {alreadyFetchedOutroAndVerification: myEducationEnabled});
    }

    yield put(PA.profileFetchFullPersonSuccess());
    yield put(alertAction.actionClear());
  } catch (error) {
    console.error(error);
  }
}

function* updateCompetenceLevel() {
  const userName = yield select(getProfileUserName);
  const competencelevel = yield call(() =>
    ProfileAxios
      .request({
        method: 'GET',
        params: {user_name: userName},
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));

  return competencelevel;
}

function* editPassword({payload: {data: {newPassword, oldPassword}}}) {
  yield put(PA.profileEditPasswordRequest());
  try {
    const params = {};

    params.password = newPassword;
    params.old_password = oldPassword;
    params.password_match = newPassword;
    const query = encodeURI(stringifyUrlParams({...params}, 'person_data'));
    const userName = yield select(getProfileUserName);
    // TODO: some sort of feedback with result for the user
    const {data: return_data} = yield call(ProfileAxios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${query}`,
      withCredentials: true,
    });

    if (return_data.valid) {
      yield put(PA.profileEditPasswordSuccess());
      yield put(notificationsAdd({
        notification: {
          text: 'Passordet er endret',
          color: 'green',
        },
      }));
    } else {
      yield put(PA.profileEditPasswordFailure({}));
      yield put(notificationsAdd({
        notification: {
          text: return_data.message,
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditPasswordFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: 'Feil ved endring av passord',
        color: 'red',
      },
    }));
  }
}

function* fetchExpiringCompetences() {
  try {
    let profile = yield select(getProfile);

    while (profile.data === null) {
      yield take();
      profile = yield select(getProfile);
    }
    const userName = yield select(getProfileUserName);

    yield put(PA.fetchExpiringRequest());
    const {data: {personcompetences: data}} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,valid_until,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expiring',
        },
        withCredentials: true,
      }));

    yield put(PA.fetchExpiringSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.fetchExpiringFailure({error}));
  }
}

/*
 *  FUNCTION ON UPDATING ALL COMPETENCES, WILL CONTROL THE REST OF THE PROCESS AS WELL.
 */
function* updateCompetences(payload) {
  let section = null;
  let refetchCompetenceOfId = null;
  let disableStatusUpdateCheck = null;

  if (payload && payload.payload) {
    // eslint-disable-next-line prefer-destructuring
    section = payload.payload.section;
    refetchCompetenceOfId = payload.payload.cid;
    disableStatusUpdateCheck = payload.payload.disableStatusUpdateCheck;
  } else if (window.location.href.includes('/role')) {
    section = 'roles';
  }

  yield put(alertAction.actionLoading({message: 'course-done'}));
  const passedIds = yield select(getPassedIds);
  const userName = yield select(getProfileUserName);

  const configObject = yield select(getConfigObject);

  const mapCourses = yield select(getMapCourses);
  const courseToUpdateIsMapCourse
    = mapCourses.data
    && mapCourses.data.tracks[0].dotts
      .map(d => d.id)
      .includes(refetchCompetenceOfId);

  if (!courseToUpdateIsMapCourse) {
    yield call(fetchPassedCompetences, {
      cid: [refetchCompetenceOfId],
      userName,
      dirty: 1,
    });
  }

  if (configObject.isMapActivated && courseToUpdateIsMapCourse) {
    if (payload.payload && payload.payload.data && payload.payload.data.passed === 100) {
      mapCourses.data.tracks[0].dotts.forEach((mc, i) => {
        if (mc.id === refetchCompetenceOfId) {
          mc.status = 'DONE';
          if (i < mapCourses.data.tracks[0].dotts.length - 1 // IF NOT OPEN, OPEN IT.
            &&   mapCourses.data.tracks[0].dotts[i + 1].status !== 'DONE') {
            mapCourses.data.tracks[0].dotts[i + 1].status = 'OPEN';
          }
        }
      });
      yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: mapCourses.data}));
    }
    yield call(updateMapCompetences);
  }

  const passedIds_new = yield select(getPassedIds);

  if (
    !disableStatusUpdateCheck
    && (passedIds.length !== passedIds_new.length || section === 'roles')
  ) {
    /*
     * check if we have a status update, if we do "reload" page.
     *
     * */
    yield updateCompetenceLevel();
    if (section === 'roles') {
      const selectedRoleId = yield select(getSelectedRoleId);

      yield put(fetchRole(selectedRoleId));
    } else {
      yield fetchCompetencesAPI();
    }
  }
  yield updateProgress();
  if (window.location.href.includes('/atlas')) {
    const isAllDone = yield select(getIsAllMapDotsCompleted);

    if (isAllDone) {
      const badge = yield select(getPropertiesForCurrLangAndTrackBadge);

      if (badge) {
        yield put(selectMapCourse({id: 'badge'}));
      }
    }
  }
  yield put(coursesEndCourse());
  yield put(alertAction.actionClear());
}

function* editPerson(action) {
  const {
    person: {
      profile_image_dataURL,
      firstname,
      lastname,
      email,
      mobile,
      roles,
    },
  } = action.payload;

  /*
   * TRIGGER SO WE UPDATE THE PERSON CARD AS WELL.
   * */
  yield put(PA.profileEditPersonRequest());
  const userName = yield select(getProfileUserName);
  const params = encodeURI(stringifyUrlParams(
    {
      profile_image_dataURL,
      firstname,
      lastname,
      mobile,
      email,
      roles: roles.map(role => role.role_id),
    },
    'person_data',
  ));

  try {
    yield call(() =>
      ProfileAxios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      }));

    yield call(fetchPerson);
    yield put(PA.profileEditPersonSuccess());
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.your-profile-updated-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditPersonFailure(error));
  }
}

function* changeProfilePicture(action) {
  const {person_id, file} = action.payload;

  if (!file) return;

  yield put(PA.profileChangeProfilePictureRequest());

  try {
    const formData = new FormData();

    formData.append('profile_image', file);
    const {data} = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        url: `${backendUrl}/persons/save_profile_image/${person_id}`,
        data: formData,
        withCredentials: true,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      }));

    yield put(PA.profileChangeProfilePictureSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileChangeProfilePictureFailure({error}));
  }
}

function* deleteProfilePicture(action) {
  const {person_id, file_id} = action.payload;

  yield put(PA.profileDeleteProfilePictureRequest());

  try {
    const {data} = yield call(() =>
      ProfileAxios.request({
        method: 'POST',
        url: `${backendUrl}/deletemanagefile/person/${person_id}/${file_id}`,
        withCredentials: true,
      }));

    yield put(PA.profileDeleteProfilePictureSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileDeleteProfilePictureFailure({error}));
  }
}

function cheatAPI(action) {
  return ProfileAxios
    .request({
      method: 'get',
      url: `${backendUrl}/templates/set-comp?username=${action.userName}&cid=${action.id}`,
    })
    .then(response => response.data);
}

function* cheatCompetence(payload) {
  const userName = yield select(getProfileUserName);

  yield call(cheatAPI, {
    id: payload.payload.id,
    userName,
  });
  yield delay(2000);
  yield call(() =>
    ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(({data: {competencelevel: [profile]}}) => profile));
  yield call(updateCompetences, {cid: payload.payload.id});
}

function* fetchCv(action) {
  const {cvId} = action.payload;

  yield put(PA.fetchCvRequest());
  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));


    yield put(PA.fetchCvRequestSuccess({cv: cv.data.cv[0]}));
  } catch (error) {
    console.error(error);
    yield put(PA.fetchCvRequestFailure({error}));
  }
}

function* fetchCvs(action) {
  yield put(PA.fetchCvsRequest());
  try {
    const cvs = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      }));


    yield put(PA.fetchCvsRequestSuccess({cvs: cvs.data.cv}));
  } catch (error) {
    console.error(error);
    yield put(PA.fetchCvsRequestFailure({error}));
  }
}

function* addCv(action) {
  const {data} = action.payload;

  yield put(PA.addCvRequest());

  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'POST',
        data,
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      }));

    yield put(PA.addCvSuccess({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());
    if(action.payload.onSuccessCallback) {
      action.payload.onSuccessCallback(cv.data.cv[0]);
    }
  } catch (error) {
    console.error(error);
    yield put(PA.addCvFailure({error}));
  }
}

function* editCv(action) {
  const {data, cvId} = action.payload;

  yield put(PA.editCvRequest());

  try {
    const cv = yield retry(() =>
      ProfileAxios.request({
        method: 'PUT',
        data,
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));

    yield put(PA.editCvSuccess({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());
    if(action.payload.onSuccessCallback) {
      action.payload.onSuccessCallback();
    }
  } catch (error) {
    console.error(error);
    yield put(PA.editCvFailure({error}));
  }
}

function* removeCv(action) {
  const {cvId, onSuccessCallback, onFailureCallback} = action.payload;

  yield put(PA.removeCvRequest());

  try {
    yield retry(() =>
      ProfileAxios.request({
        method: 'DELETE',
        url: `${backendUrl}/api/cvs/${cvId}`,
        withCredentials: true,
      }));

    yield put(PA.removeCvSuccess({removedId: cvId}));
    yield put(PA.profileFetchCvs());
    if(onSuccessCallback) {
      onSuccessCallback({removedId: cvId});
    }
  } catch (error) {
    console.error(error);
    if(onFailureCallback) {
      onFailureCallback();
    }
    yield put(PA.removeCvFailure({error}));
  }
}

function* fetchTasks(payload) {
  const profile = yield select(getProfile);

  yield put(PA.fetchMyTasksRequest());

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/persons/pendingchecklists/${profile.data.person_id}`,
        withCredentials: true,
      }));

    console.log('my data', data);
    yield put(PA.fetchMyTasksSuccess({competences: data.competences}));
  } catch (error) {
    console.error(error);
    yield put(PA.fetchMyTasksFailure({error}));
  }
}

function* fetchReport(payload) {
  let profile = yield select(getProfile);

  if (!profile.data) {
    if (!profile.isFetching) {
      yield put(PA.profileFetchPersonRequest());
    }
    yield take(PA.PROFILE_FETCH_PERSON_SUCCESS);
  }
  profile = yield select(getProfile);

  yield put(PA.fetchReportRequest());

  try {
    const {data} = yield retry(() =>
      ProfileAxios.request({
        method: 'GET',
        url: `${backendUrl}/reports/personfullcompetencereportjson/${profile.data.person_id}`,
        params: {
          fields:
            'id,passed,date,certificate_url,competence_title,competence(competence_type,files)',
        },
        withCredentials: true,
      }));

    yield put(PA.fetchReportSuccess({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.fetchReportFailure());
  }
}
const exportObj = [
  takeLatest(PA.UPDATE_ACTIVE_ORG_ID, updateActiveOrgId),
  takeLatest(authLoginSuccess().type, fetchFullPerson),
  takeLatest(PA.profileFetchPersonEvents().type, fetchCourseEvents),
  takeLatest(PA.fetchExpiring().type, fetchExpiringCompetences),
  takeLatest(PA.profileEditPassword().type, editPassword),
  takeLatest(AUTH_LOGIN_SUCCESS, fetchFullPerson),
  takeLatest(CONFIG_GET_CONFIG_SUCCESS, fetchFullPerson),
  takeLatest(PA.profileFetchPersonSummary().type, fetchPersonsSummary),
  takeLatest(PA.profileUpdateOneCompetences().type, updateCompetences),
  takeLatest(PA.profileFetchPersonRequirements().type, fetchMissingCompetencesAPI),
  // takeLatest(routerEmployeesListDidMount().type, fetchAllOrganisations),
  takeLatest(PA.fetchCompetences().type, fetchCompetencesAPI),
  takeLatest(PA.selfSignProfile().type, fetchSelfSign),
  takeLatest(
    ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
    fetchPassedCompetencesFull,
  ),
  takeLatest(
    PA.fetchPassedCompetencesFull().type,
    fetchPassedCompetencesFull,
  ),
  takeEvery(PA.fetchPassedCompetences().type, fetchPassedCompetences),
  takeEvery(PA.updateOneCompetence().type, updateOneCompetence),
  takeEvery(PA.fetchCompetencesChildren().type, fetchCompetencesChildrenAPI),
  takeLatest(PA.profileEditPerson().type, editPerson),
  takeLatest(PA.profileChangeProfilePicture().type, changeProfilePicture),
  takeLatest(PA.profileChangeProfilePictureSuccess().type, fetchPerson),
  takeLatest(PA.profileDeleteProfilePicture().type, deleteProfilePicture),
  takeLatest(PA.profileDeleteProfilePictureSuccess().type, fetchPerson),
  takeLatest(PA.profileFetchPerson().type, fetchPerson),
  takeLatest(PA.cheatCompetence().type, cheatCompetence),
  takeLatest(COURSES_COURSE_FINISHED, updateCompetences),
  takeLatest(PA.profileFetchCv().type, fetchCv),
  takeLatest(PA.profileCreateSelfSign().type, createSelfSign),
  takeLatest(PA.profileFetchCvs().type, fetchCvs),
  takeLatest(PA.profileEditCv().type, editCv),
  takeLatest(PA.fetchReport().type, fetchReport),
  takeLatest(PA.fetchMyTasks().type, fetchTasks),
  takeLatest(PA.profileAddCv().type, addCv),
  takeLatest(PA.profileRemoveCv().type, removeCv),
  takeLatest(PA.cancelSelfSign().type, cancelMySelfSign),
];

export default exportObj;
