import React, {Component} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {
  authGetAuthStatusAndStartLoading,
  authLogoutRequest,
  authStartLoginCheckOnInterval,
} from '@actions/auth.actions';
import {
  configGetConfig,
  configLoadConfigFromLocalStorage,
} from '@actions/config.actions';
import {coursesCourseFinished} from '@actions/courses.actions';
import {notificationsHide} from '@actions/notifications.actions';
import {routerAppComponentDidMount} from '@actions/router.actions';
import ClearCache from '@components/clear-cache/clear-cache';
import SignatureModal from '@components/course/signature-modal';
import CourseLoader from '@components/loading/courseLoader';
import Loading from '@components/loading/loading';
import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import {allowedLanguages, buildHash, nanoLearningLoginUrl, version} from '@config';
import {css} from '@emotion/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAt} from '@fortawesome/pro-solid-svg-icons/faAt';
import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faFileExcel} from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import {faHourglass} from '@fortawesome/pro-solid-svg-icons/faHourglass';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserCheck} from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import loadable from '@loadable/component';
import {LoginContainer, PrivateRoute} from '@routes/auth';
import {CvContainer} from '@routes/my-education/containers/my-education-cv/cv-container';
import {
  atlas as AtlasRoutes,
  atlas as atlasRoutes,
  courseCatalog as courseCatalogRoutes,
  dashboard,
  employees as employeesRoutes,
  learningPath,
  logout,   manage as manageRoutes,
  myEducation as myEducationRoutes,
  onboarding,
} from '@routes/routes.manifest';
import {getAuthStatus, getStartUrl} from '@selectors/auth.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {getActiveCourse} from '@selectors/courses.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {getIsMapCompleted} from '@selectors/map.selectors';
import {getShownNotifications} from '@selectors/notifications.selectors';
import {getProfile, isManager} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {IdentityWarning, NoticeBanner} from '@styles/mixins';
import {configObjectShape} from '@types/config';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/misc.utils';
import {ResponsiveStyles} from './styles/responsive-styles';
import '@styles/app.scss';
import '@styles/foundation.min.css';
import 'focus-visible';

const CourseCatalogSwitch = loadable(async () => {
  console.log('load up course');

  return import(/* webpackChunkName: "course.catalog" */ './routes/course-catalog/course-catalog-switch');
});

const MessageCheck = loadable(async () => {
  console.log('load up message.check');

  return import(/* webpackChunkName: "message.check" */ './common/components/message-check/message-check');
});


const MyEducationSwitch = loadable(async () => {
  console.log('load-MyEducationSwitch');

  return import(/* webpackChunkName: "my.education" */ './routes/my-education/my-education-switch');
});

const NanoLearningPlayerContainer = loadable(async () =>
  import(/* webpackChunkName: "nano.player" */ './routes/nano-learning/containers/nano-learning-player-container'));

const CoursePlayer = loadable(async () =>
  import(/* webpackChunkName: "nanoplayer" */ './routes/course-catalog/components/course-player/course-player'));


const EmployeesContainer = loadable(async () => {
  console.log('load-EmployeeContainers');

  return import(/* webpackChunkName: "employees" */ './routes/employees/containers/employees-container');
});

const EmployeeContainer = loadable(async () => {
  console.log('load-EmployeeContainer');

  return import(/* webpackChunkName: "employee" */ './routes/employees/containers/employee-container');
});

const Atlas = loadable(async () => {
  console.log('load Atlas');

  return import(/* webpackChunkName: "atlas" */ './routes/atlas/index');
});

const ReportContent = loadable(async () => {
  const {ReportContent} = await import(/* webpackChunkName: "report.content" */ './routes/reports/report-content/report-content');

  return props => <ReportContent {...props} />;
});

const ReportSearch = loadable(async () => {
  const {ReportSearch} = await import(/* webpackChunkName: "report.search" */ './routes/reports/report-search/report-search');

  return props => <ReportSearch {...props} />;
});

const ReportCustom = loadable(async () => {
  const {ReportCustom} = await import(/* webpackChunkName: "report.custom" */ './routes/reports/report-custom/report-custom');

  return props => <ReportCustom {...props} />;
});

const AdminCompetences = loadable(async () => {
  console.log('Load up admin-competences');

  return import(/* webpackChunkName: "admin-roles" */ '@routes/admin/competences');
});

const AdminRoles = loadable(async () => {
  console.log('Load up admin-roles');

  return import(/* webpackChunkName: "admin-roles" */ '@routes/admin/roles');
});

const LearningPathSwitch = loadable(() => {
  console.log('load LearningPath');

  return import(/* webpackChunkName: "learning-path" */ './routes/learning-path/learning-path-switch');
});

library.add(
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faAt,
  faRedo,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel,
);

const employeesContainerRoutes = [...Object.values(employeesRoutes), dashboard.userPreview];
const cvContainerRoutes = [myEducationRoutes.cvEdit.path, myEducationRoutes.cvAdd.path];
const employeeRoutes = [employeesRoutes.employeePreview, dashboard.userPreview];

class App extends Component {
  componentDidMount() {
    const {authStartLoginCheckOnInterval, authGetAuthStatusAndStartLoading} = this.props;

    this.setLanguage();

    if (buildHash || version) {
      // eslint-disable-next-line no-console
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }

    // the process is like this:
    // 1. check if the user is logged in or not, display loading while we wait
    // 2. if not logged in: display login, else: continue to step 3
    // 3. get config, display loading while we wait
    // 4. when the config is done loading, load the rest
    authGetAuthStatusAndStartLoading();

    // check if the user is still logged in
    authStartLoginCheckOnInterval({intervalMs: 1000 * 60 * 60});
  }

  setLanguage = () => {
    const langCodeNavigator = getTwoletterLangCodeFromNavigatorLanguage();
    let locale = localStorage.getItem('language');

    if (langCodeNavigator && !locale) {
      locale = langCodeNavigator;
    }

    if (!allowedLanguages.includes(locale)) {
      locale = allowedLanguages[0];
    }

    localStorage.setItem('language', locale);

    const dayjsLocale = {
      no: 'nb',
      en:'en-us',
    }[locale];

    dayjs.locale(dayjsLocale);
    dayjs.updateLocale(dayjsLocale, {
      weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
      yearStart: 4, // The week that contains Jan 4th is the first week of the year.
    });
  }

  onEndCourseClick = () => {
    const {coursesCourseFinished, activeCourse} = this.props;

    let my_section = 'first-page';

    if (window.location.pathname.includes('/role')) {
      my_section = 'roles';
    }

    coursesCourseFinished({
      cid: activeCourse.cid,
      section: my_section,
    });
  }

  handleToggleParams = () => {
    const isSetMapLocation
      = window.location.pathname.includes('/set-atlas');

    const isLoadConfig
      = window.location.pathname.includes('/load-config');

    if (isSetMapLocation) {
      const track = window.location.pathname.split('/')[2];
      const params = new URL(document.location).searchParams;

      const langId = params.get('lang');

      localStorage.setItem('track', track);
      localStorage.setItem('language', langId || 'no');
      Object.entries(localStorage).forEach(([k, v]) => {
        if (/^track-data\/\d+$/.test(k)) {
          localStorage.removeItem(k);
        }
      });
    }

    if (isLoadConfig) {
      const config = window.location.pathname.split('/')[2];

      localStorage.setItem('config', config);
      localStorage.setItem('track', config);
      localStorage.removeItem('learningportalConfig');
      window.location = '/';
    }
  }

  render() {
    const {
      sessionId,
      profile,
      isManager,
      alert,
      activeCourse,
      authLogoutRequest,
      notifications,
      secondLogin,
      hideNotification,
      configObject,
      isMapCompleted,
      startUrl,
      authStatus,
    } = this.props;

    this.handleToggleParams();

    if (
      !Cookies.get('tg-visit')
      || !sessionId
      || sessionId === 'sd'
      || secondLogin
      || profile.error
      || authStatus.isLoggedIn === false
    ) {
      return (
        <Router basename={process.env.REACT_APP_MOUNT_PATH}>
          <div className="app-wrapper menu-two">
            {nanoLearningLoginUrl
              ?  (
                <LoginContainer
                  to={nanoLearningLoginUrl}
                />
              )
              : (
                <>
                  <LoginContainer />
                  {!window.location.pathname.includes('login') && !window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to={window.location.pathname !== 'login' && '/login?redirect=' + window.location.pathname}
                    />
                  ) || window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to="/login"
                    />
                  )}
                </>
              )}

            <Notifications
              notifications={notifications}
              onHideNotification={hideNotification}
            />
          </div>
        </Router>
      );
    }

    const useMap = configObject.isMapActivated;

    const getRedirectRoute = () => {
      console.log('getRedirectRoute')
      const defaultRoute = startUrl !== '/' && startUrl || configObject.getProperty('params.default-route');

      if(useMap && !isMapCompleted) {
        return  defaultRoute || atlasRoutes.main.path;
      }

      if(useMap && isMapCompleted) {
        return configObject.isModuleEnabled('coursecatalog')
          ? courseCatalogRoutes.main.path
          : defaultRoute || atlasRoutes.main.path;
      }

      if(!useMap) {
        return defaultRoute || courseCatalogRoutes.main.path;
      }

      return undefined;
    };
    console.log('employeesContainerRoutes', employeesContainerRoutes)
    return (
      <>
        {configObject.data && authStatus.isLoggedIn && (
          <Router basename={process.env.REACT_APP_MOUNT_PATH}>
            {activeCourse && activeCourse.type === 24
            && <NanoLearningPlayerContainer courseId={activeCourse.cid} />
            || (
              <ResponsiveStyles
                className={classNames('app-wrapper', configObject.getProperty('params.menu.type') || 'menu-two')}
              >
                <div id="portal-above" />
                {/* Routes shown above other content */}
                <Route
                  path={cvContainerRoutes}
                  render={({...props}) =>
                    <CvContainer {...props}/>}
                  breadcrumbs
                />
                <Route
                  path={employeeRoutes}
                  render={({match: {url, params: {userName}}}) => (
                    <EmployeeContainer
                      userName={userName}
                      url={url}
                      className="employees-page__section employees-page__section--columns employees-page__section--preview fullwidth"
                    />
                  )}
                />
                <div className="app-wrapper__menu">
                  <Menu
                    messagesCount={0}
                    orgId={-1}
                    isManager={isManager}
                    onLogoutClick={() => {
                      authLogoutRequest();
                    }}
                    profileData={profile.data}
                  />
                </div>

                {activeCourse && activeCourse.status !== 0 && (
                  <div className="app-wrapper__content fullscreen">
                    <CoursePlayer
                      reloading={alert.type === 'alert-reloading'}
                      onEnd={this.onEndCourseClick}
                      id={activeCourse.cid}
                      cid={activeCourse.cid}
                      type={activeCourse.type}
                      url={activeCourse.url}
                      opened={activeCourse.opened}
                      timestamp={activeCourse.timestamp}
                    />
                  </div>
                ) || (
                  <div className="app-wrapper__content">
                    <Switch>
                      {!Cookies.get('tg-visit')
                       || profile.error && <LoginContainer />}
                      <Route
                        path={logout}
                        render={() => {
                          authLogoutRequest();
                        }}
                      />
                      <PrivateRoute
                        path={courseCatalogRoutes.main.path}
                        component={CourseCatalogSwitch}
                      />
                      <PrivateRoute
                        path={manageRoutes.roles}
                        component={AdminRoles}
                      />
                      <PrivateRoute
                        path={manageRoutes.competences}
                        component={AdminCompetences}
                      />
                      <PrivateRoute
                        path={myEducationRoutes.main.path}
                        component={MyEducationSwitch}
                      />
                      <PrivateRoute
                        path={dashboard.search}
                        component={ReportSearch}
                      />
                      <PrivateRoute
                        path={dashboard.content}
                        component={ReportContent}
                      />
                      <PrivateRoute
                        path={[dashboard.company]}
                        component={ReportCustom}
                      />
                      <PrivateRoute
                        path={employeesContainerRoutes}
                        component={EmployeesContainer}
                      />
                      <PrivateRoute
                        path={AtlasRoutes.main.path}
                        component={Atlas}
                      />
                      <PrivateRoute
                        path={[learningPath.main.path, learningPath.track.path, onboarding.main.path, onboarding.track.path]}
                        component={LearningPathSwitch}
                      />
                      <Redirect
                        to={
                         getRedirectRoute()
                       }
                      />
                    </Switch>
                  </div>
                )}
                <SignatureModal
                  signWithPassword={!(configObject.getProperty('params.sign-with') && configObject.getProperty('params.sign-with') === 'check')}
                />

                <Notifications
                  notifications={notifications}
                  onHideNotification={hideNotification}
                />

                {alert.type === 'alert-reloading'
              && (configObject.getProperty('params.reloading-indicator-variant') === 'fullscreen'
                ? alert.message === 'course-done'
                 && <CourseLoader />
                 || <CourseLoader />
                : <Loading />
              )}
                {['poll', 'push'].includes(configObject.getProperty('params.mail'))  && (
                  <MessageCheck
                    type={configObject.getProperty('params.mail')}
                  />
                )}
                <ClearCache>
                  {({message, isLatestVersion, emptyCacheStorage}) =>
                    !isLatestVersion && (
                      <NoticeBanner>
                        <span>
                          {message
                         || 'Ny versjon er lansert. Klikk refresh for ny versjon.'}
                        </span>
                        <button
                          type="button"
                          className="btn"
                          zIndex="0"
                          onClick={() => {
                            localStorage.removeItem('learningportalConfig');
                            emptyCacheStorage();
                          }}
                        >
                          REFRESH
                        </button>
                      </NoticeBanner>
                    )}
                </ClearCache>
              </ResponsiveStyles>
            )}
          </Router>
        )
          || <CourseLoader appload />}

        {Boolean(localStorage.getItem('bart')
          && profile?.data?.person_id.toString() === localStorage.getItem('bart')) && (
            <IdentityWarning>
              {i18n('person.you-have-now')}
              <strong css={css({marginLeft: '.4em'})}>{profile.data.fullname}</strong>
            </IdentityWarning>
        )}
      </>
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  alert: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  authLogoutRequest: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  configObject: configObjectShape,
  isMapCompleted: PropTypes.any,
  configLoadConfigFromLocalStorage: PropTypes.func.isRequired,
  configGetConfig: PropTypes.func.isRequired,
  authStatus: PropTypes.shape({}).isRequired,
  startUrl: PropTypes.string,
  authGetAuthStatusAndStartLoading: PropTypes.func.isRequired,
  authStartLoginCheckOnInterval: PropTypes.func.isRequired,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
  startUrl: null,
  isMapCompleted: null,
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  secondLogin: state.auth.secondLogin,
  alert: state.alert,
  activeCourse: getActiveCourse(state),
  profile: getProfile(state),
  notifications: getShownNotifications(state),
  isManager: isManager(state),
  isMobile: getIsMobile(state),
  configObject: getConfigObject(state),
  isMapCompleted: getIsMapCompleted(state),
  startUrl: getStartUrl(state),
  authStatus: getAuthStatus(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerAppComponentDidMount,
      authLogoutRequest,
      coursesCourseFinished,
      hideNotification: notificationsHide,
      configGetConfig,
      configLoadConfigFromLocalStorage,
      authGetAuthStatusAndStartLoading,
      authStartLoginCheckOnInterval,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
