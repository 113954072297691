export const selectors = {
  global: {
    wrapper: '.global__wrapper',
    container: '.global__container',
    pageSection: '.global__page-section',
    paddedPageSection: '.global__padded-page-section',
    heading: '.global__heading',
    subHeading: '.global__subheading',
  },
  header: {
    wrapper: '.header.header-wrapper',
    top: '.header.header-wrapper .header__top',
    backButton: '.header.header-wrapper .header__back-button-wrapper',
  },
  utils: {
    defaultPadding: '.--default-padding',
    fullwidthImage: '.--fullwidth-image',
    smallerHeader: '.--smaller-header',
    smallerHeaderTitle: '.--smaller-header .header__title',
    noHeaderPadding: '.--no-header-padding',
    overrideSidepadding: '.--override-sidepadding',
  },
  misc: {subHeadingWithButton: 'header.with-btn'},
};
