import * as configActions from '@actions/config.actions';
import * as T from '@types/load.types';
import {createConfigObject} from '../util/config.util';

export const initialConfigObject = {
  getProperty: () => {},
  isModuleEnabled: () => {},
  data: null,
  status: T.LoadStatuses.NOT_LOADED,
  isMapActivated: undefined,
};

const initialState = {
  config: {
    isFetching: false,
    data: null,
    error: null,
    configObject: initialConfigObject,
  },
};

const config = (state = initialState, action) => {
  switch (action.type) {
  case configActions.CONFIG_GET_CONFIG_REQUEST:
    return {
      ...state,
      config: {
        ...initialState.config,
        isFetching: true,
        configObject: {
          ...initialConfigObject,
          status: T.LoadStatuses.IS_LOADING,
        },
      },
    };
  case configActions.CONFIG_GET_CONFIG_SUCCESS:
    return {
      ...state,
      config: {
        ...initialState.config,
        data: action.payload.config,
        configObject: createConfigObject({data: action.payload.config}),
      },
    };
  case configActions.CONFIG_GET_CONFIG_FAILURE:
    return {
      ...state,
      config: {
        ...initialState.config,
        error: action.payload.error,
        configObject: {
          ...initialState.config.configObject,
          status: T.LoadStatuses.FAILED,
        },
      },
    };
  case 'RESET_STORE':
    return initialState;
  default:
    return state;
  }
};

export default config;
