const prefix = '[profile]';

export const PROFILE_UPDATE_PASSED_COMPETENCES = `${prefix} update on passed competences`;
export const profileUpdateOneCompetences = payload => ({
  type: PROFILE_UPDATE_PASSED_COMPETENCES,
  payload,
});

export const PROFILE_SET_LAST_MESSAGE_TIMESTAMP = `${prefix} set last message timestamp`;
export const profileSetLastMessageTimestamp = payload => ({
  type: PROFILE_SET_LAST_MESSAGE_TIMESTAMP,
  payload,
});

export const PROFILE_GET_PERSON = `${prefix}/person/get/request`;
export const PROFILE_FETCH_PERSON_REQUEST = `${prefix}/person/fetch/request`;
export const PROFILE_FETCH_PERSON_SUCCESS = `${prefix}/person/fetch/success`;
export const PROFILE_FETCH_PERSON_FAILURE = `${prefix}/person/fetch/failure`;

export const PROFILE_FETCH_PERSON_FULL = `${prefix}/person/get/full/request`;
export const PROFILE_FETCH_PERSON_FULL_SUCCESS = `${prefix}/person/get/full/success`;

export const profileFetchFullPerson = () => ({type: PROFILE_FETCH_PERSON_FULL});

export const profileFetchFullPersonSuccess = () => ({type: PROFILE_FETCH_PERSON_FULL_SUCCESS});

export const profileFetchPerson = () => ({type: PROFILE_GET_PERSON});
export const profileFetchPersonRequest = () => ({type: PROFILE_FETCH_PERSON_REQUEST});

export const profileFetchPersonSuccess = payload => ({
  type: PROFILE_FETCH_PERSON_SUCCESS,
  payload,
});

export const profileFetchPersonFailure = payload => ({
  type: PROFILE_FETCH_PERSON_FAILURE,
  payload,
});

export const PROFILE_EDIT_PASSWORD = `${prefix}/person/edit/password`;
export const PROFILE_EDIT_PASSWORD_REQUEST = `${prefix}/person/edit/password/request`;
export const PROFILE_EDIT_PASSWORD_SUCCESS = `${prefix}/person/edit/password/success`;
export const PROFILE_EDIT_PASSWORD_FAILURE = `${prefix}/person/edit/password/failure`;

export const profileEditPassword = payload => ({
  type: PROFILE_EDIT_PASSWORD,
  payload,
});


export const profileEditPasswordRequest = () => ({type: PROFILE_EDIT_PASSWORD_REQUEST});

export const profileEditPasswordSuccess = payload => ({
  type: PROFILE_EDIT_PASSWORD_SUCCESS,
  payload,
});

export const profileEditPasswordFailure = payload => ({
  type: PROFILE_EDIT_PASSWORD_FAILURE,
  payload,
});

export const PROFILE_FETCH_SUMMARY = `${prefix}/person/fetch/summary`;
export const PROFILE_FETCH_SUMMARY_REQUEST = `${prefix}/person/fetch/summary/request`;
export const PROFILE_FETCH_SUMMARY_SUCCESS = `${prefix}/person/fetch/summary/success`;
export const PROFILE_FETCH_SUMMARY_FAILURE = `${prefix}/person/fetch/summary/failure`;

export const profileFetchPersonSummary = () => ({type: PROFILE_FETCH_SUMMARY});

export const profileFetchPersonSummaryRequest = () => ({type: PROFILE_FETCH_SUMMARY_REQUEST});

export const profileFetchPersonSummarySuccess = payload => ({
  type: PROFILE_FETCH_SUMMARY_SUCCESS,
  payload,
});

export const profileFetchPersonSummaryFailure = payload => ({
  type: PROFILE_FETCH_SUMMARY_FAILURE,
  payload,
});

export const PROFILE_FETCH_REQUIREMENTS = `${prefix}/person/fetch/requirements`;
export const PROFILE_FETCH_REQUIREMENTS_REQUEST = `${prefix}/person/fetch/requirements/request`;
export const PROFILE_FETCH_REQUIREMENTS_SUCCESS = `${prefix}/person/fetch/requirements/success`;
export const PROFILE_FETCH_REQUIREMENTS_FAILURE = `${prefix}/person/fetch/requirements/failure`;

export const profileFetchPersonRequirements = () => ({type: PROFILE_FETCH_REQUIREMENTS});

export const profileFetchPersonRequirementsRequest = () => ({type: PROFILE_FETCH_REQUIREMENTS_REQUEST});

export const profileFetchPersonRequirementsSuccess = payload => ({
  type: PROFILE_FETCH_REQUIREMENTS_SUCCESS,
  payload,
});

export const profileFetchPersonRequirementsFailure = payload => ({
  type: PROFILE_FETCH_REQUIREMENTS_FAILURE,
  payload,
});

export const PROFILE_FETCH_EVENTS = `${prefix}/person/fetch/events`;
export const PROFILE_FETCH_EVENTS_REQUEST = `${prefix}/person/fetch/events/request`;
export const PROFILE_FETCH_EVENTS_SUCCESS = `${prefix}/person/fetch/events/success`;

export const profileFetchPersonEvents = payload => ({
  type: PROFILE_FETCH_EVENTS,
  payload,
});

export const profileFetchPersonEventsRequest = () => ({type: PROFILE_FETCH_EVENTS_REQUEST});

export const profileFetchPersonEventsSuccess = payload => ({
  type: PROFILE_FETCH_EVENTS_SUCCESS,
  payload,
});

export const PROFILE_SIGNED_ON_SINGLE_EVENT = `${prefix}/person/signed_on_single_event`;

export const profileSignedOnSingleEvent = payload => ({
  type: PROFILE_SIGNED_ON_SINGLE_EVENT,
  payload,
});

export const PROFILE_EDIT_PERSON = `${prefix} profile edit person`;
export const PROFILE_EDIT_PERSON_REQUEST = `${prefix} profile edit person request`;
export const PROFILE_EDIT_PERSON_SUCCESS = `${prefix} profile edit person success`;
export const PROFILE_EDIT_PERSON_FAILURE = `${prefix} profile edit person failure`;

export const profileEditPerson = payload => ({
  type: PROFILE_EDIT_PERSON,
  payload,
});

export const profileEditPersonRequest = () => ({type: PROFILE_EDIT_PERSON_REQUEST});

export const profileEditPersonSuccess = payload => ({
  type: PROFILE_EDIT_PERSON_SUCCESS,
  payload,
});

export const profileEditPersonFailure = payload => ({
  type: PROFILE_EDIT_PERSON_FAILURE,
  payload,
});

export const PROFILE_FETCH_HELPTEXTS_SUCCESS = `${prefix}/profile/fetch/helptexts/success`;
export const profileFetchHelptextsSuccess = payload => ({
  type: PROFILE_FETCH_HELPTEXTS_SUCCESS,
  payload,
});

export const PROFILE_FETCH_ALLORGS = `${prefix}/profile/fetch/allorgs/person`;
export const PROFILE_FETCH_ALLORGS_REQUEST = `${prefix}/profile/fetch/allorgs/request`;
export const PROFILE_FETCH_ALLORGS_SUCCESS = `${prefix}/profile/fetch/allorgs/success`;
export const PROFILE_FETCH_ALLORGS_FAILURE = `${prefix}/profile/fetch/allorgs/failure`;

export const profileFetchAllOrganisations = payload => ({
  type: PROFILE_FETCH_ALLORGS,
  payload,
});

export const profileFetchAllOrganisationsRequest = () => ({type: PROFILE_FETCH_ALLORGS_REQUEST});

export const profileFetchAllOrganisationsSuccess = payload => ({
  type: PROFILE_FETCH_ALLORGS_SUCCESS,
  payload,
});

export const profileFetchAllOrganisationsFailure = payload => ({
  type: PROFILE_FETCH_ALLORGS_FAILURE,
  payload,
});

export const PROFILE_CREATE_SELF_SIGN = 'PROFILE_CREATE_SELF_SIGN';
export const PROFILE_CREATE_SELF_SIGN_REQUEST = 'PROFILE_CREATE_SELF_SIGN_REQUEST';
export const PROFILE_CREATE_SELF_SIGN_SUCCESS = 'PROFILE_CREATE_SELF_SIGN_SUCCESS';
export const PROFILE_CREATE_SELF_SIGN_FAILURE = 'PROFILE_CREATE_SELF_SIGN_FAILURE';

export const profileCreateSelfSign = payload => ({
  type: PROFILE_CREATE_SELF_SIGN,
  payload,
});

export const profileCreateSelfSignRequest = () => ({type: PROFILE_CREATE_SELF_SIGN_REQUEST});

export const profileCreateSelfSignSuccess = payload => ({
  type: PROFILE_CREATE_SELF_SIGN_SUCCESS,
  payload,
});

export const profileCreateSelfSignFailure = payload => ({
  type: PROFILE_CREATE_SELF_SIGN_FAILURE,
  payload,
});

export const PROFILE_CHANGE_PROFILE_PICTURE = `${prefix} profile change profile picture`;
export const PROFILE_CHANGE_PROFILE_PICTURE_REQUEST = `${prefix} profile change profile picture request`;
export const PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS = `${prefix} profile change profile picture success`;
export const PROFILE_CHANGE_PROFILE_PICTURE_FAILURE = `${prefix} profile change profile picture failure`;

export const profileChangeProfilePicture = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE,
  payload,
});
export const profileChangeProfilePictureRequest = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_REQUEST,
  payload,
});
export const profileChangeProfilePictureSuccess = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS,
  payload,
});
export const profileChangeProfilePictureFailure = payload => ({
  type: PROFILE_CHANGE_PROFILE_PICTURE_FAILURE,
  payload,
});

export const PROFILE_DELETE_PROFILE_PICTURE = `${prefix} profile delete profile picture`;
export const PROFILE_DELETE_PROFILE_PICTURE_REQUEST = `${prefix} profile delete profile picture request`;
export const PROFILE_DELETE_PROFILE_PICTURE_SUCCESS = `${prefix} profile delete profile picture success`;
export const PROFILE_DELETE_PROFILE_PICTURE_FAILURE = `${prefix} profile delete profile picture failure`;

export const profileDeleteProfilePicture = payload => ({
  type: PROFILE_DELETE_PROFILE_PICTURE,
  payload,
});
export const profileDeleteProfilePictureRequest = payload => ({
  type: PROFILE_DELETE_PROFILE_PICTURE_REQUEST,
  payload,
});
export const profileDeleteProfilePictureSuccess = payload => ({
  type: PROFILE_DELETE_PROFILE_PICTURE_SUCCESS,
  payload,
});
export const profileDeleteProfilePictureFailure = payload => ({
  type: PROFILE_DELETE_PROFILE_PICTURE_FAILURE,
  payload,
});

export const PROFILE_SET_MANAGER = `${prefix} profile set manager`;
export const profileSetManager = () => ({type: PROFILE_SET_MANAGER});

export const PROFILE_SET_SPECIALROLES = `${prefix} profile set specialroles`;
export const profileSetSpecialroles = payload => ({
  type: PROFILE_SET_SPECIALROLES,
  payload,
});

export const PROFILE_FETCH_EXPIRING = `${prefix}/profile/fetch/expiring`;
export const PROFILE_FETCH_EXPIRING_REQUEST = `${prefix}/profile/fetch/expiringrequest`;
export const PROFILE_FETCH_EXPIRING_SUCCESS = `${prefix}/profile/fetch/expiringsuccess`;
export const PROFILE_FETCH_EXPIRING_FAILURE = `${prefix}/profile/fetch/expiringfailure`;

export const fetchExpiring = () => ({type: PROFILE_FETCH_EXPIRING});

export const fetchExpiringRequest = () => ({type: PROFILE_FETCH_EXPIRING_REQUEST});

export const fetchExpiringSuccess = payload => ({
  type: PROFILE_FETCH_EXPIRING_SUCCESS,
  payload,
});

export const fetchExpiringFailure = payload => ({type: PROFILE_FETCH_EXPIRING_FAILURE});


export const PROFILE_FETCH_COMPETENCES = `${prefix} profile start fetch passed competences`;
export const PROFILE_FETCH_COMPETENCES_REQUEST = `${prefix} profile fetch passed competences request`;
export const PROFILE_FETCH_COMPETENCES_SUCCESS = `${prefix} profile fetch passed competences success`;
export const PROFILE_FETCH_COMPETENCES_FAILURE = `${prefix} profile fetch passed competences failure`;

export const PROFILE_FETCH_COMPETENCE_REQUEST = `${prefix} profile fetch passed competence request`;
export const PROFILE_FETCH_COMPETENCE_FAILURE = `${prefix} profile fetch passed competence failure`;

export const fetchCompetences = () => ({type: PROFILE_FETCH_COMPETENCES});

export const fetchCompetenceRequest = () => ({type: PROFILE_FETCH_COMPETENCE_REQUEST});

export const fetchCompetenceFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCE_FAILURE,
  payload,
});

export const fetchCompetencesRequest = () => ({type: PROFILE_FETCH_COMPETENCES_REQUEST});

export const fetchCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_COMPETENCES_SUCCESS,
  payload,
});

export const fetchCompetencesFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST = `${prefix}/profile/fetch/showrole-children/request`;
export const PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS = `${prefix}/profile/fetch/showrole-children/success`;
export const PROFILE_FETCH_SHOWROLE_CHILDREN_FAILURE = `${prefix}/profile/fetch/showrole-children/failure`;

export const fetchShowRoleChildrenSuccess = payload => ({
  type: PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS,
  payload,
});

export const fetchShowRoleChildrenFailure = payload => ({
  type: PROFILE_FETCH_SHOWROLE_CHILDREN_FAILURE,
  payload,
});

export const fetchShowRoleChildrenRequest = () => ({type: PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST});

export const PROFILE_FETCH_COMPETENCES_CHILDREN = `${prefix}/profile/start/fetch/competences-children`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST = `${prefix}/profile/fetch/competences-children/request`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS = `${prefix}/profile/fetch/competences-children/success`;
export const PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE = `${prefix}/profile/fetch/competences-children/failure`;

export const PROFILE_FETCH_COMPETENCE_CHILDREN_REQUEST = `${prefix}/profile/fetch/passed/competence-children/request`;
export const PROFILE_FETCH_COMPETENCE_CHILDREN_FAILURE = `${prefix}/profile/fetch/passed/competence-children/failure`;

export const fetchCompetencesChildren = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN,
  payload,
});

export const fetchCompetenceChildrenRequest = () => ({type: PROFILE_FETCH_COMPETENCE_CHILDREN_REQUEST});

export const fetchCompetenceChildrenFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCE_CHILDREN_FAILURE,
  payload,
});

export const fetchCompetencesChildrenRequest = () => ({type: PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST});

export const fetchCompetencesChildrenSuccess = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS,
  payload,
});

export const fetchCompetencesChildrenFailure = payload => ({
  type: PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE,
  payload,
});

export const SWITCH_TAB = `${prefix}/profile/switch/tab`;
export const switchTab = payload => ({
  type: SWITCH_TAB,
  payload,
});

export const SWITCH_SECTION = `${prefix}/profile/switch/sectionb`;
export const switchSection = payload => ({
  type: SWITCH_SECTION,
  payload,
});

export const PROFILE_TOGGLE_COMPETENCE = `${prefix} profile toggle competence`;
export const PROFILE_TOGGLE_COMPETENCE_REQUEST = `${prefix} profile toggle competence request`;
export const PROFILE_TOGGLE_COMPETENCE_SUCCESS = `${prefix} profile toggle competence success`;
export const PROFILE_TOGGLE_COMPETENCE_FAILURE = `${prefix} profile toggle competence failure`;

export const profileToggleCompetence = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE,
  payload,
});
export const profileToggleCompetenceRequest = () => ({type: PROFILE_TOGGLE_COMPETENCE_REQUEST});
export const profileToggleCompetenceSuccess = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE_SUCCESS,
  payload,
});
export const profileToggleCompetenceFailure = payload => ({
  type: PROFILE_TOGGLE_COMPETENCE_FAILURE,
  payload,
});

export const PROFILE_FETCH_ROLE = `${prefix}/profile/start/fetch/role`;
export const PROFILE_FETCH_ROLE_REQUEST = `${prefix}/profile/fetch/role/request`;
export const PROFILE_FETCH_ROLE_SUCCESS = `${prefix}/profile/fetch/role/success`;
export const PROFILE_FETCH_ROLE_FAILURE = `${prefix}/profile/fetch/role/failure`;

export const fetchRoleRequest = () => ({type: PROFILE_FETCH_ROLE_REQUEST});

export const fetchRoleRequestSuccess = payload => ({
  type: PROFILE_FETCH_ROLE_SUCCESS,
  payload,
});
export const fetchRoleRequestFailure = payload => ({
  type: PROFILE_FETCH_ROLE_FAILURE,
  payload,
});

export const PROFILE_FETCH_ROLES = `${prefix} profile start fetch roles`;
export const PROFILE_FETCH_ROLES_REQUEST = `${prefix} profile fetch roles request`;
export const PROFILE_FETCH_ROLES_SUCCESS = `${prefix} profile fetch roles success`;
export const PROFILE_FETCH_ROLES_FAILURE = `${prefix} profile fetch roles failure`;

export const fetchRolesRequest = () => ({type: PROFILE_FETCH_ROLES_REQUEST});

export const fetchRolesRequestSuccess = payload => ({
  type: PROFILE_FETCH_ROLES_SUCCESS,
  payload,
});
export const fetchRolesRequestFailure = payload => ({
  type: PROFILE_FETCH_ROLES_FAILURE,
  payload,
});

export const PROFILE_UPDATE_ONE_COMPETENCE = 'PROFILE_UPDATE_ONE_COMPETENCE';
export const PROFILE_UPDATE_ONE_COMPETENCE_REQUEST = 'PROFILE_UPDATE_ONE_COMPETENCE_REQUEST';
export const PROFILE_UPDATE_ONE_COMPETENCE_SUCCESS = 'PROFILE_UPDATE_ONE_COMPETENCE_SUCCESS';
export const PROFILE_UPDATE_ONE_COMPETENCE_FAILURE = 'PROFILE_UPDATE_ONE_COMPETENCE_FAILURE';

export const updateOneCompetence = payload => ({
  type: PROFILE_UPDATE_ONE_COMPETENCE,
  payload,
});

export const updateOneCompetenceRequest = () => ({type: PROFILE_UPDATE_ONE_COMPETENCE_REQUEST});

export const updateOneCompetenceSuccess = payload => ({
  type: PROFILE_UPDATE_ONE_COMPETENCE_SUCCESS,
  payload,
});

export const updateOneCompetenceFailure = payload => ({
  type: PROFILE_UPDATE_ONE_COMPETENCE_FAILURE,
  payload,
});

export const PROFILE_FETCH_PASSED_COMPETENCES = `${prefix}/profile/fetch/passed/competences`;
export const PROFILE_FETCH_PASSED_COMPETENCES_REQUEST = `${prefix}/profile/fetch/passed/competences/request`;
export const PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS = `${prefix}/profile/fetch/passed/competences/success`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FAILURE = `${prefix}/profile/fetch/passed/competences/failure`;

export const fetchPassedCompetences = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES});

export const fetchPassedCompetencesRequest = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_REQUEST});

export const fetchPassedCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS,
  payload,
});

export const fetchPassedCompetencesFailure = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_FETCH_PASSED_COMPETENCES_FULL = `${prefix}/profile/fetch/passed/competences/full`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST = `${prefix}/profile/fetch/passed/competences/full/request`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS = `${prefix}/profile/fetch/passed/competences/full/success`;
export const PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE = `${prefix}/profile/fetch/passed/competences/full/failure`;

export const fetchPassedCompetencesFull = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_FULL});

export const fetchPassedCompetencesFullRequest = () => ({type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST});

export const fetchPassedCompetencesFullSuccess = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS,
  payload,
});

export const fetchPassedCompetencesFullFailure = payload => ({
  type: PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE,
  payload,
});

export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_REQUEST = `${prefix} profile fetch recommended competences request`;
export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_SUCCESS = `${prefix} profile fetch recommended competences success`;
export const PROFILE_FETCH_RECOMMENDED_COMPETENCES_FAILURE = `${prefix} profile fetch recommended competences failure`;

export const fetchRecommendedCompetencesRequest = () => ({type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_REQUEST});

export const fetchRecommendedCompetencesSuccess = payload => ({
  type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_SUCCESS,
  payload,
});

export const fetchRecommendedCompetencesFailure = payload => ({
  type: PROFILE_FETCH_RECOMMENDED_COMPETENCES_FAILURE,
  payload,
});

export const PROFILE_CHEAT_COMPETENCE = `${prefix} profile cheat competences`;
export const cheatCompetence = payload => ({
  type: PROFILE_CHEAT_COMPETENCE,
  payload,
});


export const PROFILE_FETCH_CV = `${prefix}/profile/start/fetch/cv`;
export const PROFILE_FETCH_CV_REQUEST = `${prefix}/profile/fetch/cv/request`;
export const PROFILE_FETCH_CV_SUCCESS = `${prefix}/profile/fetch/cv/success`;
export const PROFILE_FETCH_CV_FAILURE = `${prefix}/profile/fetch/cv/failure`;

export const fetchCvRequest = () => ({type: PROFILE_FETCH_CV_REQUEST});

export const fetchCvRequestSuccess = payload => ({
  type: PROFILE_FETCH_CV_SUCCESS,
  payload,
});
export const fetchCvRequestFailure = payload => ({
  type: PROFILE_FETCH_CV_FAILURE,
  payload,
});

export const profileFetchCv = payload => ({
  type: PROFILE_FETCH_CV,
  payload,
});


export const PROFILE_FETCH_CVS = `${prefix}/profile/start/fetch/cvs`;
export const PROFILE_FETCH_CVS_REQUEST = `${prefix}/profile/fetch/cvs/request`;
export const PROFILE_FETCH_CVS_SUCCESS = `${prefix}/profile/fetch/cvs/success`;
export const PROFILE_FETCH_CVS_FAILURE = `${prefix}/profile/fetch/cvs/failure`;

export const fetchCvsRequest = () => ({type: PROFILE_FETCH_CVS_REQUEST});

export const fetchCvsRequestSuccess = payload => ({
  type: PROFILE_FETCH_CVS_SUCCESS,
  payload,
});
export const fetchCvsRequestFailure = payload => ({
  type: PROFILE_FETCH_CVS_FAILURE,
  payload,
});

export const profileFetchCvs = payload => ({
  type: PROFILE_FETCH_CVS,
  payload,
});

export const PROFILE_EDIT_CV = `${prefix}/profile/start/edit/cv`;
export const PROFILE_EDIT_CV_REQUEST = `${prefix}/profile/edit/cv/request`;
export const PROFILE_EDIT_CV_SUCCESS = `${prefix}/profile/edit/cv/success`;
export const PROFILE_EDIT_CV_FAILURE = `${prefix}/profile/edit/cv/failure`;

export const editCvRequest = () => ({type: PROFILE_EDIT_CV_REQUEST});

export const editCvSuccess = payload => ({
  type: PROFILE_EDIT_CV_SUCCESS,
  payload,
});
export const editCvFailure = payload => ({
  type: PROFILE_EDIT_CV_FAILURE,
  payload,
});

export const profileEditCv = payload => ({
  type: PROFILE_EDIT_CV,
  payload,
});

export const PROFILE_SELF_SIGN = 'PROFILE_SELF_SIGN';
export const PROFILE_SELF_SIGN_REQUEST = 'PROFILE_SELF_SIGN_REQUEST';
export const PROFILE_SELF_SIGN_SUCCESS = 'PROFILE_SELF_SIGN_SUCCESS';
export const PROFILE_SELF_SIGN_FAILURE = 'PROFILE_SELF_SIGN_FAILURE';
export const PROFILE_SELF_SIGN_NEEDS_RELOAD = 'PROFILE_SELF_SIGN_NEEDS_RELOAD';

export const selfSignProfile = payload => ({
  type: PROFILE_SELF_SIGN,
  payload,
});
export const selfSignProfileRequest = payload => ({
  type: PROFILE_SELF_SIGN_REQUEST,
  payload,
});

export const selfSignProfileFailure = payload => ({
  type: PROFILE_SELF_SIGN_FAILURE,
  payload,
});

export const selfSignProfileSuccess = payload => ({
  type: PROFILE_SELF_SIGN_SUCCESS,
  payload,
});

export const selfSignProfileReload = () => ({type: PROFILE_SELF_SIGN_NEEDS_RELOAD});


export const PROFILE_ADD_CV = `${prefix}/profile/start/add/cv`;
export const PROFILE_ADD_CV_REQUEST = `${prefix}/profile/add/cv/request`;
export const PROFILE_ADD_CV_SUCCESS = `${prefix}/profile/add/cv/success`;
export const PROFILE_ADD_CV_FAILURE = `${prefix}/profile/add/cv/failure`;

export const addCvRequest = () => ({type: PROFILE_ADD_CV_REQUEST});

export const addCvSuccess = payload => ({
  type: PROFILE_ADD_CV_SUCCESS,
  payload,
});

export const addCvFailure = payload => ({
  type: PROFILE_ADD_CV_FAILURE,
  payload,
});

export const profileAddCv = payload => ({
  type: PROFILE_ADD_CV,
  payload,
});


export const PROFILE_REMOVE_CV = `${prefix}/profile/start/remove/cv`;
export const PROFILE_REMOVE_CV_REQUEST = `${prefix}/profile/remove/cv/request`;
export const PROFILE_REMOVE_CV_SUCCESS = `${prefix}/profile/remove/cv/success`;
export const PROFILE_REMOVE_CV_FAILURE = `${prefix}/profile/remove/cv/failure`;

export const removeCvRequest = () => ({type: PROFILE_REMOVE_CV_REQUEST});

export const removeCvSuccess = payload => ({
  type: PROFILE_REMOVE_CV_SUCCESS,
  payload,
});
export const removeCvFailure = payload => ({
  type: PROFILE_REMOVE_CV_FAILURE,
  payload,
});

export const profileRemoveCv = payload => ({
  type: PROFILE_REMOVE_CV,
  payload,
});

export const PROFILE_FETCH_MY_TASKS = 'PROFILE_FETCH_MY_TASKS';
export const PROFILE_FETCH_MY_TASKS_REQUEST = 'PROFILE_FETCH_MY_TASKS_REQUEST';
export const PROFILE_FETCH_MY_TASKS_SUCCESS = 'PROFILE_FETCH_MY_TASKS_SUCCESS';
export const PROFILE_FETCH_MY_TASKS_FAILURE = 'PROFILE_FETCH_MY_TASKS_FAILURE';

export const fetchMyTasksRequest = () => ({type: PROFILE_FETCH_MY_TASKS_REQUEST});

export const fetchMyTasksSuccess = payload => ({
  type: PROFILE_FETCH_MY_TASKS_SUCCESS,
  payload,
});
export const fetchMyTasksFailure = payload => ({
  type: PROFILE_FETCH_MY_TASKS_FAILURE,
  payload,
});

export const fetchMyTasks = payload => ({
  type: PROFILE_FETCH_MY_TASKS,
  payload,
});

export const PROFILE_CANCEL_SELF_SIGN = 'PROFILE_CANCEL_SELF_SIGN';
export const PROFILE_CANCEL_SELF_SIGN_REQUEST = 'PROFILE_CANCEL_SELF_SIGN_REQUEST';
export const PROFILE_CANCEL_SELF_SIGN_SUCCESS = 'PROFILE_CANCEL_SELF_SIGN_SUCCESS';
export const PROFILE_CANCEL_SELF_SIGN_FAILURE = 'PROFILE_CANCEL_SELF_SIGN_FAILURE';

export const cancelSelfSign = payload => ({
  type: PROFILE_CANCEL_SELF_SIGN,
  payload,
});
export const cancelSelfSignRequest = () => ({type: PROFILE_CANCEL_SELF_SIGN_REQUEST});
export const cancelSelfSignSuccess = payload => ({
  type: PROFILE_CANCEL_SELF_SIGN_SUCCESS,
  payload,
});
export const cancelSelfSignFailure = payload => ({
  type: PROFILE_CANCEL_SELF_SIGN_FAILURE,
  payload,
});


export const PROFILE_FETCH_REPORT = `${prefix}/profile/start/fetch/report`;
export const PROFILE_FETCH_REPORT_REQUEST = `${prefix}/profile/fetch/report/request`;
export const PROFILE_FETCH_REPORT_SUCCESS = `${prefix}/profile/fetch/report/success`;
export const PROFILE_FETCH_REPORT_FAILURE = `${prefix}/profile/fetch/report/failure`;

export const fetchReportRequest = () => ({type: PROFILE_FETCH_REPORT_REQUEST});

export const fetchReportSuccess = payload => ({
  type: PROFILE_FETCH_REPORT_SUCCESS,
  payload,
});
export const fetchReportFailure = payload => ({
  type: PROFILE_FETCH_REPORT_FAILURE,
  payload,
});

export const fetchReport = payload => ({
  type: PROFILE_FETCH_REPORT,
  payload,
});

export const UPDATE_ACTIVE_ORG_ID  = 'UPDATE_ACTIVE_ORG_ID';

export const updateActiveOrgId = payload => ({
  type: UPDATE_ACTIVE_ORG_ID,
  payload,
});

export const SET_ACTIVE_ORG_ID  = 'SET_ACTIVE_ORG_ID';

export const setActiveOrgId = payload => ({
  type: SET_ACTIVE_ORG_ID,
  payload,
});
