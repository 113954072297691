import {createSelector} from 'reselect';

export const getShownNotifications = ({notifications: {shown}}) => shown;
export const getHiddenNotifications = ({notifications: {hidden}}) => hidden;
export const getAll = createSelector(({notifications: {shown, hidden}}) => ({
  shown,
  hidden,
}), ({shown, hidden}) => [
  ...shown,
  ...hidden,
]);
export const getNotifications = ({notifications}) => notifications;
