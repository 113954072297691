import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, sessionId, ...rest}) => (
  <Route
    {...rest}
    render={({...props}) => sessionId 
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: '/login',
            state: {referrer: props.location},
          }}
        />
      )}
  />
);

PrivateRoute.propTypes = {sessionId: PropTypes.string};

PrivateRoute.defaultProps = {sessionId: undefined};

const mapStateToProps = state => ({sessionId: state.auth.sessionId});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
